import { FileButton, Stack } from '@mantine/core';
import { BenefitDocumentDto, BenefitDocumentType } from '@zorro/clients';
import { AddIcon, CancelIcon } from '@zorro/shared/assets';
import {
  ERROR_MESSAGES,
  createBenefitDocument,
  showErrorNotification,
  useImpersonation,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { Button, Group, Modal, Text, Title } from '@zorro/zorro-ui-design';
import { useMemo, useState } from 'react';

import { DeleteDocumentConfirmationForm } from '../EmployerDocuments';
import { useLoadingOverlay } from '../LoadingOverlayContext';
import { ModalTitle } from '../ModalComponents';
import { useMonolithMutation } from '../hooks';
import { BenefitDocumentCard } from './BenefitDocumentCard';
import { EmptyFileSection } from './EmptyFileSection';

type Props = {
  onboardingPeriodId: string;
  employeeId: string;
};

export const DocumentsTab = ({ onboardingPeriodId, employeeId }: Props) => {
  const { isAgent, isZorroOperations } = useRoles();
  const { isImpersonated } = useImpersonation();
  const { startLoading, stopLoading } = useLoadingOverlay();

  const [isDeleteDocumentsModalOpen, setIsDeleteDocumentsModalOpen] =
    useState(false);
  const [selectedDocument, setSelectedDocument] =
    useState<BenefitDocumentDto>();

  const isDon = isImpersonated || !(isAgent || isZorroOperations);

  const { data: benefitDocuments } = useMonolithQuery({
    method: 'benefitDocumentsControllerGetBenefitDocumentsByOnboardingPeriod',
    params: [onboardingPeriodId],
  });
  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  const { mutate: createBenefitDocumentMutation } = useMonolithMutation({
    method: 'benefitDocumentsControllerCreateBenefitDocument',
    successMessage: 'Document uploaded',
  });

  const uploadBenefitDocument = async (
    files: File[] | null,
    isSharedWithEmployee: boolean
  ) => {
    startLoading();
    try {
      if (!files || files.length === 0) {
        showErrorNotification({
          message: ERROR_MESSAGES.NO_FILE_SELECTED_ERROR_MESSAGE,
        });
        return;
      }

      files.forEach(async (file) => {
        const document = await createBenefitDocument(
          file,
          onboardingPeriodId,
          isSharedWithEmployee,
          BenefitDocumentType.OTHER
        );

        await createBenefitDocumentMutation([document]);
      });
    } catch (error) {
      showErrorNotification({
        message: error.message,
        title: error.name,
      });
    } finally {
      stopLoading();
    }
  };

  const [benefitAdminDocuments, benefitPlanDocuments] = useMemo(() => {
    const benefitAdminDocs: BenefitDocumentDto[] = [];
    const benefitPlanDocs: BenefitDocumentDto[] = [];

    benefitDocuments?.forEach((document) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      document.isSharedWithEmployee
        ? benefitPlanDocs.push(document)
        : benefitAdminDocs.push(document);
    });

    return [benefitAdminDocs, benefitPlanDocs];
  }, [benefitDocuments]);

  const employeeFullName = employee
    ? `${employee.firstName} ${employee.lastName}`
    : undefined;

  const isBenefitAdminDocuments = benefitAdminDocuments.length > 0;
  const isBenefitPlanDocuments = benefitPlanDocuments.length > 0;

  const handleDeleteBenefitDocument = (document: BenefitDocumentDto) => {
    setSelectedDocument(document);
    setIsDeleteDocumentsModalOpen(true);
  };

  const handleUploadSharedBenefitDocument = (files: File[] | null) =>
    uploadBenefitDocument(files, true);

  const handleUploadPrivateBenefitDocument = (files: File[] | null) =>
    uploadBenefitDocument(files, false);

  return (
    <Stack maw={900} gap="xxl">
      <Stack>
        <Group justify="space-between" wrap="nowrap">
          <Group wrap="nowrap" gap="xxs">
            <Title order={4} fw={600}>
              Admin documents
            </Title>
            <Text c="zorroGray.4" fw={400}>
              (not shared with {employeeFullName})
            </Text>
          </Group>
          {!isDon && (
            <FileButton multiple onChange={handleUploadPrivateBenefitDocument}>
              {(props) => (
                <Button {...props} variant="subtle" p={0}>
                  <AddIcon style={{ height: 28, width: 28 }} />
                </Button>
              )}
            </FileButton>
          )}
        </Group>

        {isBenefitAdminDocuments ? (
          <Stack gap="xs">
            {benefitAdminDocuments.map((adminDocument) => (
              <BenefitDocumentCard
                key={adminDocument.id}
                document={adminDocument}
                onDelete={handleDeleteBenefitDocument}
              />
            ))}
          </Stack>
        ) : (
          <EmptyFileSection
            isDon={isDon}
            isSharedWithEmployee={false}
            onboardingPeriodId={onboardingPeriodId}
            isMultiple
          />
        )}
      </Stack>

      <Stack>
        <Group justify="space-between" wrap="nowrap">
          <Group wrap="nowrap" gap="xxs">
            <Title order={4} fw={600}>
              Employee documents
            </Title>
            <Text c="zorroGray.4" fw={400}>
              (shared with {employeeFullName})
            </Text>
          </Group>
          {!isDon && (
            <FileButton multiple onChange={handleUploadSharedBenefitDocument}>
              {(props) => (
                <Button {...props} variant="subtle" p={0}>
                  <AddIcon style={{ height: 28, width: 28 }} />
                </Button>
              )}
            </FileButton>
          )}
        </Group>

        {isBenefitPlanDocuments ? (
          <Stack gap="xs">
            {benefitPlanDocuments.map((planDocument) => (
              <BenefitDocumentCard
                key={planDocument.id}
                document={planDocument}
                onDelete={handleDeleteBenefitDocument}
              />
            ))}
          </Stack>
        ) : (
          <EmptyFileSection
            isDon={isDon}
            isSharedWithEmployee
            onboardingPeriodId={onboardingPeriodId}
            isMultiple
          />
        )}
      </Stack>
      <Modal
        size="lg"
        opened={isDeleteDocumentsModalOpen}
        onClose={() => {
          setIsDeleteDocumentsModalOpen(false);
        }}
        showSeparator={false}
        title={
          <ModalTitle
            title="Delete document"
            icon={<CancelIcon />}
            titleOrder={2}
          />
        }
      >
        <DeleteDocumentConfirmationForm
          selectedDocument={selectedDocument}
          onClose={() => setIsDeleteDocumentsModalOpen(false)}
          onSubmit={async () => {
            setIsDeleteDocumentsModalOpen(false);
            setSelectedDocument(undefined);
          }}
          isBenefitDocument
        />
      </Modal>
    </Stack>
  );
};
