import {
  DependentDto,
  DependentSubtypeEnum,
  DependentTypeEnum,
  EmployeeDto,
  EnrollmentStatus,
  Gender,
  GenderEnum,
  InsuredPersonDto,
  InsuredSubtype,
  UpsertDependentDto,
} from '@zorro/clients';
import {
  DateUtilInstance,
  earliestAllowedNonDependentBirthDate,
  formatDateISO,
  latestAllowedNonDependentBirthDate,
  parseDateISO,
} from '@zorro/shared/formatters';
import {
  VALIDATION_MESSAGES,
  convertFromMaybeYesNo,
  convertToMaybeYesNoEnum,
  convertToYesNoEnum,
  parseEnumValue,
  ssnRegex,
} from '@zorro/shared/utils';
import { YesNo } from '@zorro/types';
import { UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import { InferType } from 'yup';

import { InsuredDependentsFormFields } from './DependentsFormInputs';
import { InsuredEmployeeFormFields } from './EmployeeFormInputs';
import { InsuredSpouseFormFields } from './SpouseFormInputs';

export type UseInsuredFormReturnType = {
  employeeForm: UseFormReturn<InsuredEmployeeFormFields>;
  spouseForm: UseFormReturn<InsuredSpouseFormFields>;
  dependentsForm: UseFormReturn<InsuredDependentsFormFields>;
  targetEnrollmentDate?: DateUtilInstance;
  onSubmit: () => Promise<boolean>;
  hasAnyDependents: boolean;
  setIsSpouseActive: (isActive: boolean) => void;
  isSpouseActive: boolean;
  setPossibleFipsCodes: (fipsCodes: string[]) => void;
  possibleFipsCodes: string[];
};

export const INSURED_FORM_ADDRESS_TOOLTIP_LABEL =
  'Shopping for individual health plans requires all household members to share the same residential address. If you have dependents at a different address, please contact an enrollment expert for assistance';

export const getInsuredBaseSchema = (isFinalizationMode: boolean) =>
  yup.object({
    firstName: yup
      .string()
      .required(VALIDATION_MESSAGES.firstNameRequired)
      .min(2, VALIDATION_MESSAGES.firstNameMinTwoLetters),
    lastName: yup
      .string()
      .required(VALIDATION_MESSAGES.lastNameRequired)
      .min(2, VALIDATION_MESSAGES.lastNameMinTwoLetters),
    dateOfBirth: yup
      .date()
      .min(
        latestAllowedNonDependentBirthDate(),
        VALIDATION_MESSAGES.nonDependentMaxAgeValid
      )
      .max(
        earliestAllowedNonDependentBirthDate(),
        VALIDATION_MESSAGES.nonDependentMinAgeValid
      )
      .typeError(VALIDATION_MESSAGES.dateOfBirthRequired)
      .required(VALIDATION_MESSAGES.dateOfBirthRequired),

    ...(isFinalizationMode
      ? {
          residentialAddress: yup
            .string()
            .required(VALIDATION_MESSAGES.residentialAddressRequired),
          ssn: yup
            .string()
            .required(VALIDATION_MESSAGES.ssnRequired)
            .matches(ssnRegex, VALIDATION_MESSAGES.ssnNumberValid),
          gender: yup
            .mixed<Gender>()
            .oneOf(Object.values(Gender), VALIDATION_MESSAGES.genderRequired)
            .required(VALIDATION_MESSAGES.genderRequired),
          isSmoker: yup
            .mixed<YesNo>()
            .oneOf(Object.values(YesNo), VALIDATION_MESSAGES.smokerRequired)
            .required(VALIDATION_MESSAGES.smokerRequired),
          isPregnant: yup.mixed<YesNo>().when('gender', {
            is: Gender.FEMALE,
            then: (schema) =>
              schema
                .oneOf(
                  Object.values(YesNo),
                  VALIDATION_MESSAGES.pregnantRequired
                )
                .required(VALIDATION_MESSAGES.pregnantRequired),
            otherwise: (schema) => schema.notRequired(),
          }),
        }
      : {
          residentialAddress: yup.string().optional(),
          ssn: yup.string().matches(ssnRegex, {
            message: VALIDATION_MESSAGES.ssnNumberValid,
            excludeEmptyString: true,
          }),
          gender: yup
            .mixed<Gender>()
            .oneOf(Object.values(Gender), VALIDATION_MESSAGES.genderRequired)
            .nullable(),
          isSmoker: yup
            .mixed<YesNo>()
            .oneOf(Object.values(YesNo), VALIDATION_MESSAGES.smokerRequired)
            .nullable(),
          isPregnant: yup
            .mixed<YesNo>()
            .oneOf(Object.values(YesNo), VALIDATION_MESSAGES.pregnantRequired)
            .nullable(),
        }),
  });

type BaseInsuredFormFields = InferType<ReturnType<typeof getInsuredBaseSchema>>;

export const getDefaultInsuredFormFields = (
  residentialAddress?: InsuredPersonDto['residentialAddress']
): Omit<BaseInsuredFormFields, 'dateOfBirth'> => {
  return {
    residentialAddress: residentialAddress ?? '',
    firstName: '',
    lastName: '',
    gender: null,
    ssn: '',
    isPregnant: YesNo.NO,
    isSmoker: YesNo.NO,
  };
};

const convertGenderFormToDto = (
  gender: Gender | null | undefined
): GenderEnum | undefined => {
  return gender ? parseEnumValue(GenderEnum, gender) : undefined;
};

const convertGenderDtoToForm = (
  gender: GenderEnum | null | undefined
): Gender | undefined => {
  return gender ? parseEnumValue(Gender, gender) : undefined;
};

const convertDependentTypeFormToDto = (
  insuredSubtype: InsuredSubtype | null | undefined
): DependentSubtypeEnum | null => {
  return parseEnumValue(DependentSubtypeEnum, insuredSubtype || '') || null;
};

export const convertDependentSubtypeDtoToForm = (
  dependentSubtypeEnum: DependentSubtypeEnum | null | undefined
): InsuredSubtype | null => {
  return parseEnumValue(InsuredSubtype, dependentSubtypeEnum || '') || null;
};

export const mapDependentDtoToBaseInsuredFormFields = (
  dependentDto: DependentDto
): BaseInsuredFormFields => {
  return {
    firstName: dependentDto.firstName,
    lastName: dependentDto.lastName,
    dateOfBirth: parseDateISO(dependentDto.dateOfBirth).toDate(),
    gender: convertGenderDtoToForm(dependentDto.gender),
    ssn: dependentDto.ssn ?? undefined,
    residentialAddress: dependentDto.residentialAddress ?? undefined,
    isPregnant: convertToMaybeYesNoEnum(dependentDto.isPregnant),
    isSmoker: convertToMaybeYesNoEnum(dependentDto.isSmoker),
  };
};

export const mapFormFieldsToDependentDto = (
  formFields: InsuredSpouseFormFields,
  type: DependentTypeEnum
): UpsertDependentDto => {
  return {
    firstName: formFields.firstName,
    lastName: formFields.lastName,
    gender: convertGenderFormToDto(formFields.gender),
    dateOfBirth: formatDateISO(formFields.dateOfBirth),
    isPregnant: convertFromMaybeYesNo(formFields.isPregnant),
    isSmoker: convertFromMaybeYesNo(formFields.isSmoker),
    ssn: formFields.ssn,
    residentialAddress: formFields.residentialAddress || '',
    type,
    subType: convertDependentTypeFormToDto(formFields.subtype),
  };
};

export const mapInsuredDtoToBaseInsuredFormFields = (
  insuredDto?: InsuredPersonDto | EmployeeDto
): BaseInsuredFormFields => {
  if (!insuredDto) {
    return {
      firstName: '',
      lastName: '',
      dateOfBirth: parseDateISO('1970-01-01').toDate(),
      gender: Gender.MALE,
      ssn: '',
      residentialAddress: '',
      isPregnant: YesNo.NO,
      isSmoker: YesNo.NO,
    };
  }

  return {
    firstName: insuredDto.firstName,
    lastName: insuredDto.lastName,
    dateOfBirth: parseDateISO(insuredDto.dateOfBirth).toDate(),
    gender: insuredDto?.gender || Gender.MALE,
    ssn: ('ssn' in insuredDto && insuredDto?.ssn) || '',
    residentialAddress:
      ('residentialAddress' in insuredDto && insuredDto?.residentialAddress) ||
      '',
    isPregnant:
      'isPregnant' in insuredDto && insuredDto?.isPregnant
        ? convertToYesNoEnum(insuredDto.isPregnant)
        : YesNo.NO,
    isSmoker:
      'isSmoker' in insuredDto && insuredDto?.isSmoker
        ? convertToYesNoEnum(insuredDto.isSmoker)
        : YesNo.NO,
  };
};

export const shouldShowReadonlyMode = (enrollmentStatus: EnrollmentStatus) => {
  return [
    EnrollmentStatus.CARRIER_APPLICATION_SENT,
    EnrollmentStatus.ENROLLMENT_CONFIRMED,
    EnrollmentStatus.ELECTION_SUBMITTED,
    EnrollmentStatus.ACTIVE_COVERAGE,
    EnrollmentStatus.COVERAGE_ENDED,
  ].includes(enrollmentStatus);
};
