import { NumberInput, NumberInputProps } from '@mantine/core';
import { forwardRef } from 'react';

import classNames from './NumberInput.module.scss';

export type INumberInputProps = NumberInputProps;

export const ZorroNumberInput = forwardRef<HTMLInputElement, INumberInputProps>(
  (
    {
      size = 'lg',
      hideControls = true,
      decimalScale = 0,
      placeholder,
      readOnly,
      ...props
    },
    ref
  ) => {
    return (
      <NumberInput
        ref={ref}
        size={size}
        classNames={classNames}
        hideControls={hideControls}
        decimalScale={decimalScale}
        placeholder={readOnly ? '-' : placeholder}
        readOnly={readOnly}
        {...props}
      />
    );
  }
);

ZorroNumberInput.displayName = 'ZorroNumberInput';
