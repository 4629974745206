import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const DisconnectedIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="4" fill="#CCF2FB" />
        <rect width="32" height="32" rx="4" fill="#FFBBAF" />
        <path
          d="M22.6667 19.3333L19.3333 22.6667M8.5 23.5L10.5833 21.4167M21.4167 10.5833L23.5 8.5M14.3333 15.1667L12.6667 16.8333M16.8333 17.6667L15.1667 19.3333M19.3333 19.3333L22.6667 22.6667M11.8333 16L15.9999 20.1667L14.7499 21.4167C14.478 21.6976 14.1529 21.9216 13.7935 22.0756C13.4341 22.2296 13.0477 22.3106 12.6567 22.3137C12.2657 22.3169 11.878 22.2422 11.5162 22.0941C11.1544 21.9459 10.8256 21.7272 10.5492 21.4508C10.2727 21.1743 10.054 20.8456 9.90586 20.4837C9.75771 20.1219 9.68304 19.7342 9.68621 19.3432C9.68939 18.9523 9.77034 18.5658 9.92435 18.2065C10.0784 17.8471 10.3024 17.5219 10.5833 17.25L11.8333 16ZM20.1667 15.9999L16 11.8333L17.25 10.5833C17.5219 10.3024 17.8471 10.0784 18.2065 9.92435C18.5658 9.77034 18.9523 9.68939 19.3432 9.68621C19.7342 9.68304 20.1219 9.75771 20.4837 9.90586C20.8456 10.054 21.1743 10.2727 21.4508 10.5492C21.7272 10.8256 21.9459 11.1544 22.0941 11.5162C22.2422 11.878 22.3169 12.2657 22.3137 12.6567C22.3106 13.0477 22.2296 13.4341 22.0756 13.7935C21.9216 14.1529 21.6976 14.478 21.4167 14.7499L20.1667 15.9999Z"
          stroke="#1A1721"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Center>
  );
};
