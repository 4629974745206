import { show as showIntercom } from '@intercom/messenger-js-sdk';
import { ActionIcon, AppShell } from '@mantine/core';
import { IconHelp, IconMenu2 } from '@tabler/icons-react';
import { ZorroLogo } from '@zorro/shared/assets';
import {
  COMMON_DYNAMIC_ROUTES,
  getFullName,
  useDynamicRouter,
  useImpersonation,
} from '@zorro/shared/utils';
import { Button, Center, Group, Icon, brand } from '@zorro/zorro-ui-design';

import { useAppLayout } from '../AppLayoutContext';
import classNames from './AppLayoutHeader.module.scss';
import { AppLayoutHeaderImpersonation } from './AppLayoutHeaderImpersonation';
import { AppLayoutHeaderMenu } from './AppLayoutHeaderMenu';
import { AppLayoutHeaderSearch } from './AppLayoutHeaderSearch';

type Props = {
  shouldShowSearch: boolean;
  shouldShowMenuButton: boolean;
  shouldShowDonElenaMenuItems: boolean;
  isDon: boolean;
};

export const AppLayoutHeader = ({
  shouldShowDonElenaMenuItems,
  shouldShowMenuButton,
  shouldShowSearch,
  isDon,
}: Props) => {
  const { navigateToRoute } = useDynamicRouter(COMMON_DYNAMIC_ROUTES);
  const { isImpersonated, user, isLoading } = useImpersonation();
  const { isSidebarOpen, toggleSidebar } = useAppLayout();

  let impersonatedFullName: string | undefined = undefined;
  if (isImpersonated) {
    if (!user || isLoading) {
      return null;
    }

    impersonatedFullName = getFullName(user);
  }

  return (
    <AppShell.Header bg="zorroGray.10">
      {impersonatedFullName && (
        <AppLayoutHeaderImpersonation fullName={impersonatedFullName} />
      )}
      <Group mt="xs" mx="sm">
        <Group pos="relative">
          {shouldShowMenuButton && (
            <ActionIcon
              onClick={() => toggleSidebar()}
              data-open={isSidebarOpen}
              classNames={classNames}
              size="3.5rem"
            >
              <Icon icon={IconMenu2} />
            </ActionIcon>
          )}
          <Center
            onClick={() => navigateToRoute('HOME', [])}
            style={{ cursor: 'pointer' }}
            h="3.5rem"
          >
            <ZorroLogo />
          </Center>
          {shouldShowSearch && <AppLayoutHeaderSearch />}
        </Group>
        <Button onClick={showIntercom} variant="tertiary" size="md" ml="auto">
          <Icon
            icon={IconHelp}
            color={brand.zorroGray400}
            size={20}
            style={{ marginRight: 8 }}
          />
          Help
        </Button>
        <AppLayoutHeaderMenu
          shouldShowDonElenaMenuItems={shouldShowDonElenaMenuItems}
          isDon={isDon}
        />
      </Group>
    </AppShell.Header>
  );
};
