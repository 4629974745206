import { useRoles } from '@zorro/shared/utils';
import { Button } from '@zorro/zorro-ui-design';

import { useMonolithMutation } from '../../index';

type Props = {
  employerId: string;
};

export const FinchDisconnect = ({ employerId }: Props) => {
  const { isZorroOperations } = useRoles();
  const { mutate: disconnect } = useMonolithMutation({
    method: 'finchControllerConnectDisconnect',
  });

  if (!isZorroOperations) {
    return null;
  }

  async function handleDisconnect() {
    await disconnect([{ employerId }]);
  }

  return (
    <Button onClick={handleDisconnect} size="md" bg="zorroFire.7">
      Disconnect
    </Button>
  );
};
