import { YesNo } from '@zorro/types';

export const convertToYesNoEnum = (value: boolean) => {
  return value ? YesNo.YES : YesNo.NO;
};

export const convertToMaybeYesNoEnum = (
  value: boolean | null | undefined
): YesNo | null | undefined => {
  if (value === null || value === undefined) {
    return value;
  }
  return convertToYesNoEnum(value);
};
