import { EnvParams } from '@zorro/environment';

export const getFinchConnectEnvParams = (): {
  sandbox: 'provider' | 'finch' | null;
  manual: boolean;
} => {
  const manual =
    process.env[EnvParams.NEXT_PUBLIC_FINCH_CLIENT_MANUAL] === 'true';
  const sandbox = process.env[EnvParams.NEXT_PUBLIC_FINCH_CLIENT_SANDBOX];

  return {
    sandbox: sandbox === 'finch' || sandbox === 'provider' ? sandbox : null,
    manual,
  };
};
