import { ValueOf } from '@zorro/types';
import { NextRouter, useRouter as useNextRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { DON_ROUTES, MURRIETA_ROUTES, TORNADO_ROUTES } from '../routes';

type UseRouterReturnType<Routes> = {
  router: NextRouter;
  isNavigating: boolean;
  isNavigatingToRoute: (route: ValueOf<Routes>) => boolean;
  reloadPage: (options?: { scroll: boolean }) => void;
  pushWithCurrentQueryParams: (
    route: ValueOf<Routes>,
    queryParams?: Record<string, string>
  ) => void;
};

export const useRouter = <
  Routes extends Record<string, string>,
>(): UseRouterReturnType<Routes> => {
  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({});
  const router = useNextRouter();

  const handleSetLoadingState = (route: ValueOf<Routes>) => {
    setLoadingState((prev) => ({ ...prev, [route]: true }));
  };

  const handleGetLoadingState = (route: ValueOf<Routes>): boolean => {
    return loadingState[route] ?? false;
  };

  const reloadPage = (options?: { scroll: boolean }) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.replace(router.asPath, undefined, { ...options });
  };

  const pushWithCurrentQueryParams = (
    route: ValueOf<Routes>,
    queryParams: Record<string, string> = {}
  ) => {
    const url = new URL(window.location.href);
    const currentQueryParams = Object.fromEntries(url.searchParams);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({
      pathname: route,
      query: { ...currentQueryParams, ...queryParams },
    });
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleSetLoadingState);
    return () => router.events.off('routeChangeStart', handleSetLoadingState);
  }, []);

  return {
    router,
    isNavigating: Object.keys(loadingState).length > 0,
    isNavigatingToRoute: handleGetLoadingState,
    reloadPage,
    pushWithCurrentQueryParams,
  };
};

export const useTornadoRouter = useRouter<typeof TORNADO_ROUTES>;

export const useMurrietaRouter = useRouter<typeof MURRIETA_ROUTES>;
export const useDonRouter = useRouter<typeof DON_ROUTES>;

export function getQueryValue(
  query: string | string[] | undefined
): string | undefined {
  if (query === undefined) {
    return query;
  }
  if (Array.isArray(query)) {
    return query.length > 0 ? query[0] : undefined;
  }
  return query;
}
