import { Stack } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { OnboardingPeriodBenefitsDto } from '@zorro/clients';
import { getToday, parseDateISO } from '@zorro/shared/formatters';
import { useMonolithQuery, useRoles } from '@zorro/shared/utils';
import { Alert, Text } from '@zorro/zorro-ui-design';

import { FormFooter } from '../../FormFooter';
import { InsuredFormInputs, ReadonlyInsuredForm } from '../../InsuredForm';
import { useGlobalInsuredForm } from '../../InsuredForm/useGlobalInsuredForm';

type Props = {
  employerId: string;
  employeeId: string;
  isEmployeeMode?: boolean;
};

export const GlobalPersonalDetailsForm = ({
  employerId,
  employeeId,
  isEmployeeMode = false,
}: Props) => {
  const { isZorroOperations } = useRoles();
  const isFinalizationMode = !isZorroOperations;
  const insuredForm = useGlobalInsuredForm({
    employeeId,
    isFinalizationMode,
  });
  const handleCancel = () => {
    insuredForm.employeeForm.reset();
    insuredForm.spouseForm.reset();
    insuredForm.dependentsForm.reset();
  };

  const { isDirty: isEmployeeDirty } = insuredForm.employeeForm.formState;
  const { isDirty: isSpouseDirty } = insuredForm.spouseForm.formState;
  const { isDirty: isDependentsDirty } = insuredForm.dependentsForm.formState;

  const isAnyFieldTouched =
    isEmployeeDirty || isSpouseDirty || isDependentsDirty;

  const { data: onboardingPeriods } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindMany',
    params: [employeeId],
  });

  const { data: employeeBenefits } = useMonolithQuery({
    method: 'benefitsControllerGetAllEmployeeOnboardingPeriodBenefits',
    params: [employeeId],
  });
  const hasNonWaivedBenefitEnrollment = employeeBenefits?.some(
    (periodBenefits: OnboardingPeriodBenefitsDto) =>
      periodBenefits.majorMedicalBenefit &&
      periodBenefits.majorMedicalBenefit.benefitEnrollment &&
      !periodBenefits.majorMedicalBenefit.isWaived
  );

  const today = getToday();

  const hasEditableOP = onboardingPeriods?.some(
    (op) =>
      parseDateISO(op.targetEnrollmentDate).isAfter(today) && !op.submittedAt
  );

  const isReadonly = !(isZorroOperations || hasEditableOP);

  return (
    <Stack maw={800} mb="5rem">
      {isReadonly ? (
        <ReadonlyInsuredForm
          isEmployeeMode={isEmployeeMode}
          insuredForm={insuredForm}
          employeeId={employeeId}
        />
      ) : (
        <>
          {hasNonWaivedBenefitEnrollment && (
            <Alert
              icon={<IconInfoCircle />}
              variant="default"
              bg="zorroSky.1"
              bd="none"
            >
              <Text size="sm">
                Changes made here will only apply to future election
                submissions. Existing enrollment records will remain unchanged
              </Text>
            </Alert>
          )}
          <InsuredFormInputs
            isFinalizationMode={isFinalizationMode}
            isEmployeeMode={isEmployeeMode}
            insuredForm={insuredForm}
            employerId={employerId}
          />
        </>
      )}
      <FormFooter
        primaryLabel="Save"
        primaryButtonProps={{
          onClick: () => insuredForm.onSubmit(),
          disabled: !isAnyFieldTouched,
        }}
        secondaryLabel={isAnyFieldTouched ? 'Cancel' : undefined}
        secondaryButtonProps={{ onClick: handleCancel }}
      />
    </Stack>
  );
};
