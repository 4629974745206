import { IconLink, IconUserScan } from '@tabler/icons-react';
import {
  Button,
  LabelWithTooltip,
  Space,
  Text,
  TextInput,
} from '@zorro/zorro-ui-design';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Controller, UseFormReturn } from 'react-hook-form';

import { useMonolithMutation } from '../../../hooks';
import { SendInviteFormFields } from './SendInviteUtils';

type Props = {
  sendInviteForm: UseFormReturn<SendInviteFormFields>;
  employerId: string;
};

export const InviteByLinkForm = ({ employerId, sendInviteForm }: Props) => {
  const { mutate: mutateInviteEmployer, data: inviteLink = '' } =
    useMonolithMutation({
      method: 'employersControllerInviteEmployerAdmin',
      successMessage: 'Employer invite link created',
    });

  const { control, watch, formState } = sendInviteForm;
  const { isValid } = formState;
  const email = watch('email');

  const handleSubmit = async () => {
    try {
      await mutateInviteEmployer([employerId, email, false]);
    } catch {
      /* empty */
    }
  };

  return (
    <>
      <Text fw={600}>Step 1: Create an invitation</Text>
      <Controller
        control={control}
        name="email"
        render={({ field: { ...rest } }) => (
          <TextInput
            {...rest}
            placeholder="Add invitation account email"
            rightSectionWidth="165px"
            w={415}
            radius="4px"
            size="lg"
            rightSectionProps={{ style: { paddingRight: '4px' } }}
            rightSection={
              <Button
                size="sm"
                variant="filled"
                disabled={!isValid}
                onClick={handleSubmit}
                radius="4px"
                style={{ padding: '6px 16px 6px 16px', height: '40px' }}
              >
                <IconUserScan />
                <Text ml="xs" c="white">
                  Create invitation
                </Text>
              </Button>
            }
            label={
              <LabelWithTooltip
                label="Invitation account email"
                tooltipLabel="Email of the person or team responsible for setting up the employer account"
                isRequired
              />
            }
          />
        )}
      />
      <Space />
      <Text fw={600} c={inviteLink ? 'zorroCoal.9' : 'zorroGray.6'}>
        Step 2: Share invitation link
      </Text>
      <CopyToClipboard text={inviteLink}>
        <Button size="md" variant="outline" w="140px" disabled={!inviteLink}>
          <IconLink style={{ marginRight: '0.5rem' }} />
          Copy link
        </Button>
      </CopyToClipboard>
    </>
  );
};
