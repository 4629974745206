import { Anchor, AnchorProps, ElementProps } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';
import { forwardRef } from 'react';

import classNames from './Anchor.module.scss';

export interface IAnchorProps
  extends AnchorProps,
    ElementProps<'a', keyof AnchorProps> {
  masked?: boolean;
}

// 🧠 DO NOT EXPORT! DO NOT USE OUTSIDE THE `zorro-ui-design` library! USE `Link` instead
export const ZorroAnchor = forwardRef<HTMLAnchorElement, IAnchorProps>(
  // eslint-disable-next-line id-length
  ({ c = 'zorroIris.9', target, children, masked, ...props }, ref) => {
    const isExternalLink = target === '_blank';

    const anchorClassNames = [
      classNames.root ?? null,
      masked ? 'fs-mask' : null,
    ].filter((className): className is string => Boolean(className));

    return (
      <Anchor
        ref={ref}
        component="a"
        c={c}
        target={target}
        classNames={{
          ...classNames,
          root: anchorClassNames.join(' '),
        }}
        data-external={isExternalLink}
        {...props}
      >
        {children}
        {isExternalLink && <IconExternalLink size="1.25rem" />}
      </Anchor>
    );
  }
);

ZorroAnchor.displayName = 'ZorroAnchor';
