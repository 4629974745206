import { TaxIdType } from '@zorro/clients';
import { VALIDATION_MESSAGES } from '@zorro/shared/utils';
import {
  FormErrorMessage,
  Group,
  Select,
  TextInput,
} from '@zorro/zorro-ui-design';
import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  Path,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import * as yup from 'yup';

const einPattern = /^\d{9}$/u;
const itinPattern = /^9\d{8}$/u;

type TaxIdFormFields = {
  taxIdType?: TaxIdType | null;
  taxId?: string;
};

export const taxIdExclusions: [string, string][] = [
  ['taxId', 'taxIdType'],
  ['taxIdType', 'taxId'],
];

export const taxIdSchemaObject = {
  taxIdType: yup
    .mixed<TaxIdType>()
    .oneOf(Object.values(TaxIdType), VALIDATION_MESSAGES.taxIdTypeRequired)
    .typeError(VALIDATION_MESSAGES.taxIdTypeRequired)
    .when('taxId', {
      is: (taxId: string) => Boolean(taxId),
      then: (schema) => schema.required(VALIDATION_MESSAGES.taxIdTypeRequired),
      otherwise: (schema) => schema.nullable(),
    }),
  taxId: yup.string().when('taxIdType', ([taxIdType], schema) => {
    switch (taxIdType) {
      case TaxIdType.EIN: {
        return schema.matches(einPattern, 'Invalid EIN');
      }
      case TaxIdType.ITIN: {
        return schema.matches(itinPattern, 'Invalid ITIN');
      }
      default: {
        return schema.nullable();
      }
    }
  }),
};

type Props<T extends TaxIdFormFields> = {
  control: Control<T>;
  watch: UseFormWatch<T>;
  errors: FieldErrors<T>;
  trigger: UseFormTrigger<T>;
  isRequired?: boolean;
  isDisabled?: boolean;
};

export const TaxIdInput = <T extends TaxIdFormFields>({
  watch,
  errors,
  trigger,
  control,
  isRequired = false,
  isDisabled = false,
}: Props<T>) => {
  const taxId = watch('taxId' as FieldPath<T>);
  const handleTaxIdTypeChange = (
    newTaxIdType: string | null,
    onChange: (newValue: string) => void
  ) => {
    if (newTaxIdType) {
      onChange(newTaxIdType);
    }
    if (taxId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      trigger('taxId' as FieldPath<T>);
    }
  };

  let taxIdTypeError = false;
  if ('taxIdType' in errors) taxIdTypeError = true;

  return (
    <>
      <Group wrap="nowrap" gap="xs" align="flex-end">
        <Controller
          control={control}
          disabled={isDisabled}
          name={'taxIdType' as FieldPath<T>}
          render={({ field: { onChange, ...rest } }) => (
            <Select
              {...rest}
              placeholder="-"
              w="9rem"
              label="EIN/ITIN"
              required={isRequired}
              data={Object.keys(TaxIdType)}
              onChange={(value) => handleTaxIdTypeChange(value, onChange)}
              size="md"
            />
          )}
        />

        <Controller
          control={control}
          disabled={isDisabled}
          name={'taxId' as FieldPath<T>}
          render={({ field: { value, ...rest } }) => (
            <TextInput
              {...rest}
              value={value || undefined}
              label=" "
              placeholder="912345678"
              size="md"
              w="100%"
            />
          )}
        />
      </Group>
      {taxIdTypeError && (
        <FormErrorMessage fieldName={'taxIdType' as Path<T>} errors={errors} />
      )}
      {!taxIdTypeError && (
        <FormErrorMessage fieldName={'taxId' as Path<T>} errors={errors} />
      )}
    </>
  );
};
