import { $enum } from 'ts-enum-util';
import { StringKeyOf } from 'ts-enum-util/dist/types/types';

type EnumValue<T> = Record<StringKeyOf<T>, StringKeyOf<T>>;

export function parseEnumValue<T extends EnumValue<T>>(
  enumType: T,
  value: string
) {
  return $enum(enumType).asValueOrDefault(value);
}

export function parseMaybeEnumValue<T extends EnumValue<T>>(
  enumType: T,
  value: string | null | undefined
) {
  return value ? parseEnumValue(enumType, value) : null;
}
