import {
  CitizenshipStatus,
  EmploymentType,
  Gender,
  WageType,
} from '@zorro/clients';
import { DateUtilInstance } from '@zorro/shared/formatters';
import {
  VALIDATION_MESSAGES,
  fipsCodeToLabeledData,
  insuredMaxDateOfBirth,
  insuredMinDateOfBirth,
  validateOnlyNumbers,
} from '@zorro/shared/utils';
import { YesNo } from '@zorro/types';
import {
  AddressInput,
  Checkbox,
  DateInput,
  FormErrorMessage,
  Grid,
  Group,
  InputWrapper,
  PasswordInput,
  PhoneInput,
  Select,
  TabButtonGroup,
  Tabs,
  Text,
  TextInput,
} from '@zorro/zorro-ui-design';
import { Controller, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

import { useAnalytics } from '../Analytics';
import { CitizenshipStatusInput } from '../CitizenshipStatusInput';
import { EmployeeClassInput } from '../EmployeeClassInput/EmployeeClassInput';
import { EmploymentTypeInput } from '../EmploymentTypeInput';
import { WageTypeInput } from '../WageTypeInput';
import { getInsuredBaseSchema } from './InsuredFormUtils';

export const getEmployeeFormSchema = (
  isFinalizationMode: boolean,
  isEmployeeMode: boolean
) =>
  getInsuredBaseSchema(isFinalizationMode).concat(
    yup.object({
      email: yup
        .string()
        .email(VALIDATION_MESSAGES.emailRequired)
        .matches(/^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]{2,}$/u, {
          message: VALIDATION_MESSAGES.emailRequired,
        })
        .required(VALIDATION_MESSAGES.emailRequired),
      isMailingAddressSameAsResidentialAddress: yup
        .boolean()
        .required(VALIDATION_MESSAGES.isMailingAddressSameAsResidentialAddress),

      employmentType: yup.mixed<EmploymentType>().nullable(),
      fipsCode: yup.string().optional(),
      wageType: yup.mixed<WageType>().nullable(),
      class: isEmployeeMode ? yup.string().nullable() : yup.string().required(),
      ...(isFinalizationMode
        ? {
            phone: yup
              .string()
              .required(VALIDATION_MESSAGES.phoneNumberRequired)
              // we expect a 10-digit US phone number entered by the user and react-phone-number-input automatically adds +1 prefix to it
              .matches(/^\+?\d{11}$/u, VALIDATION_MESSAGES.phoneNumberValid),
            personalEmail: yup
              .string()
              .email(VALIDATION_MESSAGES.personalEmailValid)
              .matches(/^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]{2,}$/u, {
                message: VALIDATION_MESSAGES.personalEmailValid,
              })
              .required(VALIDATION_MESSAGES.personalEmailRequired),
            mailingAddress: yup
              .string()
              .when('isMailingAddressSameAsResidentialAddress', {
                is: false,
                then: () =>
                  yup
                    .string()
                    .required(VALIDATION_MESSAGES.mailingAddressRequired),
                otherwise: () => yup.string().notRequired(),
              }),
            citizenshipStatus: yup
              .mixed<CitizenshipStatus>()
              .oneOf(
                Object.values(CitizenshipStatus),
                VALIDATION_MESSAGES.citizenshipStatus
              )
              .typeError(VALIDATION_MESSAGES.citizenshipStatus)
              .required(VALIDATION_MESSAGES.citizenshipStatus),
          }
        : {
            phone: yup
              .string()
              // we expect a 10-digit US phone number entered by the user and react-phone-number-input automatically adds +1 prefix to it
              .matches(/^\+?\d{11}$/u, {
                message: VALIDATION_MESSAGES.phoneNumberValid,
                excludeEmptyString: true,
              })
              .nullable(),
            personalEmail: yup
              .string()
              .matches(/^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]{2,}$/u, {
                message: VALIDATION_MESSAGES.personalEmailValid,
                excludeEmptyString: true,
              })
              .optional(),
            mailingAddress: yup.string().optional(),
            citizenshipStatus: yup
              .mixed<CitizenshipStatus>()
              .oneOf(
                Object.values(CitizenshipStatus),
                VALIDATION_MESSAGES.citizenshipStatus
              )
              .typeError(VALIDATION_MESSAGES.citizenshipStatus)
              .nullable(),
          }),
    })
  );

export type InsuredEmployeeFormFields = yup.InferType<
  ReturnType<typeof getEmployeeFormSchema>
>;

type Props = {
  employeeForm: UseFormReturn<InsuredEmployeeFormFields>;
  targetEnrollmentDate?: DateUtilInstance;
  isFinalizationMode: boolean;
  isEmployeeMode: boolean;
  employerId: string;
  possibleFipsCodes: string[];
  setPossibleFipsCodes: (fipsCodes: string[]) => void;
};

export const EmployeeFormInputs = ({
  employeeForm,
  targetEnrollmentDate,
  employerId,
  isEmployeeMode,
  isFinalizationMode,
  possibleFipsCodes,
  setPossibleFipsCodes,
}: Props) => {
  const { control, watch, formState, setValue } = employeeForm;
  const { errors } = formState;

  const { sendEvent } = useAnalytics();

  const isFemale = watch('gender') === Gender.FEMALE;
  const isMailingAddressSameAsResidentialAddress = watch(
    'isMailingAddressSameAsResidentialAddress'
  );
  return (
    <Group>
      <Grid>
        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { ...rest } }) => (
              <TextInput
                {...rest}
                label="First name"
                placeholder="First name"
                required
                masked
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="firstName" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="lastName"
            render={({ field: { ...rest } }) => (
              <TextInput
                {...rest}
                label="Last name"
                placeholder="Last name"
                required
                masked
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="lastName" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field: { ...rest } }) => {
              return (
                <DateInput
                  {...rest}
                  label="Date of birth"
                  minDate={insuredMinDateOfBirth(false, targetEnrollmentDate)}
                  maxDate={insuredMaxDateOfBirth(false, targetEnrollmentDate)}
                  required
                  masked
                />
              );
            }}
          />

          <FormErrorMessage errors={errors} fieldName="dateOfBirth" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="phone"
            render={({ field: { ...rest } }) => (
              <PhoneInput
                {...rest}
                control={control}
                required={isFinalizationMode}
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="phone" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="gender"
            render={({ field: { ...rest } }) => (
              <InputWrapper label="Gender" required={isFinalizationMode}>
                <TabButtonGroup {...rest}>
                  <Tabs.List grow>
                    <Tabs.Tab value={Gender.MALE}>Male</Tabs.Tab>
                    <Tabs.Tab value={Gender.FEMALE}>Female</Tabs.Tab>
                  </Tabs.List>
                </TabButtonGroup>
              </InputWrapper>
            )}
          />

          <FormErrorMessage errors={errors} fieldName="gender" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="isSmoker"
            render={({ field: { ...rest } }) => (
              <InputWrapper label="Tobacco user" required={isFinalizationMode}>
                <TabButtonGroup {...rest}>
                  <Tabs.List grow>
                    <Tabs.Tab value={YesNo.YES}>Yes</Tabs.Tab>
                    <Tabs.Tab value={YesNo.NO}>No</Tabs.Tab>
                  </Tabs.List>
                </TabButtonGroup>
              </InputWrapper>
            )}
          />

          <FormErrorMessage errors={errors} fieldName="isSmoker" />
        </Grid.Col>

        {isFemale && (
          <>
            <Grid.Col span={{ sm: 6 }}>
              <Controller
                control={control}
                name="isPregnant"
                render={({ field: { ...rest } }) => (
                  <InputWrapper label="Pregnant" required={isFinalizationMode}>
                    <TabButtonGroup {...rest}>
                      <Tabs.List grow>
                        <Tabs.Tab value={YesNo.YES}>Yes</Tabs.Tab>
                        <Tabs.Tab value={YesNo.NO}>No</Tabs.Tab>
                      </Tabs.List>
                    </TabButtonGroup>
                  </InputWrapper>
                )}
              />

              <FormErrorMessage errors={errors} fieldName="isPregnant" />
            </Grid.Col>
            <Grid.Col span={{ sm: 6 }} />
          </>
        )}

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="email"
            render={({ field: { ...rest } }) => (
              <TextInput
                {...rest}
                disabled
                label="Company email"
                placeholder="Email"
                required
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="email" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="personalEmail"
            render={({ field: { ...rest } }) => (
              <TextInput
                {...rest}
                required={isFinalizationMode}
                value={rest.value ?? undefined}
                label="Personal email"
                placeholder="Email"
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="personalEmail" />
        </Grid.Col>

        <Grid.Col>
          <Controller
            control={control}
            name="residentialAddress"
            render={({ field: { value, onBlur, onChange, ...rest } }) => (
              <AddressInput
                {...rest}
                onBlur={() => {
                  onChange(value);
                  onBlur();
                  setValue('fipsCode', undefined);
                  setPossibleFipsCodes([]);
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  sendEvent('change_address');
                }}
                required={isFinalizationMode}
                notifySubscribers={(newAddress) => onChange(newAddress)}
                onChange={onChange}
                label="Residential address"
                value={value || ''}
                placeholder="Type residential address"
                masked
              />
            )}
          />
          <FormErrorMessage errors={errors} fieldName="residentialAddress" />
        </Grid.Col>
        {possibleFipsCodes.length > 1 && (
          <Grid.Col>
            <Controller
              control={control}
              name="fipsCode"
              render={({ field: { ...rest } }) => (
                <Select
                  {...rest}
                  label="County of residence"
                  placeholder="Select county of residence"
                  data={possibleFipsCodes.map((fipsCode) =>
                    fipsCodeToLabeledData(fipsCode)
                  )}
                />
              )}
            />
            <FormErrorMessage errors={errors} fieldName="fipsCode" />
          </Grid.Col>
        )}

        <Grid.Col>
          <Controller
            control={control}
            name="isMailingAddressSameAsResidentialAddress"
            render={({ field: { value, ...rest } }) => (
              <Checkbox
                {...rest}
                isChecked={value}
                label="Mailing address is the same as residential address"
                shouldHaveBorder={false}
                size="sm"
              />
            )}
          />

          <FormErrorMessage
            errors={errors}
            fieldName="isMailingAddressSameAsResidentialAddress"
          />
        </Grid.Col>

        {!isMailingAddressSameAsResidentialAddress && (
          <Grid.Col>
            <Controller
              control={control}
              name="mailingAddress"
              render={({ field: { onChange, ...rest } }) => (
                <AddressInput
                  {...rest}
                  notifySubscribers={(address: string) => onChange(address)}
                  onChange={onChange}
                  required={isFinalizationMode}
                  placeholder="Type mailing address"
                  label="Mailing address"
                  size="md"
                  masked
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="mailingAddress" />
          </Grid.Col>
        )}

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="ssn"
            render={({ field: { value, ...rest } }) => (
              <PasswordInput
                {...rest}
                label="SSN"
                placeholder="XXXXXXXXX"
                value={value || ''}
                maxLength={9}
                onKeyDown={validateOnlyNumbers}
                required={isFinalizationMode}
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="ssn" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            name="citizenshipStatus"
            control={control}
            render={({ field }) => (
              <CitizenshipStatusInput
                {...field}
                selectProps={{
                  required: isFinalizationMode,
                  clearable: !isFinalizationMode,
                }}
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="citizenshipStatus" />
        </Grid.Col>

        {!isEmployeeMode && (
          <>
            <Grid.Col mt="lg">
              <Text fw={600}>Employment info</Text>
            </Grid.Col>

            <Grid.Col span={{ sm: 6 }}>
              <EmployeeClassInput
                control={control}
                name="class"
                employerId={employerId}
              />
            </Grid.Col>
            <Grid.Col span={{ sm: 6 }}>
              <Controller
                name="employmentType"
                control={control}
                render={({ field }) => <EmploymentTypeInput {...field} />}
              />

              <FormErrorMessage errors={errors} fieldName="employmentType" />
            </Grid.Col>
            <Grid.Col span={{ sm: 6 }}>
              <Controller
                name="wageType"
                control={control}
                render={({ field }) => <WageTypeInput {...field} />}
              />

              <FormErrorMessage errors={errors} fieldName="wageType" />
            </Grid.Col>
          </>
        )}
      </Grid>
    </Group>
  );
};
