import { IconAlertTriangle, IconMail } from '@tabler/icons-react';
import {
  EMPLOYEE_DYNAMIC_ROUTES,
  getFullName,
  useDynamicRouter,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { Drawer, Flex, Icon, Modal, Text, brand } from '@zorro/zorro-ui-design';

import {
  AccountInvitationForm,
  AddEnrollmentRecordForm,
  ChangeEmployeeClassForm,
  DeleteEnrollmentRecord,
  EditEmployeeRoleForm,
  EditEnrollmentsRecord,
  EmployeeEmailForm,
  EmployeeLeaveOfAbsenceForm,
  TerminateEmployeeForm,
  UpdateEligibilityForm,
} from '../EmployeeActions';
import { DeleteEmployee } from '../EmployeeActions/AccountManagement/DeleteEmployee';
import { ModalTitle } from '../ModalComponents';
import { EditIdFromEmployerForm } from './EmployeeLayoutSidebar/EditIdFromEmployerForm';
import { getEmployeeRole } from './EmployeeLayoutUtils';

export enum EmployeeLayoutAction {
  EDIT_COMPANY_EMAIL = 'EDIT_COMPANY_EMAIL',
  DELETE_EMPLOYEE = 'DELETE_EMPLOYEE',
  TERMINATE_EMPLOYEE = 'TERMINATE_EMPLOYEE',
  SET_LEAVE_OF_ABSENCE = 'SET_LEAVE_OF_ABSENCE',
  UPDATE_ELIGIBILITY = 'UPDATE_ELIGIBILITY',
  EDIT_EMPLOYEE_ROLE = 'EDIT_EMPLOYEE_ROLE',
  EDIT_ID_FROM_EMPLOYER = 'EDIT_ID_FROM_EMPLOYER',
  ADD_ENROLLMENT = 'ADD_ENROLLMENT',
  EDIT_ENROLLMENT = 'EDIT_ENROLLMENT',
  DELETE_ENROLLMENT = 'DELETE_ENROLLMENT',
  EMAIL_ACCOUNT_INVITATION = 'EMAIL_ACCOUNT_INVITATION',
  CHANGE_EMPLOYEE_CLASS = 'CHANGE_EMPLOYEE_CLASS',
}

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId?: string;
  action?: EmployeeLayoutAction;
  setAction: (action?: EmployeeLayoutAction) => void;
};

export const EmployeeLayoutActions = ({
  employerId,
  employeeId,
  onboardingPeriodId,
  action,
  setAction,
}: Props) => {
  const { navigateToRoute } = useDynamicRouter(EMPLOYEE_DYNAMIC_ROUTES);
  const { data: employee, isLoading: isLoadingEmployee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });
  const { data: userRoles, isLoading: isLoadingRoles } = useMonolithQuery({
    method: 'usersControllerGetUserRoles',
    params: employee && [employerId, employee.userId],
  });

  if (!employee || !userRoles || isLoadingEmployee || isLoadingRoles) {
    return null;
  }

  const handleCloseAction = () => setAction(undefined);
  const role = getEmployeeRole(userRoles);

  return (
    <>
      <Drawer
        opened={action === EmployeeLayoutAction.EDIT_COMPANY_EMAIL}
        onClose={handleCloseAction}
        title="Edit company email"
      >
        <EmployeeEmailForm
          onSuccess={handleCloseAction}
          onCancel={handleCloseAction}
          employeeId={employeeId}
        />
      </Drawer>
      <Modal
        opened={action === EmployeeLayoutAction.DELETE_EMPLOYEE}
        onClose={handleCloseAction}
        size="lg"
        title={
          <Flex align="center">
            <Icon
              icon={IconAlertTriangle}
              color={brand.zorroFire700}
              size={32}
            />
            <Text size="xl" ml="sm" fw={600}>
              Delete employee?
            </Text>
          </Flex>
        }
      >
        <DeleteEmployee
          onClose={handleCloseAction}
          employerId={employerId}
          employeeId={employeeId}
        />
      </Modal>
      <Drawer
        onClose={handleCloseAction}
        title="Terminate Employee"
        opened={action === EmployeeLayoutAction.TERMINATE_EMPLOYEE}
      >
        <TerminateEmployeeForm
          selectedEmployees={[{ ...employee, fullName: getFullName(employee) }]}
          onSuccess={handleCloseAction}
        />
      </Drawer>
      <Drawer
        onClose={handleCloseAction}
        title="Set a leave of absence"
        opened={action === EmployeeLayoutAction.SET_LEAVE_OF_ABSENCE}
      >
        <EmployeeLeaveOfAbsenceForm
          selectedEmployees={[employee]}
          onSuccess={handleCloseAction}
        />
      </Drawer>
      <Drawer
        opened={action === EmployeeLayoutAction.CHANGE_EMPLOYEE_CLASS}
        onClose={handleCloseAction}
        title="Change class"
      >
        <ChangeEmployeeClassForm
          selectedEmployees={[employee]}
          employerId={employerId}
          onClose={handleCloseAction}
          onSuccess={handleCloseAction}
        />
      </Drawer>

      <Drawer
        onClose={handleCloseAction}
        title="Update eligibility"
        opened={action === EmployeeLayoutAction.UPDATE_ELIGIBILITY}
      >
        <UpdateEligibilityForm
          selectedEmployees={[employee]}
          onSuccess={handleCloseAction}
        />
      </Drawer>
      <Drawer
        opened={action === EmployeeLayoutAction.EDIT_EMPLOYEE_ROLE}
        title="Change employee role"
        onClose={handleCloseAction}
      >
        <EditEmployeeRoleForm
          selectedEmployees={[{ ...employee, role }]}
          onSuccess={handleCloseAction}
        />
      </Drawer>
      <Drawer
        opened={action === EmployeeLayoutAction.EDIT_ID_FROM_EMPLOYER}
        title="Change employee ID"
        onClose={handleCloseAction}
      >
        <EditIdFromEmployerForm
          onClose={handleCloseAction}
          employeeId={employeeId}
        />
      </Drawer>
      <Drawer
        opened={action === EmployeeLayoutAction.ADD_ENROLLMENT}
        title="Add enrollment record"
        onClose={handleCloseAction}
      >
        <AddEnrollmentRecordForm
          employerId={employerId}
          onSuccess={(onboardingPeriodId) => {
            setAction(undefined);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            onboardingPeriodId
              ? navigateToRoute('EMPLOYEE_ENROLLMENT_TAB', [
                  { employerId, employeeId, onboardingPeriodId },
                ])
              : navigateToRoute('EMPLOYEE_PAGE', [{ employerId, employeeId }]);
          }}
          selectedEmployees={[
            {
              id: employeeId,
              firstName: employee.firstName,
              lastName: employee.lastName,
            },
          ]}
        />
      </Drawer>
      {onboardingPeriodId && (
        <Drawer
          opened={action === EmployeeLayoutAction.EDIT_ENROLLMENT}
          title="Edit enrollment record"
          onClose={handleCloseAction}
        >
          <EditEnrollmentsRecord
            onSuccess={handleCloseAction}
            employerId={employerId}
            selectedEmployees={[
              {
                employeeId,
                firstName: employee.firstName,
                lastName: employee.lastName,
                onboardingPeriodId,
              },
            ]}
          />
        </Drawer>
      )}
      <Modal
        size="md"
        overlayProps={{
          color: brand.zorroCoal900,
          opacity: 0.9,
        }}
        showSeparator={false}
        onClose={handleCloseAction}
        opened={action === EmployeeLayoutAction.DELETE_ENROLLMENT}
        title={
          <ModalTitle
            title="Delete enrollment record?"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M8.33333 8.33333L11.6667 11.6667M11.6667 8.33333L8.33333 11.6667M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z"
                  stroke="#1A1721"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            bgIcon="zorroFire.3"
            titleOrder={2}
          />
        }
        styles={{
          header: {
            height: '120px',
          },
          close: {
            color: '#676879',
            position: 'absolute',
            right: '24px',
            top: '24px',
          },
        }}
      >
        <DeleteEnrollmentRecord
          onSubmit={() =>
            navigateToRoute('EMPLOYEE_PAGE', [{ employerId, employeeId }])
          }
          onboardingPeriodId={onboardingPeriodId}
          onClose={handleCloseAction}
        />
      </Modal>
      <Modal
        opened={action === EmployeeLayoutAction.EMAIL_ACCOUNT_INVITATION}
        onClose={handleCloseAction}
        title={
          <ModalTitle
            title={`Send account invitation email to ${getFullName(employee)}?`}
            bgIcon={brand.zorroPlum100}
            icon={<IconMail color={brand.zorroCoal900} />}
            titleOrder={2}
          />
        }
        showSeparator={false}
        size="lg"
      >
        <AccountInvitationForm
          selectedEmployees={[
            {
              userId: employee.userId,
              employerId: employee.employerId,
            },
          ]}
          onClose={handleCloseAction}
          onSuccess={handleCloseAction}
        />
      </Modal>
    </>
  );
};
