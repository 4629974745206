import {
  getFullName,
  useDynamicDonRouter,
  useDynamicMurrietaRouter,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { Button, Group, Stack, Text } from '@zorro/zorro-ui-design';

import { useMonolithMutation } from '../../hooks';

type Props = {
  employerId: string;
  employeeId: string;
  onClose: () => void;
};

export const DeleteEmployee = ({ employerId, employeeId, onClose }: Props) => {
  const { isEmployerAdmin } = useRoles();
  const { navigateToRoute: navigateToRouteMurrieta } =
    useDynamicMurrietaRouter();
  const { navigateToRoute: navigateToRouteDon } = useDynamicDonRouter();
  const { tryMutate: mutateDeleteEmployee } = useMonolithMutation({
    method: 'employeesControllerDelete',
    successMessage: 'Employee has been deleted successfully',
  });

  const { data: employee, isLoading } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  if (!employee || isLoading) {
    return null;
  }

  const handleDeleteEmployee = async () => {
    const mutateResult = await mutateDeleteEmployee([employeeId]);
    if (mutateResult.isOk()) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isEmployerAdmin
        ? navigateToRouteDon('EMPLOYEES', [])
        : navigateToRouteMurrieta('EMPLOYEES', [{ employerId }]);
    }
  };

  return (
    <Stack gap="2rem">
      <Text>
        {`Are you sure you want to delete ${getFullName(employee)}?
      This action cannot be undone`}
      </Text>
      <Group justify="end">
        <Button variant="secondary" size="md" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleDeleteEmployee}
          variant="primary"
          bg="zorroFire.7"
          size="md"
        >
          Delete employee
        </Button>
      </Group>
    </Stack>
  );
};
