import { IconPrinter } from '@tabler/icons-react';
import { ActionIcon, Icon, brand } from '@zorro/zorro-ui-design';

type Props = {
  onClick: () => void;
};

export const PrintButton = ({ onClick }: Props) => {
  return (
    <ActionIcon
      style={{ border: `1px solid ${brand.zorroGray200}` }}
      size="lg"
      onClick={onClick}
    >
      <Icon icon={IconPrinter} />
    </ActionIcon>
  );
};
