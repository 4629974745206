/* eslint-disable @typescript-eslint/no-explicit-any */
import { MonolithService } from '@zorro/clients';
import { EndpointCall } from '@zorro/shared/utils';
import { NextApiRequest, NextApiResponse } from 'next';

import { withApi, withApiSession } from './session';

export async function monolithClientHandler(
  req: NextApiRequest,
  res: NextApiResponse
) {
  async function apiHandler(monolithClient: MonolithService) {
    try {
      const { method, params } = req.body as EndpointCall;
      const response = await (monolithClient[method] as any)(...params);
      res.status(200).json(response);
    } catch (error) {
      res.status(error.status).json(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  req.body.anonymous
    ? await withApi(req, res, apiHandler)
    : await withApiSession(req, res, apiHandler);
}
