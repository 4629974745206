import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import {
  CreateEmployeeDto,
  EmployerDto,
  EmploymentType,
  Gender,
  OpenEnrollmentPeriodDto,
  WageType,
} from '@zorro/clients';
import {
  formatDateISO,
  getDateUtil,
  parseDate,
  parseDateISO,
} from '@zorro/shared/formatters';
import {
  SUCCESS_MESSAGES,
  VALIDATION_MESSAGES,
  addEmployeeSchema,
  callEndpoint,
  fipsCodeToLabeledData,
  getAdultDefaultDate,
  logger,
  responseErrorToString,
  showErrorNotification,
  showSuccessNotification,
  useForm,
  useMurrietaRouter,
  validateAddress,
  validateOnlyNumbers,
} from '@zorro/shared/utils';
import {
  AddressInput,
  Button,
  Center,
  Checkbox,
  CurrencyInput,
  DateInput,
  FormErrorMessage,
  Grid,
  InputWrapper,
  LabelWithTooltip,
  MonthPickerInput,
  PasswordInput,
  PhoneInput,
  Select,
  Space,
  Stack,
  TabButtonGroup,
  Tabs,
  TextInput,
} from '@zorro/zorro-ui-design';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { EmployeeClassInput } from '../EmployeeClassInput/EmployeeClassInput';
import { useLoadingOverlay } from '../LoadingOverlayContext';
import { calculateEligibleFrom } from './createEmployee.utils';

export interface CreateEmployeeFormFields {
  idFromEmployer?: string;
  firstName: string;
  lastName: string;
  email: string;
  personalEmail?: string;
  phone?: string;
  residentialAddress?: string;
  fipsCode?: string;
  dateOfBirth: Date;
  gender?: Gender | null;
  class: string;
  salary?: number;
  hireDate: Date;
  eligibleFrom: Date;
  sendActivationEmail: boolean;
  ssn?: string;
  wageType?: WageType;
  employmentType?: EmploymentType;
}

export type CreateEmployeeFormProps = {
  employer: EmployerDto;
  enrollmentPeriods: OpenEnrollmentPeriodDto[];
  onSuccess: () => void;
};

export const CreateEmployeeForm = ({
  employer,
  enrollmentPeriods,
  onSuccess,
}: CreateEmployeeFormProps) => {
  const minEffectiveFrom =
    getDateUtil().min(
      enrollmentPeriods.map((item) => {
        return parseDateISO(item.effectiveFrom);
      })
    ) || '';

  const effectiveFrom = formatDateISO(minEffectiveFrom);

  const queryClient = useQueryClient();
  const { reloadPage } = useMurrietaRouter();
  const { startLoading, stopLoading } = useLoadingOverlay();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [possibleFipsCodes, setPossibleFipsCodes] = useState<string[]>([]);

  const { control, formState, watch, setValue, getValues, setError } =
    useForm<CreateEmployeeFormFields>({
      mode: 'all',
      resolver: yupResolver(addEmployeeSchema(employer.waitingPeriod)),
      defaultValues: {
        sendActivationEmail: true,
      },
    });

  const { errors, isValid } = formState;

  const hireDate = watch('hireDate');

  useEffect(() => {
    const calculatedEligibleFrom = getDateUtil().max(
      calculateEligibleFrom(hireDate, employer.waitingPeriod),
      parseDateISO(effectiveFrom)
    );

    const eligibleFrom = getValues('eligibleFrom');
    const parsedEligibleFrom = parseDate(eligibleFrom);

    if (hireDate && !calculatedEligibleFrom.isSame(parsedEligibleFrom, 'day')) {
      setValue('eligibleFrom', calculatedEligibleFrom.toDate(), {
        shouldValidate: true,
      });
    }
  }, [getValues, setValue, hireDate, employer.waitingPeriod, effectiveFrom]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const { residentialAddress, ...data } = getValues();

    startLoading();
    const employee: CreateEmployeeDto = {
      ...data,
      dateOfBirth: formatDateISO(data.dateOfBirth),
      eligibleFrom: formatDateISO(data.eligibleFrom),
      hireDate: formatDateISO(data.hireDate),
      sendActivationEmail: data.sendActivationEmail,
      employerId: employer.id,
      address: residentialAddress,
      personalEmail: data.personalEmail || null,
      salary: data.salary || null,
    };

    if (residentialAddress) {
      const { isValidAddress, possibleFipsCodes } =
        await validateAddress(residentialAddress);
      if (!isValidAddress) {
        setError('residentialAddress', {
          message: VALIDATION_MESSAGES.residentialAddressInvalid,
        });
        stopLoading();
        setIsSubmitting(false);
        return;
      } else if (
        possibleFipsCodes &&
        possibleFipsCodes.length > 1 &&
        !data.fipsCode
      ) {
        setPossibleFipsCodes(possibleFipsCodes);
        setError('fipsCode', {
          message: VALIDATION_MESSAGES.countyDisambiguity,
        });
        stopLoading();
        setIsSubmitting(false);
        return;
      }
    }

    try {
      await callEndpoint({
        method: 'employeesControllerCreate',
        params: [employee],
      });

      showSuccessNotification({
        message: SUCCESS_MESSAGES.EMPLOYEE_ADD_SUCCESS_MESSAGE,
        title: 'Success!',
        id: 'employee-creation-success-notification',
      });

      reloadPage();
      await queryClient.invalidateQueries();

      onSuccess();
    } catch (error) {
      logger.error(error);
      showErrorNotification({ message: responseErrorToString(error) });
    } finally {
      stopLoading();
    }

    setIsSubmitting(false);
  };

  return (
    <Stack>
      <Grid>
        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="idFromEmployer"
            render={({ field: { ...rest } }) => (
              <TextInput
                {...rest}
                label="Employee ID"
                placeholder="Employee ID"
              />
            )}
          />

          <FormErrorMessage fieldName="idFromEmployer" errors={errors} />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { ...rest } }) => (
              <TextInput
                {...rest}
                required
                label="First name"
                placeholder="First name"
              />
            )}
          />

          <FormErrorMessage fieldName="firstName" errors={errors} />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="lastName"
            render={({ field: { ...rest } }) => (
              <TextInput
                {...rest}
                required
                label="Last name"
                placeholder="Last name"
              />
            )}
          />

          <FormErrorMessage fieldName="lastName" errors={errors} />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="email"
            render={({ field: { ...rest } }) => (
              <TextInput
                {...rest}
                required
                label="Company email"
                placeholder="Company email"
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="email" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="personalEmail"
            render={({ field: { ...rest } }) => (
              <TextInput
                {...rest}
                label="Personal email"
                placeholder="Personal email"
              />
            )}
          />

          <FormErrorMessage fieldName="personalEmail" errors={errors} />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="phone"
            render={({ field: { value, ...rest } }) => (
              <PhoneInput
                {...rest}
                label="Phone number"
                placeholder="Phone number"
                control={control}
              />
            )}
          />

          <FormErrorMessage fieldName="phone" errors={errors} />
        </Grid.Col>

        <Grid.Col>
          <Controller
            control={control}
            name="residentialAddress"
            render={({ field: { onChange, onBlur, value, ...rest } }) => (
              <AddressInput
                {...rest}
                value={value}
                onBlur={() => {
                  onChange(value);
                  onBlur();
                  setPossibleFipsCodes([]);
                  setValue('fipsCode', undefined);
                }}
                notifySubscribers={(address: string) => onChange(address)}
                onChange={onChange}
                label="Address"
                placeholder="Address"
                required
              />
            )}
          />
          <FormErrorMessage errors={errors} fieldName="residentialAddress" />
        </Grid.Col>
        {possibleFipsCodes.length > 1 && (
          <Grid.Col>
            <Controller
              control={control}
              name="fipsCode"
              render={({ field: { ...rest } }) => (
                <Select
                  {...rest}
                  label="County of residence"
                  placeholder="Select county of residence"
                  data={possibleFipsCodes.map((fipsCode) =>
                    fipsCodeToLabeledData(fipsCode)
                  )}
                />
              )}
            />
            <FormErrorMessage errors={errors} fieldName="fipsCode" />
          </Grid.Col>
        )}

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field: { ...rest } }) => (
              <DateInput
                {...rest}
                required
                label="Date of birth"
                defaultLevel="decade"
                defaultDate={getAdultDefaultDate()}
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="dateOfBirth" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, ...rest } }) => (
              <InputWrapper label="Gender">
                <TabButtonGroup {...rest} onChange={onChange}>
                  <Tabs.List grow>
                    <Tabs.Tab value={Gender.MALE}>Male</Tabs.Tab>
                    <Tabs.Tab value={Gender.FEMALE}>Female</Tabs.Tab>
                  </Tabs.List>
                </TabButtonGroup>
              </InputWrapper>
            )}
          />

          <FormErrorMessage errors={errors} fieldName="gender" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <EmployeeClassInput
            control={control}
            name="class"
            employerId={employer.id}
          />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="salary"
            render={({ field: { ...rest } }) => (
              <CurrencyInput
                {...rest}
                label="Yearly salary"
                placeholder="Yearly salary"
              />
            )}
          />

          <FormErrorMessage fieldName="salary" errors={errors} />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="hireDate"
            render={({ field: { ...rest } }) => (
              <DateInput {...rest} required label="Hire date" />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="hireDate" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="eligibleFrom"
            render={({ field: { ...rest } }) => (
              <MonthPickerInput
                {...rest}
                label="Eligibility start date"
                monthsListFormat="MMM D"
                valueFormat="MMMM D, YYYY"
                minDate={
                  effectiveFrom
                    ? parseDateISO(effectiveFrom).toDate()
                    : undefined
                }
              />
            )}
          />
          <FormErrorMessage errors={errors} fieldName="eligibleFrom" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="ssn"
            render={({ field: { value, ...rest } }) => (
              <PasswordInput
                {...rest}
                label="SSN"
                placeholder="XXXXXXXXX"
                value={value || ''}
                maxLength={9}
                onKeyDown={validateOnlyNumbers}
              />
            )}
          />
          <FormErrorMessage fieldName="ssn" errors={errors} />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="employmentType"
            render={({ field: { ...rest } }) => (
              <Select
                {...rest}
                label="Employment Type"
                placeholder="Select employment type"
                data={[
                  { value: EmploymentType.FULL_TIME, label: 'Full Time' },
                  { value: EmploymentType.PART_TIME, label: 'Part Time' },
                ]}
                required
              />
            )}
          />
          <FormErrorMessage fieldName="employmentType" errors={errors} />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="wageType"
            render={({ field: { ...rest } }) => (
              <Select
                {...rest}
                label="Wage Type"
                placeholder="Select wage type"
                data={[
                  { value: WageType.SALARY, label: 'Salary' },
                  { value: WageType.HOURLY, label: 'Hourly' },
                ]}
                required
              />
            )}
          />
          <FormErrorMessage fieldName="wageType" errors={errors} />
        </Grid.Col>
      </Grid>

      <Space h="lg" />

      <Controller
        control={control}
        name="sendActivationEmail"
        render={({ field: { value, ...rest } }) => (
          <Checkbox
            {...rest}
            isChecked={value}
            shouldHaveBorder={false}
            label={
              <LabelWithTooltip
                label="Send invitation"
                tooltipLabel="Employees need an invitation to set up their Zorro account and log in for the first time"
              />
            }
          />
        )}
      />

      <Space h="lg" />

      <Center>
        <Button disabled={!isValid || isSubmitting} onClick={handleSubmit}>
          Add Employee
        </Button>
      </Center>
    </Stack>
  );
};
