import {
  appendQueryParam,
  useDynamicRouter,
  useImpersonation,
  useMobileDetector,
} from '@zorro/shared/utils';
import { Center, Group, Icon, Text, Tooltip } from '@zorro/zorro-ui-design';
import { ElementType } from 'react';

import { useAppLayout } from '../AppLayoutContext';
import classNames from './AppLayoutSidebarItem.module.scss';
import { AppLayoutSidebarItemSkeleton } from './AppLayoutSidebarItemSkeleton';

export type AppLayoutSidebarItemProps = {
  link: string;
  label: string;
  icon: ElementType;
  isLoading?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
};

export const AppLayoutSidebarItem = ({
  label,
  icon,
  link: linkProp,
  isLoading = false,
  isDisabled = false,
  isHidden = false,
}: AppLayoutSidebarItemProps) => {
  const { isSidebarOpen, toggleSidebar } = useAppLayout();
  const { employee, isImpersonated } = useImpersonation();
  const { isMobile } = useMobileDetector();
  const { router } = useDynamicRouter({});

  if (isLoading) {
    return <AppLayoutSidebarItemSkeleton />;
  }

  const link = isImpersonated
    ? appendQueryParam(linkProp, 'impersonatedEmployeeId', employee?.id || '')
    : linkProp;

  const isChecked = router.asPath.includes(link);

  const handleClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push(link);
    if (isMobile) {
      toggleSidebar();
    }
  };

  return (
    <Tooltip disabled={isSidebarOpen} position="right" label={label}>
      <Group
        display={isHidden ? 'none' : 'flex'}
        data-opened={isSidebarOpen}
        data-disabled={isDisabled}
        data-checked={isChecked}
        classNames={classNames}
        onClick={handleClick}
      >
        <Center w={22}>
          <Icon icon={icon} />
        </Center>
        {isSidebarOpen && <Text>{label}</Text>}
      </Group>
    </Tooltip>
  );
};
