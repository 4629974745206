import { AppShell } from '@mantine/core';
import { useImpersonation, useMobileDetector } from '@zorro/shared/utils';
import { ReactNode } from 'react';

import { useAppLayout } from './AppLayoutContext';
import { AppLayoutHeader } from './AppLayoutHeader/AppLayoutHeader';
import { AppLayoutMain } from './AppLayoutMain';
import classNames from './AppLayoutMain/AppLayoutMain.module.scss';
import { AppLayoutSidebar } from './AppLayoutSidebar';
import { AppLayoutSidebarItem } from './AppLayoutSidebarItem';
import { AppLayoutConstants, AppLayoutVariant } from './AppLayoutUtils';

type Props = {
  children: ReactNode;
  variant?: AppLayoutVariant;
  shouldShowSearch?: boolean;
  shouldHideSidebar?: boolean;
  shouldOpenSidebar?: boolean;
  shouldShowDonElenaMenuItems?: boolean;
  isDon?: boolean;
};

export function AppLayout({
  children,
  variant,
  shouldShowSearch = false,
  shouldHideSidebar = false,
  shouldOpenSidebar = false,
  shouldShowDonElenaMenuItems = false,
  isDon = false,
}: Props) {
  const { isImpersonated } = useImpersonation();
  const { isSidebarOpen } = useAppLayout();
  const { isDesktop } = useMobileDetector();

  return (
    <AppShell
      bg="zorroGray.10"
      withBorder={false}
      classNames={classNames}
      data-variant={variant}
      data-desktop={isDesktop}
      data-with-sidebar={!shouldHideSidebar}
      header={{
        height: isImpersonated
          ? AppLayoutConstants.HEADER_IMPERSONATION_HEIGHT
          : AppLayoutConstants.HEADER_HEIGHT,
      }}
      navbar={{
        width:
          isSidebarOpen || shouldOpenSidebar
            ? AppLayoutConstants.MENU_OPEN_WIDTH
            : AppLayoutConstants.MENU_CLOSE_WIDTH,
        collapsed: { mobile: true, desktop: shouldHideSidebar },
        breakpoint: 'xs',
      }}
    >
      <AppLayoutHeader
        shouldShowMenuButton={!shouldHideSidebar && !shouldOpenSidebar}
        shouldShowDonElenaMenuItems={shouldShowDonElenaMenuItems}
        shouldShowSearch={shouldShowSearch}
        isDon={isDon}
      />
      {children}
    </AppShell>
  );
}

AppLayout.SidebarItem = AppLayoutSidebarItem;
AppLayout.Sidebar = AppLayoutSidebar;
AppLayout.Main = AppLayoutMain;
