import { FronteggNextJSSession } from '@frontegg/nextjs';
import { EnvParams, isProductionEnvironment } from '@zorro/environment';
import pino from 'pino';

export const defaultLogLevel = 'info';

export const getLogLevel = (): string =>
  process.env[EnvParams.PINO_LOG_LEVEL] || defaultLogLevel;

const config: pino.LoggerOptions = {
  level: getLogLevel(),
};

if (!isProductionEnvironment()) {
  config.transport =
    /*
     * INFO: We encounter open handles here with unit test runs,
     * therefore we enable pretty printing ONLY for "development" env
     * and have it disabled for both "production" and "test" envs (set by jest)
     *
     * See more: https://github.com/pinojs/pino/issues/1268
     */
    process.env.NODE_ENV === 'development'
      ? { target: 'pino-pretty' }
      : undefined;
}

export const logger = pino(config);

export function getLogger(session?: FronteggNextJSSession) {
  return logger.child({
    userId: session?.user.sub,
    roles: session?.user.roles.join(';'),
    permissions: session?.user.permissions.join(';'),
    tenantId: session?.user.tenantId,
  });
}
