import { WaitingPeriod } from '@zorro/clients';
import { waitingPeriodLabelConfig } from '@zorro/shared/utils';
import { ISelectProps, LabelWithTooltip, Select } from '@zorro/zorro-ui-design';
import { ForwardedRef, forwardRef } from 'react';
import { ControllerRenderProps, Path } from 'react-hook-form';

export const waitingPeriodOptions = [
  WaitingPeriod.NONE,
  WaitingPeriod.THIRTY_DAYS,
  WaitingPeriod.SIXTY_DAYS,
].map((waitingPeriod) => ({
  label: waitingPeriodLabelConfig[waitingPeriod],
  value: waitingPeriod,
}));

type WaitingPeriodFieldValues = { waitingPeriod?: WaitingPeriod | null };
type Props<
  T extends WaitingPeriodFieldValues,
  U extends Path<T>,
> = ControllerRenderProps<T, U> & {
  selectProps?: ISelectProps;
  isRequired?: boolean;
};

export const WaitingPeriodInput = forwardRef(
  <T extends WaitingPeriodFieldValues, U extends Path<T>>(
    { selectProps, isRequired, ...field }: Props<T, U>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Select
        {...field}
        {...selectProps}
        ref={ref}
        size="md"
        label={
          <LabelWithTooltip
            label="Waiting period"
            tooltipLabel="Coverage starts on the 1st of the month following the specified waiting period"
            isRequired={isRequired}
          />
        }
        placeholder="Waiting period"
        data={waitingPeriodOptions}
      />
    );
  }
);

WaitingPeriodInput.displayName = 'WaitingPeriodInput';
