import { ZorroQuestionmarkIcon } from '@zorro/shared/assets';
import {
  Box,
  Card,
  Drawer,
  Group,
  Link,
  Stack,
  Title,
} from '@zorro/zorro-ui-design';
import { ReactNode, useState } from 'react';

import { FormFooter } from '../../FormFooter';

type LinkData = {
  name: string;
  href: string;
  isDrawer: boolean;
};

type Props = {
  links: LinkData[];
  children?: ReactNode;
};

const FaqSection = ({ links, children }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <Card bd="1px solid zorroGray.2" miw="15rem" p="sm">
      <Stack gap="xs">
        <Group gap="xxs" wrap="nowrap">
          <ZorroQuestionmarkIcon />
          <Title order={4}>FAQs</Title>
        </Group>

        <Stack gap="xxs">
          {links.map((link: LinkData, idx) => (
            <Box key={link.name}>
              {link.isDrawer ? (
                <Link
                  href={`#show_faq_${idx + 1}`}
                  anchorProps={{
                    onClick: (event) => {
                      event.preventDefault();
                      setIsDrawerOpen(true);
                    },
                    size: 'sm',
                  }}
                >
                  {link.name}
                </Link>
              ) : (
                <Link
                  href={link.href}
                  target="_blank"
                  anchorProps={{ size: 'sm' }}
                >
                  {link.name}
                </Link>
              )}
            </Box>
          ))}
        </Stack>
      </Stack>

      <Drawer
        onClose={() => setIsDrawerOpen(false)}
        title="Frequently asked questions"
        position="right"
        opened={isDrawerOpen}
      >
        <>
          {children}
          <FormFooter
            primaryLabel="Close"
            primaryButtonProps={{ onClick: () => setIsDrawerOpen(false) }}
          />
        </>
      </Drawer>
    </Card>
  );
};

export default FaqSection;
