import { DisconnectedIcon, ThreeDotsIcon } from '@zorro/shared/assets';
import {
  ActionIcon,
  Icon,
  Modal,
  RadixMenu,
  Text,
} from '@zorro/zorro-ui-design';
import { useState } from 'react';

import { ModalFooter, ModalTitle } from '../ModalComponents';
import { useMonolithMutation } from '../hooks';

type Props = {
  finchProviderDisplayName: string;
  employerId: string;
};

export const FinchConnectionCardDotsMenu = ({
  finchProviderDisplayName,
  employerId,
}: Props) => {
  const [open, setOpen] = useState(false);

  const [isDisconnectPayrollModalOpen, setIsDisconnectPayrollModalOpen] =
    useState<boolean>(false);

  const { tryMutate: mutateFinchDisconnect } = useMonolithMutation({
    successMessage: 'Payroll sync disconnected successfully.',
    method: 'finchControllerConnectDisconnect',
  });

  return (
    <>
      <RadixMenu open={open} onOpenChange={setOpen}>
        <RadixMenu.Trigger>
          <ActionIcon isChecked={open} isTransparent>
            <ThreeDotsIcon />
          </ActionIcon>
        </RadixMenu.Trigger>

        <RadixMenu.Content style={{ width: 200 }} align="end">
          <RadixMenu.Item onClick={() => setIsDisconnectPayrollModalOpen(true)}>
            Disconnect
          </RadixMenu.Item>
        </RadixMenu.Content>
      </RadixMenu>
      <Modal
        size="lg"
        opened={isDisconnectPayrollModalOpen}
        onClose={() => setIsDisconnectPayrollModalOpen(false)}
        showSeparator={false}
        title={
          <ModalTitle
            title="Disconnect payroll sync"
            bgIcon="zorroFire.3"
            icon={<Icon icon={DisconnectedIcon} size={32} />}
            titleOrder={2}
          />
        }
      >
        <Text mb="xl">
          {`Are you sure you want to disconnect ${finchProviderDisplayName} from Zorro?`}
        </Text>
        <ModalFooter
          onClose={() => setIsDisconnectPayrollModalOpen(false)}
          onSubmit={() => mutateFinchDisconnect([{ employerId }])}
          submitVariant="primary-red"
          submitText="Disconnect"
        />
      </Modal>
    </>
  );
};
