import {
  Applicant,
  FamilyUnit,
  InsuredPeopleDto,
  OnboardingPeriodDto,
} from '@zorro/clients';
import { calculateAgeForEnrollment } from '@zorro/shared/utils';

export const getApplicantsFromInsured = (
  familyUnit: FamilyUnit,
  email: string,
  insured?: InsuredPeopleDto,
  onboardingPeriod?: OnboardingPeriodDto
): Applicant[] => {
  if (!insured || !onboardingPeriod) {
    return [];
  }

  const employee: Applicant = {
    age: calculateAgeForEnrollment(
      insured.employee.dateOfBirth,
      onboardingPeriod.targetEnrollmentDate
    ),
    isSmoker: insured.employee.isSmoker,
    isChild: false,
    isEmployee: true,
    dateOfBirth: insured.employee.dateOfBirth,
    firstName: insured.employee.firstName,
    lastName: insured.employee.lastName,
    email,
  };

  const spouse = insured.spouse
    ? {
        age: calculateAgeForEnrollment(
          insured.spouse.dateOfBirth,
          onboardingPeriod.targetEnrollmentDate
        ),
        isSmoker: insured.spouse.isSmoker,
        isChild: false,
        isEmployee: false,
        dateOfBirth: insured.spouse.dateOfBirth,
        firstName: insured.spouse.firstName,
        lastName: insured.spouse.lastName,
      }
    : undefined;

  const dependents = insured.dependents
    ? insured.dependents.map((dependent) => {
        const age = calculateAgeForEnrollment(
          dependent.dateOfBirth,
          onboardingPeriod.targetEnrollmentDate
        );
        return {
          age,
          isSmoker: dependent.isSmoker,
          isChild: age < 26,
          isEmployee: false,
          dateOfBirth: dependent.dateOfBirth,
          firstName: dependent.firstName,
          lastName: dependent.lastName,
        };
      })
    : [];

  switch (familyUnit) {
    case FamilyUnit.FAMILY: {
      return spouse && dependents
        ? [employee, spouse, ...dependents]
        : [employee];
    }
    case FamilyUnit.EMPLOYEE_CHILD: {
      return dependents ? [employee, ...dependents] : [employee];
    }
    case FamilyUnit.EMPLOYEE_ONLY: {
      return [employee];
    }
    case FamilyUnit.EMPLOYEE_SPOUSE: {
      return spouse ? [employee, spouse] : [employee];
    }
    default: {
      return [];
    }
  }
};
