// These environment variables are required for a basic flow in zorro-ui to succeed
export enum RequiredEnvParams {
  DATABASE_URL = 'DATABASE_URL',
  ENVIRONMENT_NAME = 'ENVIRONMENT_NAME',
  NEXT_PUBLIC_ENVIRONMENT_NAME = 'NEXT_PUBLIC_ENVIRONMENT_NAME',
  FRONTEGG_API_GATEWAY_URL = 'FRONTEGG_API_GATEWAY_URL',
  FRONTEGG_API_KEY = 'FRONTEGG_API_KEY',
  FRONTEGG_CLIENT_ID = 'FRONTEGG_CLIENT_ID',
  IDEON_API_KEY = 'IDEON_API_KEY',
  IDEON_API_URL = 'IDEON_API_URL',
  INVITE_JWT_KEY = 'INVITE_JWT_KEY',
  MONOLITH_API_KEY = 'MONOLITH_API_KEY',
  MONOLITH_PORT = 'MONOLITH_PORT',
  MONOLITH_URL = 'MONOLITH_URL',
  POSTGRES_DB = 'POSTGRES_DB',
  POSTGRES_HOST = 'POSTGRES_HOST',
  POSTGRES_PASSWORD = 'POSTGRES_PASSWORD',
  POSTGRES_PORT = 'POSTGRES_PORT',
  POSTGRES_USER = 'POSTGRES_USER',
  PRISMA_FIELD_ENCRYPTION_KEY = 'PRISMA_FIELD_ENCRYPTION_KEY',
  SLACK_APP_AUTH_TOKEN = 'SLACK_APP_AUTH_TOKEN',
  SLACK_SUBMISSIONS_CHANNEL_ID = 'SLACK_SUBMISSIONS_CHANNEL_ID',
  SLACK_PRODUCT_NOTIFICATIONS_CHANNEL_ID = 'SLACK_PRODUCT_NOTIFICATIONS_CHANNEL_ID',
  SLACK_SENTRY_NOTIFICATIONS_CHANNEL_ID = 'SLACK_SENTRY_NOTIFICATIONS_CHANNEL_ID',
  SLACK_SUPPORT_ISSUE_REPORTS_CHANNEL_ID = 'SLACK_SUPPORT_ISSUE_REPORTS_CHANNEL_ID',
  SLACK_FINCH_SYNC_CHANNEL_ID = 'SLACK_FINCH_SYNC_CHANNEL_ID',
  SPLIT_IO_API_KEY = 'SPLIT_IO_API_KEY',
  NEXT_PUBLIC_ZORRO_UI_BASE_URL = 'NEXT_PUBLIC_ZORRO_UI_BASE_URL',
  NEXT_PUBLIC_ZORRO_AGENCY_ID = 'NEXT_PUBLIC_ZORRO_AGENCY_ID',
  USPS_USERNAME = 'USPS_USERNAME',
  ECHO_PAYMENTS_BASE_URL = 'ECHO_PAYMENTS_BASE_URL',
  ECHO_PAYMENTS_CLIENT_ID = 'ECHO_PAYMENTS_CLIENT_ID',
  ECHO_PAYMENTS_CLIENT_SECRET = 'ECHO_PAYMENTS_CLIENT_SECRET',
}

// These environment variables are either optional for a basic flow in zorro-ui to succeed or only relevant for dev environment
export enum EnvParams {
  DEV_SEED_ENROLL = 'DEV_SEED_ENROLL',
  DEV_SEED_TENANT_FILTER = 'DEV_SEED_TENANT_FILTER',
  FRONTEGG_EMPLOYEE_ROLE_ID = 'FRONTEGG_EMPLOYEE_ROLE_ID',
  FRONTEGG_EMPLOYER_ADMIN_ROLE_ID = 'FRONTEGG_EMPLOYER_ADMIN_ROLE_ID',
  FRONTEGG_OMNIPOTENT_ADMIN_ROLE_ID = 'FRONTEGG_OMNIPOTENT_ADMIN_ROLE_ID',
  FRONTEGG_AGENT_ROLE_ID = 'FRONTEGG_AGENT_ROLE_ID',
  FRONTEGG_ACCOUNT_SUPERVISOR_ROLE_ID = 'FRONTEGG_ACCOUNT_SUPERVISOR_ROLE_ID',
  FRONTEGG_AGENCY_ADMIN_ROLE_ID = 'FRONTEGG_AGENCY_ADMIN_ROLE_ID',
  FRONTEGG_OPERATOR_ROLE_ID = 'FRONTEGG_OPERATOR_ROLE_ID',
  FRONTEGG_MY_ZORRO_TENANT_ID = 'FRONTEGG_MY_ZORRO_TENANT_ID',
  FRONTEGG_HOSTED_LOGIN = 'FRONTEGG_HOSTED_LOGIN',
  ZORRO_AWS_REGION = 'ZORRO_AWS_REGION',
  ZORRO_AWS_ACCESS_KEY_ID = 'ZORRO_AWS_ACCESS_KEY_ID',
  ZORRO_AWS_SECRET_ACCESS_KEY = 'ZORRO_AWS_SECRET_ACCESS_KEY',
  NEXT_PUBLIC_MURRIETA_BASE_URL = 'NEXT_PUBLIC_MURRIETA_BASE_URL',
  NEXT_PUBLIC_ZORRO_UI_BASE_URL = 'NEXT_PUBLIC_ZORRO_UI_BASE_URL',
  NEXT_PUBLIC_FULLSTORY_ORG_ID = 'NEXT_PUBLIC_FULLSTORY_ORG_ID',
  PINO_LOG_LEVEL = 'PINO_LOG_LEVEL',
  SENDGRID_API_KEY = 'SENDGRID_API_KEY',
  SENDGRID_EMAIL_FROM = 'SENDGRID_EMAIL_FROM',
  SENDGRID_EMAIL_FROM_NAME = 'SENDGRID_EMAIL_FROM_NAME',
  SENDGRID_SANDBOX = 'SENDGRID_SANDBOX',
  SENDGRID_SIGNUP_EMAIL_TEMPLATE_ID = 'SENDGRID_SIGNUP_EMAIL_TEMPLATE_ID',
  SENTRY_AUTH_TOKEN = 'SENTRY_AUTH_TOKEN',
  SENTRY_DSN = 'SENTRY_DSN',
  SENTRY_DSN_MONOLITH = 'SENTRY_DSN_MONOLITH',
  SENDGRID_SUBMIT_EMAIL_TEMPLATE_ID = 'SENDGRID_SUBMIT_EMAIL_TEMPLATE_ID',
  SENDGRID_WAIVE_EMAIL_TEMPLATE_ID = 'SENDGRID_WAIVE_EMAIL_TEMPLATE_ID',
  SENDGRID_SUMMARY_EMAIL_TEMPLATE_ID = 'SENDGRID_SUMMARY_EMAIL_TEMPLATE_ID',
  SENDGRID_ELECTION_WINDOW_LAUNCH_EMAIL_TEMPLATE_ID = 'SENDGRID_ELECTION_WINDOW_LAUNCH_EMAIL_TEMPLATE_ID',
  SENDGRID_ENROLLMENT_CONFIRMATION_EMAIL_TEMPLATE_ID = 'SENDGRID_ENROLLMENT_CONFIRMATION_EMAIL_TEMPLATE_ID',
  SENDGRID_ELECTION_REMINDER_EMAIL_TEMPLATE_ID = 'SENDGRID_ELECTION_REMINDER_EMAIL_TEMPLATE_ID',
  SENDGRID_ADMIN_QLE_EMAIL_TEMPLATE_ID = 'SENDGRID_ADMIN_QLE_EMAIL_TEMPLATE_ID',
  SENDGRID_AGENT_QLE_EMAIL_TEMPLATE_ID = 'SENDGRID_AGENT_QLE_EMAIL_TEMPLATE_ID',
  SENDGRID_AGENT_EMPLOYEE_TERMINATION_EMAIL_TEMPLATE_ID = 'SENDGRID_AGENT_EMPLOYEE_TERMINATION_EMAIL_TEMPLATE_ID',
  SENDGRID_USER_ACTIVATION_EMAIL_TEMPLATE_ID = 'SENDGRID_USER_ACTIVATION_EMAIL_TEMPLATE_ID',
  SENDGRID_AGENT_EMPLOYEE_LEAVE_OF_ABSENCE_EMAIL_TEMPLATE_ID = 'SENDGRID_AGENT_EMPLOYEE_LEAVE_OF_ABSENCE_EMAIL_TEMPLATE_ID',
  SENDGRID_AGENT_NEW_EMPLOYEE_EMAIL_TEMPLATE_ID = 'SENDGRID_AGENT_NEW_EMPLOYEE_EMAIL_TEMPLATE_ID',
  SENDGRID_AGENT_EMPLOYEE_SUBMISSION_EMAIL_TEMPLATE_ID = 'SENDGRID_AGENT_EMPLOYEE_SUBMISSION_EMAIL_TEMPLATE_ID',
  SENDGRID_AGENT_EMPLOYEE_SUBMISSION_RENEWAL_EMAIL_TEMPLATE_ID = 'SENDGRID_AGENT_EMPLOYEE_SUBMISSION_RENEWAL_EMAIL_TEMPLATE_ID',
  SENDGRID_AGENT_EMPLOYEE_SUBMISSION_RENEWAL_WAIVED_EMAIL_TEMPLATE_ID = 'SENDGRID_AGENT_EMPLOYEE_SUBMISSION_RENEWAL_WAIVED_EMAIL_TEMPLATE_ID',
  SENDGRID_PAYMENTS_PAYMENT_METHOD_SUCCESSFULLY_CREATED = 'SENDGRID_PAYMENTS_PAYMENT_METHOD_SUCCESSFULLY_CREATED',
  SENDGRID_PAYMENTS_CONFIRMATION_APPLICATION_TEMPLATE_ID = 'SENDGRID_PAYMENTS_CONFIRMATION_APPLICATION_TEMPLATE_ID',
  SENDGRID_PAYMENTS_CONFIRMATION_PAYMENT_TEMPLATE_ID = 'SENDGRID_PAYMENTS_CONFIRMATION_PAYMENT_TEMPLATE_ID',
  SENDGRID_PAYMENTS_CONFIRMATION_APPLICATION_AND_PAYMENT_TEMPLATE_ID = 'SENDGRID_PAYMENTS_CONFIRMATION_APPLICATION_AND_PAYMENT_TEMPLATE_ID',
  SENDGRID_ADD_EMPLOYER_EMAIL_TEMPLATE_ID = 'SENDGRID_ADD_EMPLOYER_EMAIL_TEMPLATE_ID',
  SENDGRID_UPDATE_EMPLOYER_STATUS_NEEDS_REVIEW_EMAIL_TEMPLATE_ID = 'SENDGRID_UPDATE_EMPLOYER_STATUS_NEEDS_REVIEW_EMAIL_TEMPLATE_ID',
  SENDGRID_UPDATE_EMPLOYER_STATUS_ACTIVE_AGENT_EMAIL_TEMPLATE_ID = 'SENDGRID_UPDATE_EMPLOYER_STATUS_ACTIVE_AGENT_EMAIL_TEMPLATE_ID',
  SENDGRID_UPDATE_EMPLOYER_STATUS_ACTIVE_ADMIN_EMAIL_TEMPLATE_ID = 'SENDGRID_UPDATE_EMPLOYER_STATUS_ACTIVE_ADMIN_EMAIL_TEMPLATE_ID',
  SENDGRID_BULK_EMPLOYEE_UPDATE_TEMPLATE_ID = 'SENDGRID_BULK_EMPLOYEE_UPDATE_TEMPLATE_ID',
  INTERCOM_ACCESS_TOKEN = 'INTERCOM_ACCESS_TOKEN',
  INTERCOM_SECRET_KEY = 'INTERCOM_SECRET_KEY',
  HSA_SERVICE_BASE_URL = 'HSA_SERVICE_BASE_URL',
  HSA_USER_ID = 'HSA_USER_ID',
  HSA_PASSWORD = 'HSA_PASSWORD',
  GOOGLE_SERVICE_ACCOUNT_JSON_PRIVATE_KEY = 'GOOGLE_SERVICE_ACCOUNT_JSON_PRIVATE_KEY',
  GOOGLE_SERVICE_ACCOUNT_JSON_CLIENT_EMAIL = 'GOOGLE_SERVICE_ACCOUNT_JSON_CLIENT_EMAIL',
  FINCH_BASE_URL = 'FINCH_BASE_URL',
  FINCH_CLIENT_ID = 'FINCH_CLIENT_ID',
  FINCH_CLIENT_SECRET = 'FINCH_CLIENT_SECRET',
  NEXT_PUBLIC_FINCH_CLIENT_MANUAL = 'NEXT_PUBLIC_FINCH_CLIENT_MANUAL',
  NEXT_PUBLIC_FINCH_CLIENT_SANDBOX = 'NEXT_PUBLIC_FINCH_CLIENT_SANDBOX',
  E2E_EMPLOYEE_TEST_PASSWORD = 'E2E_EMPLOYEE_TEST_PASSWORD',
  E2E_EMPLOYEE_TEST_EMAIL = 'E2E_EMPLOYEE_TEST_EMAIL',
  E2E_OMNIPOTENT_ADMIN_TEST_PASSWORD = 'E2E_OMNIPOTENT_ADMIN_TEST_PASSWORD',
  E2E_OMNIPOTENTADMIN_TEST_EMAIL = 'E2E_OMNIPOTENTADMIN_TEST_EMAIL',
  E2E_EMPLOYER_TEST_PASSWORD = 'E2E_EMPLOYER_TEST_PASSWORD',
  E2E_EMPLOYER_TEST_EMAIL = 'E2E_EMPLOYER_TEST_EMAIL',
  E2E_AGENT_ACCOUNT_SUPERVISOR_TEST_PASSWORD = 'E2E_AGENT_ACCOUNT_SUPERVISOR_TEST_PASSWORD',
  E2E_AGENT_ACCOUNT_SUPERVISOR_TEST_EMAIL = 'E2E_AGENT_ACCOUNT_SUPERVISOR_TEST_EMAIL',
  E2E_AGENT_TEST_EMAIL = 'E2E_AGENT_TEST_EMAIL',
  E2E_AGENT_TEST_PASSWORD = 'E2E_AGENT_TEST_PASSWORD',
  E2E_DEFAULT_COMPANY_NAME = 'E2E_DEFAULT_COMPANY_NAME',
  E2E_ENROLLMENT_TEAM = 'E2E_ENROLLMENT_TEAM',
  E2E_PRODUCER_AGENCY = 'E2E_PRODUCER_AGENCY',
  METRICS_API_KEY = 'METRICS_API_KEY',
}

export enum Environments {
  DEVELOPMENT = 'development',
  TEST = 'test',
  PRODUCTION = 'production',
}

export function environmentName() {
  return (
    process.env[RequiredEnvParams.ENVIRONMENT_NAME] ||
    process.env[RequiredEnvParams.NEXT_PUBLIC_ENVIRONMENT_NAME]
  );
}

export function stackName() {
  const envName = environmentName();
  switch (envName) {
    case Environments.DEVELOPMENT:
    case Environments.TEST: {
      return 'test';
    }
    case Environments.PRODUCTION: {
      return 'prod';
    }
    default: {
      return envName;
    }
  }
}

export function isProductionEnvironment() {
  return environmentName() === Environments.PRODUCTION;
}

export function isTestEnvironment() {
  return environmentName() === Environments.TEST;
}

export function isDevelopmentEnvironment() {
  return environmentName() === Environments.DEVELOPMENT;
}
