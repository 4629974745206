import { IconCopy, IconEye, IconEyeOff } from '@tabler/icons-react';
import {
  ActionIcon,
  Grid,
  Group,
  Icon,
  Stack,
  Text,
  Title,
  brand,
} from '@zorro/zorro-ui-design';
import { ReactNode, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

/* eslint-disable react/destructuring-assignment */
export type ReadonlyValueProps =
  | {
      id: string;
      label: string;
      value?: ReactNode | string | null;
      span?: number;
      isSensitive?: boolean;
      copyValue?: string | null;
      // eslint-disable-next-line react/boolean-prop-naming
      masked?: boolean;
    }
  | {
      id: string;
      title: string;
      // eslint-disable-next-line react/boolean-prop-naming
      masked?: boolean;
    };

const ReadonlyValue = (props: ReadonlyValueProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);

  if ('title' in props) {
    return (
      <Grid.Col>
        <Title size="h5" fw={600}>
          {props.title}
        </Title>
      </Grid.Col>
    );
  }

  const {
    label,
    value,
    span = 6,
    isSensitive = false,
    copyValue,
    masked,
  } = props;

  return (
    <Grid.Col span={span}>
      <Stack gap="xs">
        <Text size="sm" fw={400} c="zorroGray.4">
          {label}
        </Text>

        <Group gap="xs">
          {value ? (
            <Text
              style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
              masked={masked}
            >
              {isSensitive && isHidden ? '*********' : value}
            </Text>
          ) : (
            <Text>-</Text>
          )}
          {isSensitive && value && (
            <ActionIcon onClick={() => setIsHidden(!isHidden)}>
              <Icon
                icon={isHidden ? IconEye : IconEyeOff}
                color={brand.zorroGray400}
                width={20}
              />
            </ActionIcon>
          )}
          {copyValue && value && (
            <CopyToClipboard text={copyValue}>
              <ActionIcon>
                <IconCopy
                  size={16}
                  cursor="pointer"
                  color={brand.zorroGray400}
                />
              </ActionIcon>
            </CopyToClipboard>
          )}
        </Group>
      </Stack>
    </Grid.Col>
  );
};

type Props = {
  items: ReadonlyValueProps[];
};

export const ReadonlyValuesGrid = ({ items }: Props) => {
  return (
    <Grid>
      {items.map((item) => (
        <ReadonlyValue key={item.id} {...item} />
      ))}
    </Grid>
  );
};
