import {
  ContactIcon,
  IconPersonPurple,
  IconPremiumRights,
  IconScale,
} from '@zorro/shared/assets';
import { Stack } from '@zorro/zorro-ui-design';
import { UseFormReturn } from 'react-hook-form';

import { ContactsFormSection } from './ContactsFormSection';
import { ContactsFormFields } from './ContactsFormUtils';

type Props = {
  contactsForm: UseFormReturn<ContactsFormFields>;
  duplicateFieldName?: keyof ContactsFormFields;
  shouldShowOnboardingContacts?: boolean;
  shouldHideMakeAdmin?: boolean;
  isFinalizationMode?: boolean;
  isDon?: boolean;
};

export const ContactsForm = ({
  contactsForm,
  duplicateFieldName,
  isFinalizationMode,
  shouldShowOnboardingContacts,
  shouldHideMakeAdmin,
  isDon,
}: Props) => {
  const shouldShowDuplicateMode = Boolean(duplicateFieldName);
  const duplicateModeCheckbox = shouldShowDuplicateMode
    ? {
        label: isDon ? 'I am this contact' : 'Same as legal contact',
      }
    : undefined;

  return (
    <Stack gap="2rem">
      {shouldShowOnboardingContacts && (
        <ContactsFormSection
          contactsForm={contactsForm}
          fieldName="onboardingContacts"
          title={isDon ? 'You' : 'Onboarding contact'}
          subtitle="The person who set up and completed the employer account onboarding"
          icon={<IconPersonPurple />}
          isFinalizationMode={isFinalizationMode && isDon}
          shouldHideMakeAdmin={shouldHideMakeAdmin}
        />
      )}

      <ContactsFormSection
        contactsForm={contactsForm}
        fieldName="legalContacts"
        duplicateFieldName={duplicateFieldName}
        title="Legal contact"
        subtitle="The person who signed the service agreement"
        contactTitle=""
        icon={<IconScale />}
        isFinalizationMode={isFinalizationMode}
        shouldHideMakeAdmin={shouldHideMakeAdmin}
        duplicateModeCheckbox={
          shouldShowOnboardingContacts ? duplicateModeCheckbox : undefined
        }
      />

      <ContactsFormSection
        contactsForm={contactsForm}
        fieldName="hrContacts"
        duplicateFieldName={duplicateFieldName}
        title="HR contacts"
        subtitle="Manage benefits, inform employees of changes, and assist with understanding benefits"
        contactTitle="HR contact"
        icon={<ContactIcon />}
        isFinalizationMode={isFinalizationMode}
        shouldHideMakeAdmin={shouldHideMakeAdmin}
        duplicateModeCheckbox={duplicateModeCheckbox}
        isMultiSelect
      />

      <ContactsFormSection
        contactsForm={contactsForm}
        fieldName="financeContacts"
        duplicateFieldName={duplicateFieldName}
        title="Finance contacts"
        subtitle="Receive monthly billing invoices, premium draw alerts, and other financial reports"
        contactTitle="Finance contact"
        icon={<IconPremiumRights />}
        isFinalizationMode={isFinalizationMode}
        shouldHideMakeAdmin={shouldHideMakeAdmin}
        duplicateModeCheckbox={duplicateModeCheckbox}
        isMultiSelect
      />
    </Stack>
  );
};
