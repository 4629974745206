import { EmployerStatus } from '@zorro/clients';
import {
  useCompanyDetailsForm,
  useCompanySetupForm,
  useContactsForm,
  usePlanYearSetupForm,
  useZorroSetupForm,
} from '@zorro/shared/ui';
import { useRoles } from '@zorro/shared/utils';

const isFinalizationMode = true;

type Props = {
  employerStatus: EmployerStatus | undefined;
  isFinalizationMode: boolean;
  employerId: string;
};

export const useReviewFormFinalization = ({
  isFinalizationMode: isFinalizationModeProp,
  employerStatus,
  employerId,
}: Props) => {
  const { isAgentWithoutSupervisor } = useRoles();
  const { companyDetailsForm } = useCompanyDetailsForm({
    employerId,
    isFinalizationMode,
  });
  const { contactsForm } = useContactsForm(employerId, isFinalizationMode);
  const { companySetupForm } = useCompanySetupForm(
    employerId,
    isFinalizationMode
  );
  const { zorroSetupForm } = useZorroSetupForm({ employerId });
  const { planYearSetupForm } = usePlanYearSetupForm({
    shouldFallbackToEarliestPeriod: true,
    isFinalizationMode,
    employerId,
  });

  const { isValid: isCompanyDetailsValid } = companyDetailsForm.formState;
  const { isValid: isContactsValid } = contactsForm.formState;
  const { isValid: isCompanySetupValid } = companySetupForm.formState;
  const { isValid: isZorroSetupValid } = zorroSetupForm.formState;
  const { isValid: isPlanYearSetupValid } = planYearSetupForm.formState;

  return {
    shouldHideActivationButtons:
      isAgentWithoutSupervisor &&
      (employerStatus === EmployerStatus.IN_ZORRO_SETUP ||
        employerStatus === EmployerStatus.NEEDS_REVIEW),
    isFinalizationModeValid:
      !isFinalizationModeProp &&
      isCompanyDetailsValid &&
      isContactsValid &&
      isCompanySetupValid &&
      isZorroSetupValid &&
      isPlanYearSetupValid,
  };
};
