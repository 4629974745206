import { EmployeeDto, EmploymentStatus } from '@zorro/clients';
import { FlagIcon } from '@zorro/shared/assets';
import { formatDateEnLocale } from '@zorro/shared/formatters';
import { ReactElement } from 'react';
import { $enum } from 'ts-enum-util';

const NOT_AVAILABLE = '-';
const flagStyles = { minWidth: '24px' };

export const eligibleLabel = 'Eligible for benefits';
const ineligibleLabel = 'Ineligible for benefits';

export const getEligibilityStatusLabel = (
  eligibilityStatus: EmploymentStatus | null
): string => {
  return $enum.mapValue(eligibilityStatus).with({
    [EmploymentStatus.INELIGIBLE_WAITING]: 'Waiting for benefit eligibility',
    [EmploymentStatus.INELIGIBLE_ON_LEAVE]: 'Benefit eligibility paused',
    [EmploymentStatus.ELIGIBLE_EMPLOYED]: eligibleLabel,
    [EmploymentStatus.ELIGIBLE_UPCOMING_TERMINATION]: eligibleLabel,
    [EmploymentStatus.ELIGIBLE_UPCOMING_LEAVE]: eligibleLabel,
    [EmploymentStatus.INELIGIBLE_TERMINATED]: ineligibleLabel,
    [EmploymentStatus.INELIGIBLE_TERMINATED_BEFORE_ACTIVATION]: ineligibleLabel,
    [$enum.handleNull]: NOT_AVAILABLE,
    [$enum.handleUnexpected]: NOT_AVAILABLE,
  });
};

export const getEligibilityStatusBadgeConfig = (
  eligibilityStatus: EmploymentStatus | null,
  {
    eligibleFrom,
    eligibleUntil,
    leaveOfAbsence,
    createdAt,
  }: Pick<
    EmployeeDto,
    'eligibleFrom' | 'eligibleUntil' | 'leaveOfAbsence' | 'createdAt'
  >
) => {
  const createdAtFallback = createdAt
    ? formatDateEnLocale(createdAt)
    : NOT_AVAILABLE;

  return $enum
    .mapValue(eligibilityStatus)
    .with<{ label: string; icon?: ReactElement; description?: string }>({
      [EmploymentStatus.INELIGIBLE_WAITING]: {
        label: getEligibilityStatusLabel(eligibilityStatus),
        icon: <FlagIcon style={flagStyles} flagColor="#FFC214" grow />,
        description: eligibleFrom
          ? `Eligibility will start on ${formatDateEnLocale(eligibleFrom)}`
          : undefined,
      },
      [EmploymentStatus.INELIGIBLE_ON_LEAVE]: {
        label: getEligibilityStatusLabel(eligibilityStatus),
        icon: <FlagIcon style={flagStyles} flagColor="#FFC214" grow />,
        description: leaveOfAbsence?.endDate
          ? `On leave of absence until ${formatDateEnLocale(
              leaveOfAbsence?.endDate
            )}`
          : undefined,
      },
      [EmploymentStatus.ELIGIBLE_EMPLOYED]: {
        label: getEligibilityStatusLabel(eligibilityStatus),
        icon: <FlagIcon style={flagStyles} flagColor="#00D16C" grow />,
        description: eligibleFrom
          ? `Eligibility started on ${formatDateEnLocale(eligibleFrom)}`
          : `Eligibility started on ${createdAtFallback}`,
      },
      [EmploymentStatus.ELIGIBLE_UPCOMING_TERMINATION]: {
        label: getEligibilityStatusLabel(eligibilityStatus),
        icon: <FlagIcon style={flagStyles} flagColor="#00D16C" withDot grow />,
        description: eligibleUntil
          ? `Eligibility ending on ${formatDateEnLocale(eligibleUntil)}`
          : undefined,
      },
      [EmploymentStatus.ELIGIBLE_UPCOMING_LEAVE]: {
        label: getEligibilityStatusLabel(eligibilityStatus),
        icon: <FlagIcon style={flagStyles} flagColor="#00D16C" withDot grow />,
        description: leaveOfAbsence?.startDate
          ? `Eligibility started on ${formatDateEnLocale(
              leaveOfAbsence?.startDate
            )}`
          : undefined,
      },
      [EmploymentStatus.INELIGIBLE_TERMINATED]: {
        label: getEligibilityStatusLabel(eligibilityStatus),
        icon: <FlagIcon style={flagStyles} flagColor="#FF5636" grow />,
        description: eligibleUntil
          ? `Eligibility ended on ${formatDateEnLocale(eligibleUntil)}`
          : undefined,
      },
      [EmploymentStatus.INELIGIBLE_TERMINATED_BEFORE_ACTIVATION]: {
        label: getEligibilityStatusLabel(eligibilityStatus),
        icon: <FlagIcon style={flagStyles} flagColor="#FF5636" grow />,
        description:
          eligibleFrom && eligibleUntil
            ? 'Terminated before activation'
            : undefined,
      },
      [$enum.handleNull]: {
        label: getEligibilityStatusLabel(eligibilityStatus),
        description: NOT_AVAILABLE,
      },
      [$enum.handleUnexpected]: {
        label: getEligibilityStatusLabel(eligibilityStatus),
        description: NOT_AVAILABLE,
      },
    });
};
