import {
  ErrorMessage,
  FieldValuesFromFieldErrors,
} from '@hookform/error-message';
import {
  FieldErrors,
  FieldName,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

import { ITextProps, ZorroText as Text } from '../Text';

type FormErrorMessageProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = {
  fieldName: TName;
  errors: FieldErrors<TFieldValues>;
  textProps?: Omit<ITextProps, 'children'>;
};

export const FormErrorMessage = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  fieldName,
  errors,
  textProps,
}: FormErrorMessageProps<TFieldValues, TName>) => {
  const errorArray = errors[fieldName];
  if (Array.isArray(errorArray)) {
    if (errorArray.length === 0) {
      return null;
    }
    const errorMessages = errorArray.filter((error) => error?.message) || [
      { message: 'Unexpected error' },
    ];

    return (
      <Text c="zorroFire.7" my="xs" {...textProps}>
        {errorMessages?.[0]?.message}
      </Text>
    );
  }

  return (
    <ErrorMessage
      errors={errors}
      name={
        fieldName as unknown as FieldName<
          FieldValuesFromFieldErrors<TFieldValues>
        >
      }
      render={({ message }) => (
        <Text c="zorroFire.7" my="xs" size="sm" {...textProps}>
          {message}
        </Text>
      )}
    />
  );
};
