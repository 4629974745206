import {
  BenefitDto,
  BenefitEnrollmentDto,
  MonolithService,
  OnboardingPeriodDto,
} from '@zorro/clients';
import { parseDateISO } from '@zorro/shared/formatters';

export type BenefitWithBenefitEnrollment = {
  benefit: BenefitDto;
  benefitEnrollment?: BenefitEnrollmentDto;
};

export function findLatestPeriod<T extends { targetEnrollmentDate: string }>(
  periods: T[]
): T | undefined {
  if (periods.length === 0) {
    return undefined;
  }
  return periods.reduce((prev, current) => {
    return parseDateISO(prev.targetEnrollmentDate).isAfter(
      parseDateISO(current.targetEnrollmentDate)
    )
      ? prev
      : current;
  });
}

export async function getLatestOnboardingPeriod(
  monolithClient: MonolithService,
  employeeId: string
) {
  const periods =
    await monolithClient.onboardingPeriodsControllerFindMany(employeeId);
  return findLatestPeriod(periods);
}

export function fundLastSubmittedOnboardingPeriodId(
  onboardingPeriods: OnboardingPeriodDto[]
): string | undefined {
  const lastSubmittedEnrollmentPeriod = onboardingPeriods.reduce(
    (prevPeriod, currentPeriod) => {
      if (!prevPeriod?.submittedAt && !currentPeriod.submittedAt) {
        return undefined;
      }

      if (!prevPeriod?.submittedAt) {
        return currentPeriod;
      }

      if (!currentPeriod.submittedAt) {
        return prevPeriod;
      }

      return parseDateISO(currentPeriod.submittedAt).isAfter(
        parseDateISO(prevPeriod.submittedAt)
      )
        ? currentPeriod
        : prevPeriod;
    },
    undefined as OnboardingPeriodDto | undefined
  );

  return lastSubmittedEnrollmentPeriod?.id;
}

export async function getLastSubmittedOnboardingPeriodId(
  monolithClient: MonolithService,
  employeeId: string
): Promise<string | undefined> {
  const onboardingPeriods =
    await monolithClient.onboardingPeriodsControllerFindMany(employeeId);

  return fundLastSubmittedOnboardingPeriodId(onboardingPeriods);
}
