import { RequiredEnvParams } from '@zorro/environment';
import { v4 as uuidv4 } from 'uuid';

import { ApiClient } from '../__generated__';

export const API_KEY_HEADER = 'x-api-key';
export const ALLOW_NO_AUTH_REQUEST = 'allowNoAuthRequest';
export const CORRELATION_ID_HEADER = 'x-zorro-correlation-id';

type NextSession = {
  accessToken?: string;
};

type Options = {
  accessToken?: string;
  correlationId?: string;
};

function nextOptions(accessToken?: string, correlationId?: string) {
  return {
    accessToken,
    correlationId: correlationId ?? `${uuidv4()}`,
  };
}

function nestOptions(accessToken?: string, correlationId?: string) {
  return {
    accessToken: accessToken?.replace(/^\s*Bearer /u, '') ?? '',
    correlationId,
  };
}

export const MonolithClientFactory = {
  getInstance({ accessToken, correlationId }: Options) {
    const headers: Record<string, string> = {
      [API_KEY_HEADER]: process.env[RequiredEnvParams.MONOLITH_API_KEY]!,
    };

    if (correlationId) {
      headers[CORRELATION_ID_HEADER] = correlationId;
    }

    if (!process.env[RequiredEnvParams.MONOLITH_URL]) {
      throw new Error('MONOLITH_URL not set');
    }

    // Can't use config service here and port is not parsed out of the box
    const urlBase = process.env[RequiredEnvParams.MONOLITH_URL]!.replace(
      '${MONOLITH_PORT}',
      process.env[RequiredEnvParams.MONOLITH_PORT]!
    );

    return new ApiClient({
      BASE: urlBase,
      TOKEN: accessToken,
      HEADERS: headers,
    });
  },

  getNestInstance(accessToken?: string, correlationId?: string) {
    return this.getInstance(nestOptions(accessToken, correlationId));
  },

  getNextInstance(session?: NextSession, correlationId?: string) {
    return this.getInstance(nextOptions(session?.accessToken, correlationId))
      .monolith;
  },
};
