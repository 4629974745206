import {
  EmployeeDto,
  OnboardingPeriodDto,
  OnboardingType,
  OpenEnrollmentPeriodDto,
} from '@zorro/clients';
import {
  formatDateISO,
  parseDate,
  parseDateISO,
} from '@zorro/shared/formatters';
import { maximalDateISO, minimalDateISO } from '@zorro/shared/utils';
import {
  Box,
  Button,
  Center,
  DateInput,
  DateRangeInput,
  Group,
  Select,
} from '@zorro/zorro-ui-design';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';

import { InformationBoxComponent } from '../../InformationBox';
import {
  ChangeEnrollmentsFormFields,
  addOrEditAlertBoxText,
  formFieldStyle,
  getAvailableEnrollmentTypes,
  handleFormStateChange,
  inlineGroupStyle,
} from './changeEnrollment.utils';

interface SharedEnrollmentFormProps {
  form: UseFormReturn<ChangeEnrollmentsFormFields>;
  onSubmit: (data: ChangeEnrollmentsFormFields) => Promise<void>;
  allOpenEnrollmentPeriods: OpenEnrollmentPeriodDto[];
  singleEmployeeDto?: EmployeeDto;
  associatedOtherOnboardingPeriodsSingleEmployee?: OnboardingPeriodDto[];
}

export const ChangeEnrollmentForm = ({
  form,
  onSubmit,
  allOpenEnrollmentPeriods,
  singleEmployeeDto,
  associatedOtherOnboardingPeriodsSingleEmployee,
}: SharedEnrollmentFormProps) => {
  const { control, setValue, handleSubmit, formState } = form;
  const { isValid } = formState;
  const isSingleEmployee = Boolean(singleEmployeeDto);
  const selectedOEPId = useWatch({
    control,
    name: 'planYear',
  });
  const selectedOEP = selectedOEPId
    ? allOpenEnrollmentPeriods?.find((oep) => oep.id === selectedOEPId)
    : undefined;

  const selectedEnrollmentType = useWatch({
    control,
    name: 'enrollmentType',
  });
  const electionWindow = useWatch({
    control,
    name: 'electionWindow',
  });

  const formValues = {
    planYear: selectedOEP,
    enrollmentType: selectedEnrollmentType,
    electionWindow,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Group>
        <InformationBoxComponent
          title={addOrEditAlertBoxText(isSingleEmployee)}
        />
        <Box style={{ width: '100%' }}>
          <Box style={inlineGroupStyle}>
            <Controller
              control={control}
              name="planYear"
              render={({ field }) => (
                <Select
                  {...field}
                  required
                  label="Plan year"
                  data={allOpenEnrollmentPeriods.map((oep) => ({
                    label: parseDateISO(oep.effectiveFrom).year().toString(),
                    value: oep.id,
                  }))}
                  onChange={(value) => {
                    formValues.planYear =
                      allOpenEnrollmentPeriods.find(
                        (oep) => oep.id === value
                      ) || selectedOEP;
                    handleFormStateChange(
                      formValues,
                      'planYear',
                      setValue,
                      singleEmployeeDto,
                      associatedOtherOnboardingPeriodsSingleEmployee
                    );
                  }}
                  style={formFieldStyle}
                />
              )}
            />
            <Controller
              control={control}
              name="enrollmentType"
              render={({ field: { onChange, ...rest } }) => (
                <Select
                  {...rest}
                  label="Enrollment type"
                  required
                  placeholder="Enrollment type"
                  data={getAvailableEnrollmentTypes(
                    selectedOEP,
                    singleEmployeeDto,
                    associatedOtherOnboardingPeriodsSingleEmployee
                  )}
                  onChange={(value) => {
                    formValues.enrollmentType = value as OnboardingType;
                    handleFormStateChange(
                      formValues,
                      'enrollmentType',
                      setValue,
                      singleEmployeeDto,
                      associatedOtherOnboardingPeriodsSingleEmployee
                    );
                  }}
                  disabled={!selectedOEP}
                  style={formFieldStyle}
                />
              )}
            />
          </Box>
          <Box style={inlineGroupStyle}>
            <Controller
              control={control}
              name="electionWindow"
              render={({ field: { onChange, ...rest } }) => (
                <DateRangeInput
                  {...rest}
                  required
                  label="Election window"
                  maxDate={
                    selectedEnrollmentType === OnboardingType.OPEN_ENROLLMENT &&
                    selectedOEP
                      ? parseDateISO(selectedOEP.effectiveFrom)
                          .subtract(1, 'day')
                          .toDate()
                      : undefined
                  }
                  onChange={(value) => {
                    const [newFrom, newUntil] = value;
                    if (newFrom && newUntil) {
                      formValues.electionWindow = [newFrom, newUntil];
                      handleFormStateChange(
                        formValues,
                        'electionWindow',
                        setValue,
                        singleEmployeeDto,
                        associatedOtherOnboardingPeriodsSingleEmployee
                      );
                    }
                    onChange(value);
                  }}
                  style={formFieldStyle}
                  disabled={!selectedOEP || !selectedEnrollmentType}
                />
              )}
            />
            <Controller
              control={control}
              name="expectedStartOfCoverage"
              render={({ field }) => (
                <DateInput
                  {...field}
                  required
                  label="Expected start of coverage"
                  style={formFieldStyle}
                  minDate={
                    isSingleEmployee
                      ? selectedEnrollmentType === OnboardingType.SPECIAL &&
                        selectedOEP?.effectiveFrom &&
                        electionWindow?.length
                        ? parseDateISO(
                            maximalDateISO(
                              formatDateISO(
                                parseDate(electionWindow[1]).add(1, 'day')
                              ),
                              selectedOEP?.effectiveFrom,
                              singleEmployeeDto?.eligibleFrom
                            )
                          ).toDate()
                        : undefined
                      : electionWindow?.[1]
                        ? parseDateISO(
                            maximalDateISO(
                              formatDateISO(parseDate(electionWindow[1])),
                              selectedOEP?.effectiveFrom
                            )
                          ).toDate()
                        : undefined
                  }
                  maxDate={
                    selectedOEP?.effectiveUntil
                      ? selectedEnrollmentType === OnboardingType.SPECIAL
                        ? parseDateISO(
                            minimalDateISO(
                              selectedOEP.effectiveUntil,
                              singleEmployeeDto?.eligibleUntil
                            )
                          ).toDate()
                        : parseDateISO(selectedOEP.effectiveUntil).toDate()
                      : undefined
                  }
                  disabled={!selectedOEP || !selectedEnrollmentType}
                  readOnly={
                    selectedEnrollmentType === OnboardingType.OPEN_ENROLLMENT
                  }
                />
              )}
            />
          </Box>
        </Box>
      </Group>
      <Center>
        <Button type="submit" size="lg" ml="xl" disabled={!isValid}>
          Submit
        </Button>
      </Center>
    </form>
  );
};
