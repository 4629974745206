import {
  ErrorEvent,
  SuccessEvent,
  useFinchConnect,
} from '@tryfinch/react-connect';
import {
  FINCH_ERRORS,
  getFinchConnectEnvParams,
  showErrorNotification,
} from '@zorro/shared/utils';
import { Button } from '@zorro/zorro-ui-design';

import { useMonolithMutation } from '../../index';

type Props = {
  employerId: string;
};

export const FinchConnect = ({ employerId }: Props) => {
  const { mutate: connect } = useMonolithMutation({
    method: 'finchControllerConnect',
  });

  const { mutate: connectCallback } = useMonolithMutation({
    method: 'finchControllerConnectCallback',
  });

  async function handleSuccess({ code }: SuccessEvent) {
    await connectCallback([code, employerId]);
  }

  async function handleError(_event: ErrorEvent) {
    showErrorNotification({
      message: FINCH_ERRORS.CONNECT_PROCESS_FAILURE,
    });
  }

  const { open } = useFinchConnect({
    ...getFinchConnectEnvParams(),
    sessionId: undefined,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  async function handleConnect() {
    const { sessionId } = await connect([{ employerId }]);
    open({
      sessionId,
    });
  }

  return (
    <Button onClick={handleConnect} size="md">
      Connect
    </Button>
  );
};
