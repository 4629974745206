import { FinchConnectionStatusType } from '@zorro/clients';
import { useMonolithQuery, useRoles } from '@zorro/shared/utils';
import { Button, Center, Group, Loader } from '@zorro/zorro-ui-design';

import { useMonolithMutation } from '../hooks';
import { FinchConnectionCard } from './FinchConnectionCard';
import { FinchDisconnect } from './FinchDisconnect';
import { FinchIntroduction } from './FinchIntroduction';

type Props = {
  employerId: string;
};

export const FinchIntegrationManagement = ({ employerId }: Props) => {
  const { isZorroOperations } = useRoles();
  const { tryMutate: syncCompany } = useMonolithMutation({
    method: 'finchControllerSyncCompany',
  });
  const { tryMutate: syncEnrollments } = useMonolithMutation({
    method: 'finchControllerSyncEnrollments',
  });

  const { isLoading: isLoadingConnectStatus, data: connectStatus } =
    useMonolithQuery({
      method: 'finchControllerConnectStatus',
      params: [{ employerId }],
    });

  if (isLoadingConnectStatus) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (
    connectStatus?.finchConnectionStatus === FinchConnectionStatusType.CONNECTED
  ) {
    return (
      <>
        <FinchConnectionCard {...connectStatus} />
        <Group>
          {isZorroOperations && (
            <>
              <Button size="md" onClick={() => syncCompany([employerId])}>
                Sync company
              </Button>
              <Button size="md" onClick={() => syncEnrollments([employerId])}>
                Sync enrollments
              </Button>
            </>
          )}
          <FinchDisconnect employerId={employerId} />
        </Group>
      </>
    );
  }

  return <FinchIntroduction employerId={employerId} />;
};
