/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';

export class MetricsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns string
   * @throws ApiError
   */
  public metricsControllerIndex(): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/metrics',
    });
  }
}
