import { IconAlertCircle, IconInfoCircle } from '@tabler/icons-react';
import {
  FinchConnectEmployerStatusDto,
  FinchEmployerConnectionStatus,
} from '@zorro/clients';
import { ConnectedIcon } from '@zorro/shared/assets';
import { formatDateTimeEnLocale, getNow } from '@zorro/shared/formatters';
import { FullPageLoader } from '@zorro/shared/ui';
import { useMonolithQuery, useRoles } from '@zorro/shared/utils';
import { DeepReadonly } from '@zorro/types';
import {
  Box,
  Button,
  Card,
  Center,
  Divider,
  Group,
  Icon,
  Image,
  Stack,
  Text,
  Tooltip,
  brand,
} from '@zorro/zorro-ui-design';

import { FinchConnectionCardDotsMenu } from './FinchConnectionCardDotsMenu';

// todo: Remove temp enum once Aviv figures out the new one in the backend
export enum TemporaryConnectionStatus {
  CONNECTED = 'CONNECTED', // Previous full sync was successful
  WAITING_FOR_RESPONSE = 'WAITING_FOR_RESPONSE', // Connected, but still waiting for the response of the initial data read to arrive
  WAITING_FOR_EARLIEST_SYNC_DATE = 'WAITING_FOR_EARLIEST_SYNC_DATE', // Connected, and first data read is successful, but first full sync is still pending due to the fact that the date defined as the earliest-employees-sync-date for this company has not arrived yet
  CONNECTION_LOST = 'CONNECTION_LOST', // Previous connection failed due to lost connection or invalid token
  INITIAL_SYNC_FAILED = 'INITIAL_SYNC_FAILED', // Initial sync (right after connection) had company-level sync failures as described above
  LAST_SYNC_FAILED = 'LAST_SYNC_FAILED', // Last sync attempt had company-level sync failures as described above, which prevented individual syncing in the last 24 hours
}

const temporaryConnectionStatusConfig: DeepReadonly<{
  [tempConnectionStatus in TemporaryConnectionStatus]: {
    status: string;
    description: ((providerName: string) => string) | null;
    descriptionBoxColor: string | null;
    shouldShowDisconnect: boolean;
    shouldShowFixConnection: boolean;
    shouldShowNextSyncToolTip: boolean;
  };
}> = {
  [TemporaryConnectionStatus.CONNECTED]: {
    status: 'Connected',
    description: null,
    descriptionBoxColor: null,
    shouldShowDisconnect: true,
    shouldShowFixConnection: false,
    shouldShowNextSyncToolTip: false,
  },
  [TemporaryConnectionStatus.WAITING_FOR_RESPONSE]: {
    status: 'Pending initial connection',
    description: () =>
      'Initial connection configuration in progress (typically takes 1–2 weeks)',
    descriptionBoxColor: brand.zorroYolk200,
    shouldShowDisconnect: true,
    shouldShowFixConnection: false,
    shouldShowNextSyncToolTip: false,
  },
  [TemporaryConnectionStatus.WAITING_FOR_EARLIEST_SYNC_DATE]: {
    status: 'Connected',
    description: null,
    descriptionBoxColor: null,
    shouldShowDisconnect: true,
    shouldShowFixConnection: false,
    shouldShowNextSyncToolTip: false,
  },
  [TemporaryConnectionStatus.CONNECTION_LOST]: {
    status: 'Connection lost',
    description: (providerName: string) =>
      `Connection with ${providerName} was lost. Please try restoring the connection or contact our support team for assistance`,
    descriptionBoxColor: brand.zorroFire300,
    shouldShowDisconnect: true,
    shouldShowFixConnection: true,
    shouldShowNextSyncToolTip: true,
  },
  [TemporaryConnectionStatus.INITIAL_SYNC_FAILED]: {
    status: 'Failed to sync',
    description: (providerName: string) =>
      `Unable to sync with ${providerName}. Please contact our support team for assistance`,
    descriptionBoxColor: brand.zorroFire300,
    shouldShowDisconnect: true,
    shouldShowFixConnection: false,
    shouldShowNextSyncToolTip: true,
  },
  [TemporaryConnectionStatus.LAST_SYNC_FAILED]: {
    status: 'Failed to sync',
    description: (providerName: string) =>
      `Last sync with ${providerName} failed. Please contact our support team for assistance`,
    descriptionBoxColor: brand.zorroFire300,
    shouldShowDisconnect: true,
    shouldShowFixConnection: false,
    shouldShowNextSyncToolTip: true,
  },
};

const FinchConnectionCardNew = ({
  connectionStatus,
  finchConnectionStatus,
  connectionMessage,
  employerId,
  providerId,
}: FinchConnectEmployerStatusDto) => {
  const { isZorroOperations } = useRoles();

  const { data: finchProvider, isLoading } = useMonolithQuery({
    method: 'finchControllerGetFinchProvider',
    params: providerId && [providerId],
  });

  if (isLoading) {
    return <FullPageLoader />;
  }
  const tempStatus = TemporaryConnectionStatus.CONNECTION_LOST; // todo: get this from Aviv
  const lastSynced = getNow().subtract(1, 'day').startOf('day'); // todo: get this from Aviv
  const nextSync = getNow().add(1, 'day').startOf('day'); // todo: get this from Aviv
  const cardConfig = temporaryConnectionStatusConfig[tempStatus];

  return (
    <Card maw="60rem" p="xl">
      <Stack gap="xl">
        <Group justify="space-between" align="flex-start">
          <Group wrap="nowrap" gap="xl">
            <Card maw="11.5rem" p="md">
              <Image src={finchProvider?.logoUrl} alt="Provider" />
            </Card>
            <Stack gap="0.25rem">
              <Text fw={600} size="md">
                {finchProvider?.displayName}
              </Text>
              <Text size="md">
                <Text size="md" c={brand.zorroGray400}>
                  Status:
                </Text>
                {` ${cardConfig.status}`}
              </Text>
            </Stack>
          </Group>
          {isZorroOperations && cardConfig.shouldShowDisconnect && (
            <FinchConnectionCardDotsMenu
              finchProviderDisplayName={finchProvider?.displayName || ''}
              employerId={employerId}
            />
          )}
        </Group>
        {cardConfig.description && cardConfig.descriptionBoxColor && (
          <Group
            gap="xs"
            p="sm"
            bg={cardConfig.descriptionBoxColor}
            style={(theme) => ({
              borderRadius: theme.radius.md,
            })}
          >
            <Icon icon={IconAlertCircle} size="1.25rem" />
            <Text>
              {cardConfig.description(finchProvider?.displayName || '')}
            </Text>
          </Group>
        )}
      </Stack>
      <Divider my="md" />
      <Stack gap="xl">
        <Group wrap="nowrap" gap="xl" justify="space-between">
          <Text size="md">
            <Text size="md" c={brand.zorroGray400}>
              Last synced:
            </Text>
            {` ${formatDateTimeEnLocale(lastSynced)}`}
          </Text>
          <Group gap="xs">
            <Text size="md">
              <Text size="md" c={brand.zorroGray400}>
                Next scheduled sync:
              </Text>
              {` ${formatDateTimeEnLocale(nextSync)}`}
            </Text>
            {cardConfig.shouldShowNextSyncToolTip && (
              <Tooltip
                label="Next sync may fail unless the current issue is resolved"
                position="top"
              >
                <Box pos="relative">
                  <Center>
                    <Icon
                      icon={IconInfoCircle}
                      color={brand.zorroFire700}
                      size="1rem"
                    />
                  </Center>
                </Box>
              </Tooltip>
            )}
            {cardConfig.shouldShowFixConnection && (
              <Button
                variant="secondary"
                size="sm"
                style={() => ({
                  borderColor: brand.zorroGray200,
                })}
                onClick={() => {}}
              >
                <Group gap="xs">
                  <Icon icon={ConnectedIcon} size={20} />
                  <Text>Fix connection</Text>
                </Group>
              </Button>
            )}
          </Group>
        </Group>
      </Stack>
    </Card>
  );
};

const finchConnectionCardConfig: Record<
  FinchEmployerConnectionStatus,
  { label?: string }
> = {
  [FinchEmployerConnectionStatus.CONNECTED]: { label: 'Connected' },
  [FinchEmployerConnectionStatus.DISCONNECTED]: { label: 'Disconnected' },
  [FinchEmployerConnectionStatus.UNAUTHORIZED]: { label: 'Unauthorized' },
  [FinchEmployerConnectionStatus.ERROR]: { label: 'Error' },
};

export const FinchConnectionCard = ({
  providerId,
  connectionStatus,
}: FinchConnectEmployerStatusDto) => {
  const { label } = connectionStatus
    ? finchConnectionCardConfig[connectionStatus]
    : {};

  return (
    <Card maw="45rem" p="xl">
      <Group wrap="nowrap" gap="xl">
        <Group wrap="nowrap">
          <Stack>
            <Group wrap="nowrap" align="start">
              <Stack gap="xxs" flex="0 0 auto">
                <Text>Connection status:</Text>
              </Stack>
              <Stack gap="sm">
                <Group gap="xs">
                  <Box
                    style={{ borderRadius: '50%' }}
                    bg="zorroGreen.6"
                    w={12}
                    h={12}
                  />
                  <Text>{label}</Text>
                </Group>
              </Stack>
            </Group>
            <Group wrap="nowrap" align="start">
              <Stack gap="xxs" flex="0 0 auto">
                <Text>Integration:</Text>
              </Stack>
              <Text>{providerId}</Text>
            </Group>
          </Stack>
        </Group>
      </Group>
    </Card>
  );
};
