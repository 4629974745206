/* eslint-disable sonarjs/slow-regex */
export const formatCurrencyCompactEnUs = (
  amount: number,
  { minimumFractionDigits = 0, maximumFractionDigits = 2 } = {}
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amount);
};

export const formatCurrencyEnUs = (
  amount: number,
  { minimumFractionDigits = 0, maximumFractionDigits = 2 } = {}
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amount);
};

export const formatCurrencyEnUsNoCents = (amount: number) => {
  return formatCurrencyEnUs(amount, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const valueIsNaN = (value: string) => {
  return Number.isNaN(+value) || Number.isNaN(Number.parseFloat(value));
};

export const formatPotentialNumber = (value: string) => {
  if (valueIsNaN(value)) {
    return value;
  }

  return formatCurrencyEnUs(Number.parseFloat(value));
};

export const formatStringAmountToUSD = (amount: string) => {
  return valueIsNaN(amount)
    ? ''
    : `$${amount}`.replaceAll(/\B(?=(\d{3})+(?!\d))/gu, ',');
};

export const formatToFixedFloat = (value?: number | null, precision = 2) => {
  if (value === 0) {
    return 0;
  }
  return value ? Number.parseFloat(value.toFixed(precision)) : null;
};

export const parseStringToFloat = (amount?: string): number => {
  return Number.parseFloat(amount?.replaceAll(/[$,]/gu, '') || '');
};

export function parseNumberToFloat(numberString: string | number) {
  if (typeof numberString === 'string') {
    return Number.parseFloat(numberString);
  }
  return numberString;
}
