import { yupResolver } from '@hookform/resolvers/yup';
import { IconHexagons, IconSwitchHorizontal } from '@tabler/icons-react';
import { VALIDATION_MESSAGES, useForm } from '@zorro/shared/utils';
import { Roles } from '@zorro/types';
import {
  Group,
  Icon,
  RadioCard,
  RadioGroup,
  Stack,
} from '@zorro/zorro-ui-design';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { EmployeeRow } from '../../EmployeesDatatable';
import { FormFooter } from '../../FormFooter';
import { useBatchCallEndpoint } from '../../hooks';
import { ErrorsTable } from '../Errors/ErrorsTable';

// 🔮 tech debt: should be fixed at the `EmployeeRow` definition
function getEmployeeCurrentRole(role: string): Roles {
  if (role === Roles.EMPLOYEE) {
    return Roles.EMPLOYEE;
  }
  return Roles.EMPLOYER_ADMIN;
}

function getEmployeesCurrentRole(
  selectedEmployees: Pick<
    EmployeeRow,
    'id' | 'role' | 'userId' | 'firstName' | 'lastName'
  >[]
) {
  const roles = selectedEmployees.map((user) => user.role);
  const uniqueRoles = [...new Set(roles)]; // Get unique roles

  if (uniqueRoles.length === 1) {
    // If there's only one unique role, return that role
    return getEmployeeCurrentRole(uniqueRoles[0]);
  }

  // If there are more than one unique role (i.e. some admins and some employees)
  return undefined;
}

const editEmployeeRoleSchema = yup.object({
  role: yup
    .mixed<Roles>()
    .required(VALIDATION_MESSAGES.roleRequired)
    .oneOf(Object.values(Roles), VALIDATION_MESSAGES.roleRequired),
});

type EditEmployeeRoleFormFields = yup.InferType<typeof editEmployeeRoleSchema>;

type EditEmployeeRoleFormProps = {
  selectedEmployees: Pick<
    EmployeeRow,
    'id' | 'role' | 'userId' | 'firstName' | 'lastName'
  >[];
  onSuccess: () => void;
};

export const EditEmployeeRoleForm = ({
  selectedEmployees,
  onSuccess,
}: EditEmployeeRoleFormProps) => {
  const isSingleEmployee = selectedEmployees.length === 1;
  const singleEmployee = selectedEmployees[0];

  const { executeBatchCall, errors } = useBatchCallEndpoint({
    methodName: 'usersControllerChangeCustomerFacingRole',
    singularItemName: 'employee',
    action: 'updated',
    batchSize: 5,
  });

  const { control, getValues, formState } = useForm<EditEmployeeRoleFormFields>(
    {
      mode: 'all',
      resolver: yupResolver(editEmployeeRoleSchema),
      defaultValues: {
        role: isSingleEmployee
          ? getEmployeeCurrentRole(singleEmployee.role)
          : getEmployeesCurrentRole(selectedEmployees),
      },
    }
  );

  const { isValid } = formState;

  const handleSubmit = async () => {
    const { role } = getValues();

    const batchItems = selectedEmployees.map(
      ({ userId, firstName, lastName }) => ({
        key: `${firstName} ${lastName}`,
        params: [userId, { role }] as const,
      })
    );

    await executeBatchCall(batchItems, { onSuccess });
  };

  return (
    <>
      <Stack>
        <Controller
          control={control}
          name="role"
          render={({ field }) => (
            <RadioGroup
              {...field}
              description="Role"
              variant="with-description"
            >
              <Group wrap="nowrap">
                <RadioCard
                  icon={<Icon icon={IconHexagons} />}
                  iconColor="zorroYolk.4"
                  label="Employee"
                  value={Roles.EMPLOYEE}
                />
                <RadioCard
                  icon={<Icon icon={IconSwitchHorizontal} />}
                  iconColor="zorroSky.1"
                  label="Admin"
                  value={Roles.EMPLOYER_ADMIN}
                />
              </Group>
            </RadioGroup>
          )}
        />

        <ErrorsTable
          tableTitle="Employee name"
          errors={errors}
          isBulk={selectedEmployees?.length > 1}
        />
      </Stack>

      <FormFooter
        primaryLabel="Save"
        primaryButtonProps={{
          onClick: handleSubmit,
          disabled: !isValid,
        }}
      />
    </>
  );
};
