import { yupResolver } from '@hookform/resolvers/yup';
import { DependentTypeEnum, EmployeeDto } from '@zorro/clients';
import { formatDateISO } from '@zorro/shared/formatters';
import {
  ERROR_MESSAGES,
  VALIDATION_MESSAGES,
  convertFromMaybeYesNo,
  logger,
  useForm,
  useImpersonation,
  useMonolithQuery,
  useRoles,
  validateAddress,
} from '@zorro/shared/utils';
import { useEffect, useState } from 'react';

import { useLoadingOverlay } from '../LoadingOverlayContext';
import { useMonolithMutation } from '../hooks';
import {
  InsuredDependentsFormFields,
  getDependentInsuredSchema,
} from './DependentsFormInputs';
import {
  InsuredEmployeeFormFields,
  getEmployeeFormSchema,
} from './EmployeeFormInputs';
import {
  UseInsuredFormReturnType,
  convertDependentSubtypeDtoToForm,
  getDefaultInsuredFormFields,
  mapDependentDtoToBaseInsuredFormFields,
  mapFormFieldsToDependentDto,
  mapInsuredDtoToBaseInsuredFormFields,
} from './InsuredFormUtils';
import {
  InsuredSpouseFormFields,
  getSpouseInsuredSchema,
} from './SpouseFormInputs';

type Props = {
  employeeId: string;
  isFinalizationMode: boolean;
};

const applySpecialFieldTransform = (
  partialDto: Record<string, unknown>,
  formValue: unknown,
  originalValue: unknown,
  dtoField: string,
  transform: (value: unknown) => unknown
) => {
  if (formValue !== originalValue) {
    partialDto[dtoField] = transform(formValue);
  }
};

const getChangedEmployeeFields = (
  formValues: InsuredEmployeeFormFields,
  originalEmployee: EmployeeDto | undefined,
  isMailingAddressSameAsResidentialAddress?: boolean
  // eslint-disable-next-line sonarjs/cognitive-complexity
): { partialDto: Partial<EmployeeDto> } => {
  if (!originalEmployee) {
    throw new Error('Cannot get changed fields: originalEmployee is required');
  }

  const partialDto: Partial<EmployeeDto> = {};

  if (formValues.firstName !== originalEmployee.firstName) {
    partialDto.firstName = formValues.firstName;
  }
  if (formValues.lastName !== originalEmployee.lastName) {
    partialDto.lastName = formValues.lastName;
  }
  if (formValues.gender !== originalEmployee.gender) {
    partialDto.gender = formValues.gender;
  }
  if (formValues.residentialAddress !== originalEmployee.address) {
    partialDto.address = formValues.residentialAddress;
  }
  if (formValues.phone !== originalEmployee.phone) {
    partialDto.phone = formValues.phone;
  }
  if (formValues.ssn !== originalEmployee.ssn) {
    partialDto.ssn = formValues.ssn;
  }
  if (formValues.employmentType !== originalEmployee.employmentType) {
    partialDto.employmentType = formValues.employmentType;
  }
  if (formValues.wageType !== originalEmployee.wageType) {
    partialDto.wageType = formValues.wageType;
  }
  if (formValues.personalEmail !== originalEmployee.personalEmail) {
    partialDto.personalEmail = formValues.personalEmail || null;
  }
  if (formValues.class !== originalEmployee.class) {
    partialDto.class = formValues.class || undefined;
  }
  if (formValues.fipsCode !== originalEmployee.fipsCode) {
    partialDto.fipsCode = formValues.fipsCode;
  }
  if (formValues.citizenshipStatus !== originalEmployee.citizenshipStatus) {
    partialDto.citizenshipStatus = formValues.citizenshipStatus;
  }

  const formDateOfBirth = formValues.dateOfBirth
    ? new Date(formValues.dateOfBirth).toISOString().split('T')[0]
    : '';
  if (formDateOfBirth !== originalEmployee.dateOfBirth) {
    partialDto.dateOfBirth = formatDateISO(formValues.dateOfBirth);
  }

  const currentMailingAddress = isMailingAddressSameAsResidentialAddress
    ? formValues.residentialAddress
    : formValues.mailingAddress;
  if (currentMailingAddress !== originalEmployee.mailingAddress) {
    partialDto.mailingAddress = currentMailingAddress;
  }

  if (
    convertFromMaybeYesNo(formValues.isSmoker) !== originalEmployee.isSmoker
  ) {
    partialDto.isSmoker = convertFromMaybeYesNo(formValues.isSmoker);
  }

  if (
    convertFromMaybeYesNo(formValues.isPregnant) !== originalEmployee.isPregnant
  ) {
    partialDto.isPregnant = convertFromMaybeYesNo(formValues.isPregnant);
  }

  return { partialDto };
};

export const useGlobalInsuredForm = ({
  employeeId,
}: Props): UseInsuredFormReturnType => {
  const { startLoading, stopLoading } = useLoadingOverlay();
  const { isAgent, isZorroOperations, isEmployerAdmin } = useRoles();
  const { isImpersonated } = useImpersonation();
  const [possibleFipsCodes, setPossibleFipsCodes] = useState<string[]>([]);
  const isFinalizationMode = !isZorroOperations;
  const { mutate: mutateEmployee } = useMonolithMutation(
    isZorroOperations || isAgent || isEmployerAdmin || isImpersonated
      ? {
          method: 'employeesControllerUpdateByAdmin',
        }
      : {
          method: 'employeesControllerUpdateSelf',
        }
  );

  const { mutate: mutateDependents } = useMonolithMutation({
    method: 'employeesControllerUpsertDependents',
  });

  const [isSpouseActive, setIsSpouseActive] = useState<boolean>(false);

  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  const { data: { dependents } = { dependents: undefined } } = useMonolithQuery(
    {
      method: 'employeesControllerGetDependents',
      params: [employeeId],
    }
  );

  const employeeForm = useForm<InsuredEmployeeFormFields>({
    mode: 'all',
    resolver: yupResolver(getEmployeeFormSchema(isFinalizationMode, false)),
    defaultValues: {
      ...mapInsuredDtoToBaseInsuredFormFields(employee),
      email: employee?.email || '',
      phone: employee?.phone || '',
      personalEmail: employee?.personalEmail || '',
      residentialAddress: employee?.address || '',
      isMailingAddressSameAsResidentialAddress:
        employee?.address && employee?.mailingAddress
          ? employee.address === employee.mailingAddress
          : true,
      mailingAddress: employee?.mailingAddress || '',
      employmentType: employee?.employmentType || null,
      wageType: employee?.wageType || null,
      class: employee?.class || '',
      fipsCode: employee?.fipsCode ?? '',
      citizenshipStatus: employee?.citizenshipStatus || null,
    } satisfies InsuredEmployeeFormFields,
  });

  const spouse = dependents?.find(
    (dependent) =>
      dependent.type === DependentTypeEnum.SPOUSE_OR_DOMESTIC_PARTNER
  );
  const otherDependents = dependents?.filter(
    (dependent) => dependent.type === DependentTypeEnum.CHILD_OR_OTHER_DEPENDENT
  );

  const spouseForm = useForm<InsuredSpouseFormFields>({
    mode: 'all',
    resolver: yupResolver(getSpouseInsuredSchema(isFinalizationMode)),
    defaultValues: spouse
      ? {
          ...mapDependentDtoToBaseInsuredFormFields(spouse),
          subtype: convertDependentSubtypeDtoToForm(spouse.subType),
        }
      : getDefaultInsuredFormFields(employee?.address),
  });

  const dependentsForm = useForm<InsuredDependentsFormFields>({
    mode: 'all',
    defaultValues: {
      dependents:
        otherDependents && otherDependents.length > 0
          ? otherDependents.map((dependent) => ({
              ...mapDependentDtoToBaseInsuredFormFields(dependent),
              subtype: convertDependentSubtypeDtoToForm(dependent.subType),
            }))
          : [],
    },
    resolver: yupResolver(getDependentInsuredSchema(isFinalizationMode, false)),
  });
  const hasAnyDependents = dependentsForm.watch('dependents').length > 0;
  const employeeAddress = employeeForm.watch('residentialAddress');

  useEffect(() => {
    if (!employeeAddress) {
      return;
    }

    const hasEmployeeAddressChanged = employeeAddress !== employee?.address;
    if (
      !spouse?.residentialAddress ||
      (hasEmployeeAddressChanged &&
        spouse?.residentialAddress === employee?.address)
    ) {
      spouseForm.setValue('residentialAddress', employeeAddress);
    }

    otherDependents?.forEach((dependent, index) => {
      if (
        !dependent.residentialAddress ||
        (hasEmployeeAddressChanged &&
          dependent.residentialAddress === employee?.address)
      ) {
        dependentsForm.setValue(
          `dependents.${index}.residentialAddress`,
          employeeAddress
        );
      }
    });
  }, [
    employeeAddress,
    spouseForm,
    dependentsForm,
    employee?.address,
    otherDependents,
    spouse?.residentialAddress,
  ]);

  useEffect(() => {
    setIsSpouseActive(Boolean(spouse));
  }, [spouse, setIsSpouseActive]);

  return {
    employeeForm,
    spouseForm,
    dependentsForm,
    hasAnyDependents,
    isSpouseActive,
    setIsSpouseActive,
    possibleFipsCodes,
    setPossibleFipsCodes,
    onSubmit: async (): Promise<boolean> => {
      startLoading();
      const validationResults = await Promise.all([
        employeeForm.trigger(),
        isSpouseActive ? spouseForm.trigger() : true,
        hasAnyDependents ? dependentsForm.trigger() : true,
      ]);

      if (validationResults.some((result) => !result)) {
        stopLoading();
        return false;
      }

      const formValues = employeeForm.getValues();
      const { isMailingAddressSameAsResidentialAddress, fipsCode } = formValues;
      const spouse = spouseForm.getValues();
      const { dependents } = dependentsForm.getValues();
      const { setError: setEmployeeFormError } = employeeForm;
      const { isValidAddress, isPoBox, possibleFipsCodes } =
        await validateAddress(formValues.residentialAddress);
      if (isPoBox) {
        setEmployeeFormError('residentialAddress', {
          message: ERROR_MESSAGES.ADDRESS_IS_PO_BOX_ERROR_MESSAGE,
        });
      } else if (!isValidAddress) {
        setEmployeeFormError('residentialAddress', {
          message: VALIDATION_MESSAGES.residentialAddressInvalid,
        });
        stopLoading();
        return false;
      } else if (
        possibleFipsCodes &&
        possibleFipsCodes.length > 1 &&
        !fipsCode
      ) {
        setPossibleFipsCodes(possibleFipsCodes);
        setEmployeeFormError('fipsCode', {
          message: VALIDATION_MESSAGES.countyDisambiguity,
        });
        stopLoading();
        return false;
      }

      let result = false;
      try {
        const { partialDto } = getChangedEmployeeFields(
          formValues,
          employee,
          isMailingAddressSameAsResidentialAddress
        );

        if (Object.keys(partialDto).length > 0) {
          await mutateEmployee([employeeId, partialDto]);
        }

        const insuredSpouse = isSpouseActive
          ? [
              mapFormFieldsToDependentDto(
                spouse,
                DependentTypeEnum.SPOUSE_OR_DOMESTIC_PARTNER
              ),
            ]
          : [];
        const insuredDependents = hasAnyDependents
          ? dependents.map((dependent) =>
              mapFormFieldsToDependentDto(
                dependent,
                DependentTypeEnum.CHILD_OR_OTHER_DEPENDENT
              )
            )
          : [];

        const insuredDtos = [...insuredSpouse, ...insuredDependents];

        if (insuredDtos && insuredDtos.length > 0) {
          await mutateDependents([
            employeeId,
            {
              dependents: insuredDtos,
            },
          ]);
        }

        result = true;
      } catch (error) {
        logger.error(error);
      } finally {
        stopLoading();
      }
      return result;
    },
  };
};
