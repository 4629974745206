import { Table, TableProps } from '@mantine/core';

import classNames from './SimpleTable.module.scss';

export type ISimpleTableProps = TableProps;

export function ZorroSimpleTable({
  withTableBorder = true,
  horizontalSpacing = 'md',
  verticalSpacing = 'sm',
  withColumnBorders = true,
  ...props
}: ISimpleTableProps) {
  return (
    <Table
      classNames={classNames}
      withTableBorder={withTableBorder}
      horizontalSpacing={horizontalSpacing}
      verticalSpacing={verticalSpacing}
      withColumnBorders={withColumnBorders}
      {...props}
    />
  );
}

ZorroSimpleTable.Thead = Table.Thead;
ZorroSimpleTable.Tbody = Table.Tbody;
ZorroSimpleTable.Tr = Table.Tr;
ZorroSimpleTable.Th = Table.Th;
ZorroSimpleTable.Td = Table.Td;
