/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EmploymentStatus {
  ELIGIBLE_EMPLOYED = 'Eligible - employed',
  ELIGIBLE_UPCOMING_LEAVE = 'Eligible - upcoming leave',
  INELIGIBLE_WAITING = 'Ineligible - waiting',
  INELIGIBLE_ON_LEAVE = 'Ineligible - on leave',
  ELIGIBLE_UPCOMING_TERMINATION = 'Eligible - upcoming termination',
  INELIGIBLE_TERMINATED = 'Ineligible - terminated',
  INELIGIBLE_TERMINATED_BEFORE_ACTIVATION = 'Ineligible - terminated before activation',
}
