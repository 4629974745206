import { getDateUtil, parseDateISO } from '@zorro/shared/formatters';
import { useMonolithQuery } from '@zorro/shared/utils';
import {
  DateRangeInput,
  IDateRangeInputProps,
  IMonthPickerInputProps,
  MonthPickerInput,
} from '@zorro/zorro-ui-design';
import { ForwardedRef, forwardRef } from 'react';
import { ControllerRenderProps, Path } from 'react-hook-form';

const dateUtil = getDateUtil();

type CitizenshipStatusFieldValues = {
  effectiveDates: [Date | null, Date | null];
};
type Props<
  T extends CitizenshipStatusFieldValues,
  U extends Path<T>,
> = ControllerRenderProps<T, U> &
  Omit<
    IDateRangeInputProps &
      IMonthPickerInputProps<'range'> & {
        employeeId: string;
        onboardingPeriodId: string;
      },
    'type'
  >;

export const CoveragePeriodInput = forwardRef(
  <T extends CitizenshipStatusFieldValues, U extends Path<T>>(
    { employeeId, onboardingPeriodId, ...field }: Props<T, U>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const { data: employee } = useMonolithQuery({
      method: 'employeesControllerFindOne',
      params: [employeeId],
    });
    const { data: onboardingPeriod } = useMonolithQuery({
      method: 'onboardingPeriodsControllerFindOne',
      params: [employeeId, onboardingPeriodId],
    });

    if (!employee || !onboardingPeriod) return null;

    const [enrollmentEffectiveFrom, enrollmentEffectiveUntil] = [
      parseDateISO(onboardingPeriod.enrollmentEffectiveFrom),
      parseDateISO(onboardingPeriod.enrollmentEffectiveUntil),
    ];

    const minDate = employee.eligibleFrom
      ? dateUtil.max(
          enrollmentEffectiveFrom,
          parseDateISO(employee.eligibleFrom)
        )
      : enrollmentEffectiveFrom;

    const maxDate = employee.eligibleUntil
      ? dateUtil.min(
          enrollmentEffectiveUntil,
          parseDateISO(employee.eligibleUntil)
        )
      : enrollmentEffectiveUntil;

    const DateComponent = onboardingPeriod.isSpecialEnrollment
      ? DateRangeInput
      : MonthPickerInput;

    return (
      <DateComponent
        {...field}
        ref={ref}
        placeholder="Select coverage period dates"
        minDate={minDate.toDate()}
        maxDate={maxDate.toDate()}
        type="range"
        size="md"
        allowSingleDateInRange
      />
    );
  }
);

CoveragePeriodInput.displayName = 'CoveragePeriodInput';
