import { EmployerDto } from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';
import {
  FormErrorMessage,
  ISelectProps,
  LabelWithTooltip,
  Select,
} from '@zorro/zorro-ui-design';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

type EmployeeClassInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  employerId: EmployerDto['id'];
  selectProps?: ISelectProps;
};

export function EmployeeClassInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  employerId,
  selectProps,
  ...formField
}: EmployeeClassInputProps<TFieldValues, TName>) {
  const {
    field,
    formState: { errors },
  } = useController(formField);

  const { data: employeeClasses } = useMonolithQuery({
    method: 'allowanceModelControllerGetEmployeeClasses',
    params: [employerId],
  });

  const classes = employeeClasses?.classes || [];

  // TODO: figure out the best way to handle errors
  // @see https://myzorroworkspace.slack.com/archives/C03EZ1SDJBG/p1712564797793239
  return (
    <>
      <Select
        {...field}
        placeholder="Class"
        data={classes}
        label={
          <LabelWithTooltip
            label="Class"
            tooltipLabel="If you don’t see the right class for the employee, it’s possible an additional class needs to be added. Please contact Zorro Support for assistance."
            isRequired
          />
        }
        {...selectProps}
      />

      <FormErrorMessage fieldName={formField.name} errors={errors} />
    </>
  );
}
