/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DependentSubtypeEnum {
  SPOUSE = 'SPOUSE',
  DOMESTIC_PARTNER = 'DOMESTIC_PARTNER',
  CHILD = 'CHILD',
  OTHER_DEPENDENT = 'OTHER_DEPENDENT',
}
