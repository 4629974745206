import { NextRouter, useRouter as useNextRouter } from 'next/router';
import { useEffect, useState } from 'react';

import {
  DON_DYNAMIC_ROUTES,
  MURRIETA_DYNAMIC_ROUTES,
  ZORRO_UI_DYNAMIC_ROUTES,
} from '../routes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteConfig = Record<string, (...params: any[]) => string>;

type UseDynamicRouter = <R extends RouteConfig>(
  routeConfig: R
) => {
  router: NextRouter;
  isNavigating: boolean;
  isNavigatingToRoute: <K extends keyof R>(route: K) => boolean;
  reloadPage: (options?: { scroll: boolean }) => void;
  navigateToRoute: <K extends keyof R>(
    route: K,
    params: Parameters<R[K]>,
    queryParams?: Record<string, string>,
    shouldReplace?: boolean
  ) => void;
};

export const useDynamicRouter: UseDynamicRouter = <R extends RouteConfig>(
  routeConfig: R
) => {
  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({});
  const router = useNextRouter();

  const handleSetLoadingState = <K extends keyof R>(route: K) => {
    setLoadingState((prev) => ({ ...prev, [route]: true }));
  };

  const handleGetLoadingState = <K extends keyof R>(route: K): boolean => {
    return loadingState[route] ?? false;
  };

  const navigateToRoute = <K extends keyof R>(
    route: K,
    params: Parameters<R[K]>,
    queryParams: Record<string, string> = {},
    shouldReplace = false
  ) => {
    const url = new URL(window.location.href);
    const currentQueryParams = Object.fromEntries(url.searchParams);
    const routerAction = shouldReplace ? router.replace : router.push;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    routerAction({
      pathname: routeConfig[route](...params),
      query: { ...currentQueryParams, ...queryParams },
    });
  };

  const reloadPage = (options?: { scroll: boolean }) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.replace(router.asPath, undefined, { ...options });
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleSetLoadingState);
    return () => router.events.off('routeChangeStart', handleSetLoadingState);
  }, []);

  return {
    router,
    navigateToRoute,
    isNavigating: Object.keys(loadingState).length > 0,
    isNavigatingToRoute: handleGetLoadingState,
    reloadPage,
  };
};

export const useZorroUIRouter = () => {
  return useDynamicRouter(ZORRO_UI_DYNAMIC_ROUTES);
};
export const useDynamicMurrietaRouter = () => {
  return useDynamicRouter(MURRIETA_DYNAMIC_ROUTES);
};
export const useDynamicDonRouter = () => {
  return useDynamicRouter(DON_DYNAMIC_ROUTES);
};
