import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ConnectedIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M2.5 17.5L4.58333 15.4167M15.4167 4.58333L17.5 2.5M8.33333 9.16667L6.66667 10.8333M10.8333 11.6667L9.16667 13.3333M5.83328 10L9.99995 14.1667L8.74995 15.4167C8.478 15.6976 8.15287 15.9216 7.79349 16.0756C7.43411 16.2296 7.04768 16.3106 6.6567 16.3137C6.26573 16.3169 5.87803 16.2422 5.5162 16.0941C5.15437 15.9459 4.82565 15.7272 4.54918 15.4508C4.27271 15.1743 4.05402 14.8456 3.90586 14.4837C3.75771 14.1219 3.68304 13.7342 3.68621 13.3432C3.68939 12.9523 3.77034 12.5658 3.92435 12.2065C4.07836 11.8471 4.30236 11.5219 4.58328 11.25L5.83328 10ZM14.1667 9.99995L10 5.83328L11.25 4.58328C11.5219 4.30236 11.8471 4.07836 12.2065 3.92435C12.5658 3.77034 12.9523 3.68939 13.3432 3.68621C13.7342 3.68304 14.1219 3.75771 14.4837 3.90586C14.8456 4.05402 15.1743 4.27271 15.4508 4.54918C15.7272 4.82565 15.9459 5.15437 16.0941 5.5162C16.2422 5.87803 16.3169 6.26573 16.3137 6.6567C16.3106 7.04768 16.2296 7.43411 16.0756 7.79349C15.9216 8.15287 15.6976 8.478 15.4167 8.74995L14.1667 9.99995Z"
          stroke="#676979"
          strokeWidth="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Center>
  );
};
