import { Gender } from '@zorro/clients';
import { fipsDatabase } from '@zorro/shared/databases';
import { formatDateEnLocale } from '@zorro/shared/formatters';
import { getPhoneVariations, useMonolithQuery } from '@zorro/shared/utils';
import {
  YesNo,
  citizenshipStatusLabelConfig,
  employmentTypeLabelConfig,
  wageTypeLabelConfig,
} from '@zorro/types';
import { UseFormReturn } from 'react-hook-form';

import { InsuredEmployeeFormFields } from '../EmployeeFormInputs';
import { ReadonlyValuesGrid } from './ReadonlyValuesGrid';

type Props = {
  employeeId: string;
  employeeForm: UseFormReturn<InsuredEmployeeFormFields>;
  onboardingPeriodId?: string;
};

export const ReadonlyEmployeeForm = ({
  employeeId,
  employeeForm,
  onboardingPeriodId,
}: Props) => {
  const {
    firstName,
    lastName,
    dateOfBirth,
    phone,
    gender,
    isSmoker,
    isPregnant,
    email,
    personalEmail,
    residentialAddress,
    mailingAddress,
    ssn,
    citizenshipStatus,
    class: employeeClass,
    employmentType,
    wageType,
    fipsCode: employeeGlobalFipsCode,
  } = employeeForm.watch();

  const { data: insured } = useMonolithQuery({
    method: 'insuredControllerFindInsuredForPeriod',
    params: onboardingPeriodId && [onboardingPeriodId],
  });

  const fipsCode = insured ? insured.employee.fipsCode : employeeGlobalFipsCode;
  const county = fipsDatabase.find(
    (fipsRecord) => fipsRecord.fipsCode === fipsCode
  )?.county;

  const { valueForView, valueForCopy } = getPhoneVariations(phone);

  return (
    <ReadonlyValuesGrid
      items={[
        { id: 'employee-info', title: 'Employee info' },
        {
          id: 'firstName',
          label: 'First name',
          value: firstName,
          masked: true,
        },
        {
          id: 'lastName',
          label: 'Last name',
          value: lastName,
          masked: true,
        },
        {
          id: 'dateOfBirth',
          label: 'Date of birth',
          value: formatDateEnLocale(dateOfBirth),
          masked: true,
        },
        {
          id: 'phone',
          label: 'Phone',
          value: valueForView,
          copyValue: valueForCopy,
        },
        {
          id: 'gender',
          label: 'Gender',
          value: gender === Gender.MALE ? 'Male' : 'Female',
        },
        {
          id: 'smoker',
          label: 'Smoker',
          value: isSmoker === YesNo.YES ? 'Yes' : 'No',
        },
        {
          id: 'pregnant',
          label: 'Pregnant',
          value: isPregnant === YesNo.YES ? 'Yes' : 'No',
          span: 12,
        },
        {
          id: 'email',
          label: 'Company email',
          value: email,
        },
        {
          id: 'personal-email',
          label: 'Personal email',
          value: personalEmail,
        },
        {
          id: 'residential-address',
          label: 'Residential address',
          value: residentialAddress,
          span: 12,
          masked: true,
        },
        {
          id: 'county',
          label: 'County of residence',
          value: county,
          span: 12,
        },
        {
          id: 'mailing-address',
          label: 'Mailing address',
          value: mailingAddress,
          span: 12,
          masked: true,
        },
        {
          id: 'ssn',
          label: 'SSN',
          value: ssn,
          isSensitive: true,
          masked: true,
        },
        {
          id: 'citizenship-status',
          label: 'Citizenship status',
          value:
            citizenshipStatus &&
            citizenshipStatusLabelConfig[citizenshipStatus],
        },
        { id: 'employment-info', title: 'Employment info' },
        {
          id: 'class',
          label: 'Class',
          value: employeeClass,
        },
        {
          id: 'employment-type',
          label: 'Employment type',
          value: employmentType && employmentTypeLabelConfig[employmentType],
        },
        {
          id: 'wage-type',
          label: 'Wage type',
          value: wageType && wageTypeLabelConfig[wageType],
        },
      ]}
    />
  );
};
