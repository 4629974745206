import { EmployerDto } from '@zorro/clients';
import {
  callEndpoint,
  showErrorNotification,
  showSuccessNotification,
  useMonolithQuery,
} from '@zorro/shared/utils';
import {
  RichTextEditor,
  Stack,
  Text,
  useRichTextEditor,
} from '@zorro/zorro-ui-design';
import _debounce from 'lodash/debounce';

import { getEnrollmentInstructionsContent } from './enrollmentInstructions.utils';

const mutateEnrollmentInstructions = _debounce(
  async (employerId: EmployerDto['id'], content: string) => {
    try {
      await callEndpoint({
        method: 'enrollmentInstructionsControllerUpsert',
        params: [employerId, { content }],
      });
      showSuccessNotification({
        message: 'Enrollment instructions have been saved.',
      });
    } catch (error) {
      showErrorNotification(error);
    }
  },
  2000,
  { maxWait: 15_000 }
);

type EnrollmentInstructionsEditorProps = {
  employerId: EmployerDto['id'];
};

const EnrollmentInstructionsEditor = ({
  employerId,
}: EnrollmentInstructionsEditorProps) => {
  const {
    isLoading: isLoadingEnrollmentInstructions,
    data: enrollmentInstructions,
  } = useMonolithQuery({
    method: 'enrollmentInstructionsControllerFind',
    params: [employerId],
  });

  const content = getEnrollmentInstructionsContent(
    enrollmentInstructions?.content
  );

  const { editor } = useRichTextEditor(
    {
      content,
      onUpdate({ editor }) {
        const content = editor.isEmpty ? {} : editor.getJSON();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        mutateEnrollmentInstructions(employerId, JSON.stringify(content));
      },
    },
    [enrollmentInstructions]
  );

  if (isLoadingEnrollmentInstructions) {
    return null;
  }

  return (
    <Stack gap="xs">
      <Text fw={400} size="sm">
        Enrollment instructions
      </Text>
      <RichTextEditor editor={editor} />
    </Stack>
  );
};

export { EnrollmentInstructionsEditor };
