import {
  EmployeeReportRowDto,
  ExtendedBenefitStatus,
  OpenEnrollmentPeriodDto,
  SelfReportType,
} from '@zorro/clients';
import {
  formatToFixedFloat,
  getNowAsDate,
  getToday,
  parseDateEnLocale,
  parseDateISOThenFormatEnLocale,
  parseDateTimeISOThenFormatEnLocale,
} from '@zorro/shared/formatters';
import {
  calculateAge,
  convertToYesNo,
  exportToCsv,
  rolesToDisplayName,
} from '@zorro/shared/utils';
import {
  EmployeesReportView,
  Roles,
  qualifyingLifeEventLabelConfig,
  selfReportTypeLabelConfig,
  submissionTypeToStringMap,
} from '@zorro/types';
import { DataTableSortStatus } from 'mantine-datatable';

import { enrollmentStatusConfig } from '../EnrollmentStatus';
import { Filter, FilterRecord } from '../Filters';
import { EmployeesViewToDataMap } from './employeesDatatable.types';

type CustomTableColumns = {
  fullName: string;
  age: number;
  onboardingStatus: string | undefined;
  enrollmentStatusLabel: string | undefined;
  enrollmentSubmissionType: string;
  enrollmentIsCombinedPlan: string | undefined;
  enrollmentSelfReportType: string;
  role: string;
  medicalPremium: number | null;
  medicalEmployeeContribution: number | null;
  medicalEmployerContribution: number | null;
  isHsaEligible: string;
  qleType: string;
};

export type EmployeeRow = Omit<EmployeeReportRowDto, keyof CustomTableColumns> &
  CustomTableColumns;

// TODO: Remove ColumnId, replace with generic `keyof EmployeeRow` type.
export enum ColumnId {
  FULL_NAME = 'fullName',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  INVITATION_STATUS = 'invitationStatus',
  AGE = 'age',
  STATE = 'state',
  CLASS = 'class',
  BENEFIT_ELIGIBILITY = 'benefitEligibility',
  ROLE = 'role',
  ENROLLMENT_STATUS = 'enrollmentStatus',
  ENROLLMENT_TYPE = 'enrollmentType',
  ELECTION_START = 'electionStart',
  ELECTION_END = 'electionEnd',
  COVERAGE_START = 'coverageStart',
  COVERAGE_END = 'coverageEnd',
  QLE = 'qle',
  QLE_TYPE = 'qleType',
  ENROLLMENT_SUBMISSION_TYPE = 'enrollmentSubmissionType',
  ENROLLMENT_SUBMISSION_DATE = 'enrollmentSubmissionDate',
  ENROLLMENT_COMBINED_PLAN = 'enrollmentIsCombinedPlan',
  EMPLOYEE_DETAILS = 'employeeDetails',
  MEDICAL_CARRIER = 'medicalCarrier',
  MEDICAL_PLAN_NAME = 'medicalPlanName',
  MEDICAL_PLAN_ID = 'medicalPlanId',
  MEDICAL_PLAN_MARKET = 'medicalPlanMarket',
  MEDICAL_FAMILY_UNIT = 'medicalFamilyUnit',
  DEPENDENT_COUNT = 'dependentCount',
  MEDICAL_EMPLOYER_CONTRIBUTION = 'medicalEmployerContribution',
  MEDICAL_EMPLOYEE_CONTRIBUTION = 'medicalEmployeeContribution',
  MEDICAL_PREMIUM = 'medicalPremium',
  ALLOWANCE = 'allowance',
  ZORRO_ID = 'id',
  ID_FROM_EMPLOYER = 'idFromEmployer',
  IS_HSA_ELIGIBLE = 'isHsaEligible',
  PAYMENT_METHOD = 'paymentMethod',
  INITIAL_PREMIUM_PAYMENT_STATUS = 'initialPremiumPaymentStatus',
  AUTO_PAY_STATUS = 'autoPayStatus',
}

export function stringComparator(s1?: string, s2?: string) {
  if (s1 && s2) {
    return s1.localeCompare(s2);
  } else if (!s1 && s2) {
    return 1;
  }
  return -1;
}

export function dateStringComparator(d1?: string | null, d2?: string | null) {
  if (d1 && d2) {
    return parseDateEnLocale(d1).isBefore(parseDateEnLocale(d2)) ? -1 : 1;
  } else if (!d1 && d2) {
    return 1;
  }
  return -1;
}

const filterCallback = <T extends FilterRecord>(
  recordValues: T,
  newValue: string[],
  filterBy: keyof T
) => {
  let shouldFilter = false;
  newValue.forEach((val) => {
    const recordValue = recordValues[filterBy];
    const isArray = Array.isArray(recordValue);
    if (
      (isArray && recordValue.includes(val)) ||
      String(recordValues[filterBy]) === val ||
      (val === '' && !recordValues[filterBy])
    ) {
      shouldFilter = true;
    }
  });
  return shouldFilter;
};

export function getFilteredSortedSearchedRecords(
  viewedEmployeeRows: EmployeeReportRowDto[],
  filters: Filter<EmployeeRow>[],
  sortStatus: DataTableSortStatus,
  searchValue: string
): EmployeeRow[] {
  const formatted = employeesDataToFormattedRows(viewedEmployeeRows);
  const filtered = formatted.filter((employee) => {
    return filters.every((filter) => {
      return filter.filterCallback
        ? filter.filterCallback(employee)
        : filterCallback<EmployeeRow>(
            employee,
            filter.value as string[], // TODO: Fix type castion
            filter.type
          );
    });
  });

  const sorted = filtered.sort((first: EmployeeRow, second: EmployeeRow) => {
    const firstStr = String(
      first[sortStatus.columnAccessor as keyof EmployeeRow] || ''
    );
    const secondStr = String(
      second[sortStatus.columnAccessor as keyof EmployeeRow] || ''
    );
    return sortStatus.direction === 'asc'
      ? stringComparator(firstStr, secondStr)
      : stringComparator(secondStr, firstStr);
  });

  if (!searchValue) {
    return sorted;
  }

  const lowerCaseValue = searchValue.toLowerCase();

  // apply search filter
  return sorted.filter((employee) => {
    return Object.entries(employee).some(([_field, value]) => {
      return String(value).toLowerCase().includes(lowerCaseValue);
    });
  });
}

export function getAvailableViews(
  viewToFilteredDataMap: EmployeesViewToDataMap,
  selectedEnrollmentPeriod: OpenEnrollmentPeriodDto | null
) {
  const isOpenEnrollmentMode = getToday().isBefore(
    selectedEnrollmentPeriod?.effectiveFrom
  );

  return isOpenEnrollmentMode
    ? [
        {
          label: 'All',
          value: EmployeesReportView.ALL,
          count:
            viewToFilteredDataMap.get(EmployeesReportView.ALL)?.data.length ||
            0,
        },
        {
          label: 'Open enrollment',
          value: EmployeesReportView.OPEN_ENROLLMENT,
          count:
            viewToFilteredDataMap.get(EmployeesReportView.OPEN_ENROLLMENT)?.data
              .length || 0,
        },
      ]
    : [
        {
          label: 'All',
          value: EmployeesReportView.ALL,
          count:
            viewToFilteredDataMap.get(EmployeesReportView.ALL)?.data.length ||
            0,
        },
        {
          label: 'Latest coverage',
          value: EmployeesReportView.LATEST_COVERAGE,
          count:
            viewToFilteredDataMap.get(EmployeesReportView.LATEST_COVERAGE)?.data
              .length || 0,
        },
        {
          label: 'Enrollments in process',
          value: EmployeesReportView.ENROLLMENTS_IN_PROCESS,
          count:
            viewToFilteredDataMap.get(
              EmployeesReportView.ENROLLMENTS_IN_PROCESS
            )?.data.length || 0,
        },
      ];
}

export const downloadEmployees = (
  records: EmployeeRow[],
  employerName: string,
  hiddenColumnIds?: string[]
) => {
  const timestamp = getNowAsDate().getTime();
  const fileName = `${employerName}_employees_enrollment_data_${timestamp}.csv`;

  const recordsToExport = records.map((record) => ({
    ...record,
    qle: record.qle
      ? `${qualifyingLifeEventLabelConfig[record.qle.type]}, ${parseDateISOThenFormatEnLocale(record.qle.occurredOn)}`
      : null,
    electionStart: record.electionStart
      ? parseDateISOThenFormatEnLocale(record.electionStart)
      : null,
    electionEnd: record.electionEnd
      ? parseDateISOThenFormatEnLocale(record.electionEnd)
      : null,
    coverageStart: record.coverageStart
      ? parseDateISOThenFormatEnLocale(record.coverageStart)
      : null,
    coverageEnd: record.coverageEnd
      ? parseDateISOThenFormatEnLocale(record.coverageEnd)
      : null,
    enrollmentSubmissionDate: record.enrollmentSubmissionDate
      ? parseDateTimeISOThenFormatEnLocale(record.enrollmentSubmissionDate)
      : null,
  }));

  const fields = [
    { value: ColumnId.FIRST_NAME, label: 'First Name' },
    { value: ColumnId.LAST_NAME, label: 'Last Name' },
    { value: ColumnId.EMAIL, label: 'Email' },
    { value: ColumnId.INVITATION_STATUS, label: 'Invitation Status' },
    { value: ColumnId.AGE, label: 'Age' },
    { value: ColumnId.STATE, label: 'State' },
    { value: ColumnId.CLASS, label: 'Class' },
    { value: ColumnId.BENEFIT_ELIGIBILITY, label: 'Benefit Eligibility' },
    { value: ColumnId.ROLE, label: 'Role' },
    { value: ColumnId.ENROLLMENT_STATUS, label: 'Status' },
    { value: ColumnId.ELECTION_START, label: 'Election start' },
    { value: ColumnId.ELECTION_END, label: 'Election end' },
    { value: ColumnId.COVERAGE_START, label: 'Coverage start' },
    { value: ColumnId.COVERAGE_END, label: 'Coverage end' },
    { value: ColumnId.ENROLLMENT_TYPE, label: 'Type' },
    { value: ColumnId.QLE, label: 'QLE' },
    { value: ColumnId.ENROLLMENT_SUBMISSION_TYPE, label: 'Submission type' },
    { value: ColumnId.ENROLLMENT_SUBMISSION_DATE, label: 'Submission date' },
    { value: ColumnId.ENROLLMENT_COMBINED_PLAN, label: 'Combined plan' },
    { value: ColumnId.PAYMENT_METHOD, label: 'Payment method' },
    {
      value: ColumnId.INITIAL_PREMIUM_PAYMENT_STATUS,
      label: 'Initial payment status',
    },
    { value: ColumnId.AUTO_PAY_STATUS, label: 'Auto-pay status' },
    { value: ColumnId.MEDICAL_CARRIER, label: 'Carrier' },
    { value: ColumnId.MEDICAL_PLAN_NAME, label: 'Plan name' },
    { value: ColumnId.MEDICAL_PLAN_ID, label: 'Plan ID' },
    { value: ColumnId.MEDICAL_PLAN_MARKET, label: 'Plan market' },
    { value: ColumnId.MEDICAL_FAMILY_UNIT, label: 'Family unit' },
    { value: ColumnId.DEPENDENT_COUNT, label: 'Children' },
    { value: ColumnId.IS_HSA_ELIGIBLE, label: 'HSA eligible' },
    { value: ColumnId.MEDICAL_PREMIUM, label: 'Premium' },
    {
      value: ColumnId.MEDICAL_EMPLOYER_CONTRIBUTION,
      label: 'Employer contribution',
    },
    {
      value: ColumnId.MEDICAL_EMPLOYEE_CONTRIBUTION,
      label: 'Employee contribution',
    },
    { value: ColumnId.ALLOWANCE, label: 'Allowance' },
    { value: ColumnId.ZORRO_ID, label: 'Zorro ID' },
    { value: ColumnId.ID_FROM_EMPLOYER, label: 'Employee ID' },
  ];
  exportToCsv(
    recordsToExport,
    fileName,
    fields.filter((field) => !hiddenColumnIds?.includes(field.value))
  );
};

export const employeesDataToFormattedRows = (
  employees: EmployeeReportRowDto[]
): EmployeeRow[] =>
  employees.map((employee) => {
    return {
      ...employee,
      fullName: `${employee.firstName} ${employee.lastName}`,
      age: calculateAge(employee.dateOfBirth),
      onboardingStatus:
        employee.isOnboardingActive === undefined
          ? undefined
          : employee.isOnboardingActive
            ? 'Active'
            : 'Inactive',
      enrollmentStatusLabel: employee.enrollmentStatus
        ? enrollmentStatusConfig[employee.enrollmentStatus].label
        : undefined,
      enrollmentSubmissionType: employee.enrollmentSubmissionType
        ? submissionTypeToStringMap[employee.enrollmentSubmissionType]
        : '',
      enrollmentIsCombinedPlan:
        employee.medicalStatus &&
        employee.medicalStatus !== ExtendedBenefitStatus.WAIVED
          ? convertToYesNo(employee.enrollmentIsCombinedPlan || false)
          : undefined,
      enrollmentSelfReportType:
        employee.enrollmentSelfReportType &&
        employee.enrollmentSelfReportType !== SelfReportType.NOT_APPLICABLE
          ? selfReportTypeLabelConfig[employee.enrollmentSelfReportType]
          : '-',
      role: rolesToDisplayName(employee.roles as Roles[]),
      medicalPremium: formatToFixedFloat(employee.medicalPremium),
      medicalEmployeeContribution: formatToFixedFloat(
        employee.medicalEmployeeContribution
      ),
      medicalEmployerContribution: formatToFixedFloat(
        employee.medicalEmployerContribution
      ),
      isHsaEligible:
        employee.isHsaEligible === undefined || employee.isHsaEligible === null
          ? ''
          : employee.isHsaEligible
            ? 'Yes'
            : 'No',
      qleType: employee.qle?.type || '',
    };
  });
