import { YesNo } from '@zorro/types';

export const convertFromYesNo = (value?: YesNo) => {
  return value === YesNo.YES;
};

export const convertFromMaybeYesNo = (
  value: YesNo | null | undefined
): boolean | null | undefined => {
  if (value === null || value === undefined) {
    return value;
  }
  return convertFromYesNo(value);
};
