import Link, { LinkProps } from 'next/link';
import { ReactNode } from 'react';

import { IAnchorProps, ZorroAnchor } from '../Anchor';

export interface ILinkProps extends LinkProps {
  children: ReactNode;
  anchorProps?: Omit<IAnchorProps, 'href' | 'download' | 'target'>;

  download?: IAnchorProps['download'];
  target?: IAnchorProps['target'];
  // eslint-disable-next-line react/boolean-prop-naming
  masked?: boolean;
}

export const ZorroLink = ({
  href,
  prefetch,
  children,
  anchorProps,
  download = false,
  target,
  masked,
  ...props
}: ILinkProps) => {
  if (download) {
    return (
      <ZorroAnchor
        {...anchorProps}
        href={String(href)}
        download
        target={target}
        masked={masked}
      >
        {children}
      </ZorroAnchor>
    );
  }

  const isSamePageAction = String(href).startsWith('#');

  return (
    <Link
      {...props}
      href={href}
      prefetch={isSamePageAction ? false : prefetch}
      passHref
      legacyBehavior
    >
      <ZorroAnchor {...anchorProps} target={target} masked={masked}>
        {children}
      </ZorroAnchor>
    </Link>
  );
};
