/* eslint-disable unicorn/custom-error-definition */
import { HttpStatus } from '@nestjs/common';
import {
  BenefitType,
  DoctorDto,
  EmployeeDto,
  FamilyUnit,
  InsuredPeopleDto,
  OnboardingPeriodReportDto,
} from '@zorro/clients';

export class ZorroError extends Error {
  override message: string;
  status: number;
  constructor(message?: string, status?: number) {
    super(message);
    this.name = this.constructor.name;
    this.message = message ?? this.name;
    this.status = status ?? HttpStatus.BAD_REQUEST;
  }
}

export class InvalidStateError extends ZorroError {}
export class InvalidOnboardingPeriodSubmission extends ZorroError {
  readonly description;
  constructor(message?: string, description?: string) {
    super(message);
    this.description = description ?? message;
  }
}

export enum OtherCoveragesMetlife {
  PET = 'PET',
}

export enum ValidationIndicatorState {
  VALIDATING = 'validating',
  VALID = 'valid',
  INVALID = 'invalid',
}

export enum OtherCoveragesAflac {
  FRAUD_PROTECTION = 'FRAUD_PROTECTION',
  FINANCIAL_AND_LEGAL_HEALTH = 'FINANCIAL_AND_LEGAL_HEALTH',
  ASSISTANCE_PROGRAM_FOR_MENTAL_HEALTH = 'ASSISTANCE_PROGRAM_FOR_MENTAL_HEALTH',
  OTHER = 'OTHER',
}

export type DoctorDtoEnhanced = DoctorDto & { name?: string };

export enum EmploymentStatus {
  ELIGIBLE_EMPLOYED = 'Eligible - employed',
  ELIGIBLE_UPCOMING_LEAVE = 'Eligible - upcoming leave',
  INELIGIBLE_WAITING = 'Ineligible - waiting',
  INELIGIBLE_ON_LEAVE = 'Ineligible - on leave',
  ELIGIBLE_UPCOMING_TERMINATION = 'Eligible - upcoming termination',
  INELIGIBLE_TERMINATED = 'Ineligible - terminated',
  INELIGIBLE_TERMINATED_BEFORE_ACTIVATION = 'Ineligible - terminated before activation',
}

export enum Relation {
  SELF = 'self',
  SPOUSE = 'spouse',
  CHILD = 'child',
}

export enum FronteggRoles {
  EMPLOYEE = 'Employee',
}

export enum YesNo {
  YES = 'YES',
  NO = 'NO',
}

export enum PartOfCoverage {
  ME = 'ME',
  ME_SPOUSE = 'ME_SPOUSE',
  ME_CHILDREN = 'ME_CHILDREN',
  ME_SPOUSE_CHILDREN = 'ME_SPOUSE_CHILDREN',
  WAIVE = 'WAIVE',
}

export interface MultiSelectData {
  value: string;
  label: string;
  location?: string;
  specialty?: string;
  term?: string;
  medID?: number | null;
}

export enum Permissions {
  NONE = 'NONE',
  /*
   * Grouped by Frontegg "Category"
   * Don Employer
   */
  VIEW_EMPLOYER_OBJECTS = 'VIEW_EMPLOYER_OBJECTS',
  // Elena Employee
  VIEW_SELF = 'VIEW_SELF',
  VIEW_SELF_EMPLOYER_PUBLIC_INFO = 'VIEW_SELF_EMPLOYER_PUBLIC_INFO',
  VIEW_SELF_EMPLOYER_NON_SPECIFIC_BENEFITS_MODEL = 'VIEW_SELF_EMPLOYER_NON_SPECIFIC_BENEFITS_MODEL',
  VIEW_SELF_EMPLOYER_DOCUMENTS = 'VIEW_SELF_EMPLOYER_DOCUMENTS',
  WRITE_SELF = 'WRITE_SELF',
  // Prospecting
  VIEW_QUOTES = 'VIEW_QUOTES',
  EDIT_QUOTES = 'EDIT_QUOTES',
  // Agency Management
  AGENCY_MANAGEMENT = 'AGENCY_MANAGEMENT',
}

type ZorroPermissions = Permissions;

export interface Authorization {
  tenantId: string;
  userId: string;
  roles: string[];
  permissions: ZorroPermissions[];
  isInternal: boolean;
}

export interface Person {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

export interface User {
  id: string;
  email: string;
  tenantId: string;
}

export interface Employee extends Person {
  id: string;
  userId: string;
  employerId: string;
  phone?: string | null;
  email: string;
  personalEmail?: string | null;
  class: string;
  zipCode?: string | null;
  salary?: number | null;
  activePeriodId?: string;
}

export enum Roles {
  ANY = 'Any', // 🧠 a workaround to allow access to any role

  OMNIPOTENT_ADMIN = 'OmnipotentAdmin',
  OPERATOR = 'Operator',
  AGENCY_ADMIN = 'AgencyAdmin',
  AGENT = 'Agent',
  ACCOUNT_SUPERVISOR = 'AccountSupervisor',
  EMPLOYER_ADMIN = 'EmployerAdmin',
  EMPLOYEE = 'Employee',
}

export enum RoleDisplayName {
  EMPLOYEE = 'Employee',
  ADMIN = 'Admin',
  OTHER = 'Other',
}

interface FronteggRole {
  key: string;
}

export interface UserRoles {
  roles: string[];
}

export interface UserProfile {
  roles: FronteggRole[];
}

export enum DrugCoverageTier {
  generic = 'generic',
  specialty = 'specialty',
  brand = 'brand',
  preferred_brand = 'preferredBrand',
  preferred_generic = 'preferredGeneric',
  preferred_specialty = 'preferredSpecialty',
  non_preferred_brand = 'nonPreferredBrand',
  non_preferred_generic = 'nonPreferredGeneric',
  non_preferred_specialty = 'nonPreferredSpecialty',
  not_covered = 'notCovered',
  not_listed = 'notListed',
  non_listed = 'nonListed',
  no_coverage = 'noCoverage',
}

export enum MedicalPlanMarket {
  INDIVIDUAL = 'individual',
}

export type CreateFamilyFormFields = {
  value: FamilyUnit;
  label: string;
  disabled: boolean;
};

export enum ProviderType {
  INDIVIDUAL = 'individual',
  ORGANIZATION = 'organization',
}

export type EmployeePageData = {
  employee: EmployeeDto;
  onboardingPeriods: OnboardingPeriodReportDto[];
  selectedOnboardingPeriod: OnboardingPeriodReportDto | null;
  insuredPeople: InsuredPeopleDto | null;
};

export enum EmployeesReportView {
  ALL = 'ALL',
  OPEN_ENROLLMENT = 'OPEN_ENROLLMENT',
  LATEST_COVERAGE = 'LATEST_COVERAGE',
  ENROLLMENTS_IN_PROCESS = 'ENROLLMENTS_IN_PROCESS',
}

export type EffectiveDatesRange = [string, string];
export type EffectiveDatesRanges = Partial<{
  [benefitType in BenefitType]: EffectiveDatesRange;
}>;

export enum EnrollmentStatus {
  PENDING_ELECTION_WINDOW = 'PENDING_ELECTION_WINDOW',
  ELECTION_ACTIVE = 'ELECTION_ACTIVE',
  ELECTION_ACTIVE_HAS_NOT_STARTED = 'ELECTION_ACTIVE_HAS_NOT_STARTED',
  ELECTION_ACTIVE_STARTED = 'ELECTION_ACTIVE_STARTED',
  ELECTION_SUBMITTED = 'ELECTION_SUBMITTED',
  WAIVED_ELECTION = 'WAIVED_ELECTION',
  DEADLINE_PASSED = 'DEADLINE_PASSED',
  ENROLLMENT_CONFIRMED = 'ENROLLMENT_CONFIRMED',
  CARRIER_APPLICATION_SENT = 'CARRIER_APPLICATION_SENT',
  COVERAGE_ENDED = 'COVERAGE_ENDED',
  ACTIVE_COVERAGE = 'ACTIVE_COVERAGE',
  WAIVED_COVERAGE = 'WAIVED_COVERAGE',
  NO_ENROLLMENTS = 'NO_ENROLLMENTS',
}

export const CoverageStatuses = [
  EnrollmentStatus.ACTIVE_COVERAGE,
  EnrollmentStatus.WAIVED_COVERAGE,
  EnrollmentStatus.COVERAGE_ENDED,
];

export enum EmployerStatus {
  NEW = 'New',
  ACTIVE = 'Active',
  DEACTIVATED = 'Deactivated',
}

export enum TierLevel {
  PLATINUM = 'platinum',
  GOLD = 'gold',
  SILVER = 'silver',
  EXPANDED_BRONZE = 'expanded_bronze',
  BRONZE = 'bronze',
  CATASTROPHIC = 'catastrophic',
  NONE = 'none',
}

export type ProviderDtoEnhanced = {
  name?: string;
  npi: number;
  location?: string;
};

export enum PlanMarket {
  ON_EXCHANGE = 'on_exchange',
  ON_MARKET = 'on_market',
  OFF_MARKET = 'off_market',
  BOTH_MARKETS = 'both_markets',
  SHOP = 'shop',
}

export type EmployeeCountByEnrollmentStatus = {
  [enrollmentStatus in EnrollmentStatus]: number;
};

export type DeepReadonly<T> = {
  readonly [P in keyof T]: T[P] extends Record<string, unknown>
    ? DeepReadonly<T[P]>
    : T[P];
};

// Use this type to extract all methods from a class type
// Example -
//    class Example { foo: () => bar }
//    type ExampleMethods = ExtractClassMethods<typeof Example>
// Expected type -
//    type ExampleMethods = { foo: () => bar; }
export type ExtractClassMethods<T> = T extends new (
  args: infer ConstructorArgs
) => infer InstanceType
  ? {
      [Method in keyof InstanceType]: InstanceType[Method] extends (
        ...args: infer MethodArgs
      ) => infer MethodReturn
        ? (...args: MethodArgs) => MethodReturn
        : never;
    }
  : never;

// This helper type gets the value of a type, behaves similarly to "keyof" keyword but for values.
// type SomeValue = ValueOf<{ foo: number; bar: string }>; // type SomeValue = string | number
export type ValueOf<T> = T[keyof T];

// Extract does not error when a key does not exist in the key list, this solves it.
export type StrictExtract<T, U extends keyof T> = U;
