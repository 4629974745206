import { readLocalStorageValue, useLocalStorage } from '@mantine/hooks';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { EmployeeReportRowDto } from '@zorro/clients';
import { callEndpoint, parseEnumValue, useRouter } from '@zorro/shared/utils';
import { EmployeesReportView, recordsPerPage } from '@zorro/types';
import { HttpStatusCode } from 'axios';
import { useEffect, useMemo } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

import { PaginatedEmployeeReportsViewToDataMap } from './employeesDatatable.types';
import { employeesDataToFormattedRows } from './employeesDatatable.utils';

export function usePaginatedEmployeeReports(
  selectedEnrollmentPeriodId?: string | null,
  view: EmployeesReportView = EmployeesReportView.ALL
): {
  isLoading: boolean;
  data: EmployeeReportRowDto[];
  refetch: () => Promise<QueryObserverResult<EmployeeReportRowDto[] | null>>;
} {
  const { reloadPage } = useRouter();

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'employerReportsControllerGenerateEmployeeReportRestOfPages',
      selectedEnrollmentPeriodId,
      view,
    ],
    queryFn: async () => {
      try {
        if (selectedEnrollmentPeriodId) {
          const firstPage = await callEndpoint({
            method: 'employerReportsControllerGenerateEmployeeReport',
            params: [selectedEnrollmentPeriodId, view, recordsPerPage, 1],
          });
          if (firstPage) {
            const restOfPages = await Promise.all(
              Array.from({ length: firstPage.meta.lastPage - 1 }).map(
                async (_, index) => {
                  const pageNumber = index + 2;
                  return await callEndpoint({
                    method: 'employerReportsControllerGenerateEmployeeReport',
                    params: [
                      selectedEnrollmentPeriodId,
                      view,
                      recordsPerPage,
                      pageNumber,
                    ],
                  });
                }
              )
            );
            return [firstPage, ...restOfPages].flatMap((page) => page.data);
          }
        }
      } catch (error) {
        if (error.status === HttpStatusCode.Unauthorized) {
          reloadPage();
        }
      }
      return null;
    },
  });

  return {
    isLoading,
    data: data ?? [],
    refetch,
  };
}

export function useFetchEmployeeDataAllViews(
  selectedEnrollmentPeriodId: string | null
) {
  const storedView = readLocalStorageValue<string>({
    key: `selectedView_${selectedEnrollmentPeriodId}`,
    defaultValue: EmployeesReportView.ALL,
  });

  const [viewQuery, setViewQuery] = useQueryParam(
    'view',
    withDefault(StringParam, '')
  );

  const [viewLocal, setViewLocalLocal] = useLocalStorage({
    key: `selectedView_${selectedEnrollmentPeriodId}`,
    defaultValue: storedView ?? EmployeesReportView.ALL,
  });

  useEffect(() => {
    if (viewQuery && viewQuery !== viewLocal) {
      setViewLocalLocal(viewQuery);
    } else if (!viewQuery && viewLocal !== EmployeesReportView.ALL) {
      setViewQuery(viewLocal);
    }
  }, []);

  const setCurrentView = (view: EmployeesReportView) => {
    setViewQuery(view);
    setViewLocalLocal(view);
  };
  const {
    isLoading: isLoadingOpenEnrollmentData,
    data: openEnrollmentData = [],
  } = usePaginatedEmployeeReports(
    selectedEnrollmentPeriodId,
    EmployeesReportView.OPEN_ENROLLMENT
  );

  const { isLoading: isLoadingAllEmployeesData, data: allData = [] } =
    usePaginatedEmployeeReports(
      selectedEnrollmentPeriodId,
      EmployeesReportView.ALL
    );

  const {
    isLoading: isLoadingLatestCoverageData,
    data: latestCoverageData = [],
  } = usePaginatedEmployeeReports(
    selectedEnrollmentPeriodId,
    EmployeesReportView.LATEST_COVERAGE
  );

  const {
    isLoading: isLoadingEnrollmentsInProcessData,
    data: enrollmentsInProcessData = [],
  } = usePaginatedEmployeeReports(
    selectedEnrollmentPeriodId,
    EmployeesReportView.ENROLLMENTS_IN_PROCESS
  );

  const employeesViewToDataMap: PaginatedEmployeeReportsViewToDataMap =
    useMemo(() => {
      return new Map([
        [
          EmployeesReportView.OPEN_ENROLLMENT,
          {
            isLoading: isLoadingOpenEnrollmentData,
            data: openEnrollmentData,
          },
        ],
        [
          EmployeesReportView.ALL,
          {
            isLoading: isLoadingAllEmployeesData,
            data: allData,
          },
        ],
        [
          EmployeesReportView.LATEST_COVERAGE,
          {
            isLoading: isLoadingLatestCoverageData,
            data: latestCoverageData,
          },
        ],
        [
          EmployeesReportView.ENROLLMENTS_IN_PROCESS,
          {
            isLoading: isLoadingEnrollmentsInProcessData,
            data: enrollmentsInProcessData,
          },
        ],
      ]);
    }, [
      isLoadingOpenEnrollmentData,
      openEnrollmentData,
      isLoadingAllEmployeesData,
      allData,
      isLoadingLatestCoverageData,
      latestCoverageData,
      isLoadingEnrollmentsInProcessData,
      enrollmentsInProcessData,
    ]);

  const viewedEmployees = employeesDataToFormattedRows(
    employeesViewToDataMap.get(
      parseEnumValue(EmployeesReportView, viewLocal) || EmployeesReportView.ALL
    )?.data || []
  );

  return {
    viewedEmployees,
    employeesViewToDataMap,
    currentView:
      parseEnumValue(EmployeesReportView, viewLocal) || EmployeesReportView.ALL,
    setCurrentView,
  };
}
