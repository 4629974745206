import { IconCheck, IconX } from '@tabler/icons-react';
import { PaymentStatus, YesNoNotSure } from '@zorro/clients';
import { AgentIcon, EmployeeIcon } from '@zorro/shared/assets';
import { VALIDATION_MESSAGES } from '@zorro/shared/utils';
import { DeepReadonly } from '@zorro/types';
import { Box, Icon, brand } from '@zorro/zorro-ui-design';
import { $enum } from 'ts-enum-util';
import * as yup from 'yup';

import { AgentOrEmployee } from './AgentOrEmployeeInput';

const AWAITING_EMPLOYEE_REPORT = 'Awaiting employee report';
const AUTO_PAY_DESCRIPTION =
  'Auto pay setup ensures timely premium payments and uninterrupted coverage';
const INITIAL_PREMIUM_DESCRIPTION =
  'The first premium payment required to activate your health coverage';
const COMPLETED_OR_INITIAL_PREMIUM_FALSE_BY_EMPLOYEE_LEARN_MORE_LINK =
  'https://help.myzorro.co/en/articles/9909428-how-do-i-know-if-my-initial-premium-was-paid-or-if-i-am-set-up-with-autopay';
const AUTO_PAY_FALSE_BY_EMPLOYEE_LEARN_MORE_LINK =
  'https://help.myzorro.co/en/articles/9909445-how-do-i-set-up-auto-pay';
const INITIAL_PREMIUM_TOOL_TIP_LABEL =
  'Go to your carrier’s website and complete your enrollment’s initial premium payment';
const AUTO_PAY_TOOL_TIP_LABEL =
  'Go to your carrier’s website and set up your auto pay';

export const paymentStatusSchema = yup.object({
  initialPremiumHandler: yup
    .mixed<AgentOrEmployee>()
    .oneOf(
      Object.values(AgentOrEmployee),
      VALIDATION_MESSAGES.paymentHandlerRequired
    )
    .typeError(VALIDATION_MESSAGES.paymentHandlerRequired)
    .required(VALIDATION_MESSAGES.paymentHandlerRequired),
  initialPremiumCompleted: yup
    .mixed<YesNoNotSure>()
    .oneOf(
      Object.values(YesNoNotSure),
      VALIDATION_MESSAGES.wasPaymentCompletedRequired
    )
    .typeError(VALIDATION_MESSAGES.wasPaymentCompletedRequired)
    .required(VALIDATION_MESSAGES.wasPaymentCompletedRequired),
  autoPaySetter: yup
    .mixed<AgentOrEmployee>()
    .oneOf(
      Object.values(AgentOrEmployee),
      VALIDATION_MESSAGES.autoPaySetterRequired
    )
    .typeError(VALIDATION_MESSAGES.autoPaySetterRequired)
    .required(VALIDATION_MESSAGES.autoPaySetterRequired),
  autoPayCompleted: yup
    .mixed<YesNoNotSure>()
    .oneOf(Object.values(YesNoNotSure), VALIDATION_MESSAGES.hasAutoPayBeenSetUp)
    .typeError(VALIDATION_MESSAGES.hasAutoPayBeenSetUp)
    .required(VALIDATION_MESSAGES.hasAutoPayBeenSetUp),
});

export type PaymentStatusFormFields = yup.InferType<typeof paymentStatusSchema>;

export const getPaymentIconElena = (completed: YesNoNotSure) => {
  const backgroundColor = $enum.mapValue(completed).with({
    [YesNoNotSure.YES]: brand.zorroGreen300,
    [YesNoNotSure.NO]: brand.zorroFire300,
    [YesNoNotSure.NOT_SURE]: brand.zorroFire300,
  });
  return (
    <Box
      bg={backgroundColor}
      w="2rem"
      h="2rem"
      style={(theme) => ({
        borderRadius: theme.spacing.xxs,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      })}
    >
      <Icon icon={completed === YesNoNotSure.YES ? IconCheck : IconX} />
    </Box>
  );
};

export const getPaymentIconMurrieta = (
  byEmployee: boolean,
  completed: YesNoNotSure
) => {
  const backgroundColor = $enum.mapValue(completed).with({
    [YesNoNotSure.YES]: brand.zorroGreen300,
    [YesNoNotSure.NO]: brand.zorroFire300,
    [YesNoNotSure.NOT_SURE]: brand.zorroYolk400,
  });
  return (
    <Box
      bg={backgroundColor}
      w="2rem"
      h="2rem"
      style={(theme) => ({
        borderRadius: theme.spacing.xxs,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      })}
    >
      <Icon icon={byEmployee ? EmployeeIcon : AgentIcon} />
    </Box>
  );
};

export const initialPremiumPaymentConfigElena: DeepReadonly<{
  [status in PaymentStatus]: {
    label: string;
    icon: JSX.Element;
    description: string;
    learnMoreLink: string | null;
    toolTipLabel: string | null;
    shouldShowMarkAsDone: boolean;
  };
}> = {
  [PaymentStatus.TRUE_BY_EMPLOYEE]: {
    label: 'Initial premium payment completed',
    icon: getPaymentIconElena(YesNoNotSure.YES),
    description: INITIAL_PREMIUM_DESCRIPTION,
    learnMoreLink:
      COMPLETED_OR_INITIAL_PREMIUM_FALSE_BY_EMPLOYEE_LEARN_MORE_LINK,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.TRUE]: {
    label: 'Initial premium payment completed',
    icon: getPaymentIconElena(YesNoNotSure.YES),
    description: INITIAL_PREMIUM_DESCRIPTION,
    learnMoreLink:
      COMPLETED_OR_INITIAL_PREMIUM_FALSE_BY_EMPLOYEE_LEARN_MORE_LINK,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.FALSE]: {
    label: 'Waiting for agent to complete the initial premium payment',
    icon: getPaymentIconElena(YesNoNotSure.NO),
    description: INITIAL_PREMIUM_DESCRIPTION,
    learnMoreLink: null,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.FALSE_BY_EMPLOYEE]: {
    label: 'You need to complete your initial premium payment',
    icon: getPaymentIconElena(YesNoNotSure.NO),
    description: INITIAL_PREMIUM_DESCRIPTION,
    learnMoreLink:
      COMPLETED_OR_INITIAL_PREMIUM_FALSE_BY_EMPLOYEE_LEARN_MORE_LINK,
    toolTipLabel: INITIAL_PREMIUM_TOOL_TIP_LABEL,
    shouldShowMarkAsDone: true,
  },
  [PaymentStatus.UNKNOWN_BY_EMPLOYEE]: {
    label: 'You need to complete your initial premium payment',
    icon: getPaymentIconElena(YesNoNotSure.NOT_SURE),
    description: INITIAL_PREMIUM_DESCRIPTION,
    learnMoreLink:
      COMPLETED_OR_INITIAL_PREMIUM_FALSE_BY_EMPLOYEE_LEARN_MORE_LINK,
    toolTipLabel: INITIAL_PREMIUM_TOOL_TIP_LABEL,
    shouldShowMarkAsDone: true,
  },
};

export const autoPayConfigElena: DeepReadonly<{
  [status in PaymentStatus]: {
    label: string;
    icon: JSX.Element;
    description: string;
    learnMoreLink: string | null;
    toolTipLabel: string | null;
    shouldShowMarkAsDone: boolean;
  };
}> = {
  [PaymentStatus.TRUE_BY_EMPLOYEE]: {
    label: 'Auto-pay is set up',
    icon: getPaymentIconElena(YesNoNotSure.YES),
    description: AUTO_PAY_DESCRIPTION,
    learnMoreLink:
      COMPLETED_OR_INITIAL_PREMIUM_FALSE_BY_EMPLOYEE_LEARN_MORE_LINK,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.TRUE]: {
    label: 'Auto-pay is set up',
    icon: getPaymentIconElena(YesNoNotSure.YES),
    description: AUTO_PAY_DESCRIPTION,
    learnMoreLink:
      COMPLETED_OR_INITIAL_PREMIUM_FALSE_BY_EMPLOYEE_LEARN_MORE_LINK,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.FALSE]: {
    label: 'Waiting for agent to set up your auto-pay',
    icon: getPaymentIconElena(YesNoNotSure.NO),
    description: AUTO_PAY_DESCRIPTION,
    learnMoreLink: null,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.FALSE_BY_EMPLOYEE]: {
    label: 'You need to set up your auto-pay',
    icon: getPaymentIconElena(YesNoNotSure.NO),
    description: AUTO_PAY_DESCRIPTION,
    learnMoreLink: AUTO_PAY_FALSE_BY_EMPLOYEE_LEARN_MORE_LINK,
    toolTipLabel: AUTO_PAY_TOOL_TIP_LABEL,
    shouldShowMarkAsDone: true,
  },
  [PaymentStatus.UNKNOWN_BY_EMPLOYEE]: {
    label: 'You need to set up your auto-pay',
    icon: getPaymentIconElena(YesNoNotSure.NOT_SURE),
    description: AUTO_PAY_DESCRIPTION,
    learnMoreLink: AUTO_PAY_FALSE_BY_EMPLOYEE_LEARN_MORE_LINK,
    toolTipLabel: AUTO_PAY_TOOL_TIP_LABEL,
    shouldShowMarkAsDone: true,
  },
};

export const initialPremiumPaymentConfigMurrieta: DeepReadonly<{
  [status in PaymentStatus]: {
    label: string;
    icon: JSX.Element;
    description: null;
    learnMoreLink: null;
    toolTipLabel: null;
    shouldShowMarkAsDone: boolean;
  };
}> = {
  [PaymentStatus.TRUE_BY_EMPLOYEE]: {
    label: 'Completed by employee',
    icon: getPaymentIconMurrieta(true, YesNoNotSure.YES),
    description: null,
    learnMoreLink: null,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.TRUE]: {
    label: 'Completed by agent',
    icon: getPaymentIconMurrieta(false, YesNoNotSure.YES),
    description: null,
    learnMoreLink: null,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.FALSE]: {
    label: 'Not completed - waiting for agent',
    icon: getPaymentIconMurrieta(false, YesNoNotSure.NO),
    description: null,
    learnMoreLink: null,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.FALSE_BY_EMPLOYEE]: {
    label: 'Not completed - waiting for employee',
    icon: getPaymentIconMurrieta(true, YesNoNotSure.NO),
    description: null,
    learnMoreLink: null,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.UNKNOWN_BY_EMPLOYEE]: {
    label: AWAITING_EMPLOYEE_REPORT,
    icon: getPaymentIconMurrieta(true, YesNoNotSure.NOT_SURE),
    description: null,
    learnMoreLink: null,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
};

export const autoPayConfigMurrieta: DeepReadonly<{
  [status in PaymentStatus]: {
    label: string;
    icon: JSX.Element;
    description: null;
    learnMoreLink: null;
    toolTipLabel: null;
    shouldShowMarkAsDone: boolean;
  };
}> = {
  [PaymentStatus.TRUE_BY_EMPLOYEE]: {
    label: 'Set up by employee',
    icon: getPaymentIconMurrieta(true, YesNoNotSure.YES),
    description: null,
    learnMoreLink: null,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.TRUE]: {
    label: 'Set up by agent',
    icon: getPaymentIconMurrieta(false, YesNoNotSure.YES),
    description: null,
    learnMoreLink: null,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.FALSE]: {
    label: 'Not set up - waiting for agent',
    icon: getPaymentIconMurrieta(false, YesNoNotSure.NO),
    description: null,
    learnMoreLink: null,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.FALSE_BY_EMPLOYEE]: {
    label: 'Not set up - waiting for employee',
    icon: getPaymentIconMurrieta(true, YesNoNotSure.NO),
    description: null,
    learnMoreLink: null,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
  [PaymentStatus.UNKNOWN_BY_EMPLOYEE]: {
    label: AWAITING_EMPLOYEE_REPORT,
    icon: getPaymentIconMurrieta(true, YesNoNotSure.NOT_SURE),
    description: null,
    learnMoreLink: null,
    toolTipLabel: null,
    shouldShowMarkAsDone: false,
  },
};

export const getInitialPremiumStatus = (
  initialPremiumPaymentStatus: PaymentStatus,
  isElena: boolean
): {
  label: string;
  icon: JSX.Element;
  description: string | null;
  learnMoreLink: string | null;
  toolTipLabel: string | null;
  shouldShowMarkAsDone: boolean;
} => {
  // eslint-disable-next-line sonarjs/no-selector-parameter
  return isElena
    ? initialPremiumPaymentConfigElena[initialPremiumPaymentStatus]
    : initialPremiumPaymentConfigMurrieta[initialPremiumPaymentStatus];
};

export const getAutoPayStatus = (
  initialPremiumPaymentStatus: PaymentStatus,
  isElena: boolean
): {
  label: string;
  icon: JSX.Element;
  description: string | null;
  learnMoreLink: string | null;
  toolTipLabel: string | null;
  shouldShowMarkAsDone: boolean;
} => {
  // eslint-disable-next-line sonarjs/no-selector-parameter
  return isElena
    ? autoPayConfigElena[initialPremiumPaymentStatus]
    : autoPayConfigMurrieta[initialPremiumPaymentStatus];
};
