export const getPhoneVariations = (phone?: string | null) => {
  if (!phone) {
    return {
      valueForView: phone,
      valueForCopy: phone,
    };
  }

  const digits = phone.replace(/\D/gu, '');
  const phoneNumber = digits.slice(-10);

  return {
    valueForView: `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`,
    valueForCopy: phoneNumber,
  };
};
