import { PayrollCycle } from '@zorro/clients';
import { payrollCycleLabelConfig } from '@zorro/shared/utils';
import { ISelectProps, Select } from '@zorro/zorro-ui-design';
import { ForwardedRef, forwardRef } from 'react';
import { ControllerRenderProps, Path } from 'react-hook-form';

export const selectPayrollCycleOptions = Object.values(PayrollCycle).map(
  (payrollCycle) => ({
    label: payrollCycleLabelConfig[payrollCycle],
    value: payrollCycle,
  })
);

type PayrollCycleFieldValues = { payrollCycle?: PayrollCycle | null };
type Props<
  T extends PayrollCycleFieldValues,
  U extends Path<T>,
> = ControllerRenderProps<T, U> & {
  selectProps?: ISelectProps;
  size?: 'sm' | 'md' | 'lg';
};

export const PayrollCycleInput = forwardRef(
  <T extends PayrollCycleFieldValues, U extends Path<T>>(
    { selectProps, size = 'lg', ...field }: Props<T, U>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Select
        {...field}
        {...selectProps}
        ref={ref}
        size={size}
        label="Payroll cycle"
        placeholder="Payroll cycle"
        data={selectPayrollCycleOptions}
      />
    );
  }
);

PayrollCycleInput.displayName = 'PayrollCycleInput';
