import { MantineTheme } from '@mantine/core';
import {
  GetEmployerDto,
  PayrollCycle,
  PayrollReportRowDto,
} from '@zorro/clients';
import {
  MONTH_YEAR_EN_LOCALE_DATE_FORMAT,
  formatCurrencyEnUs,
  formatToFixedFloat,
  parseDateISOThenFormatEnLocale,
  parseDateUnknown,
} from '@zorro/shared/formatters';
import {
  exportToCsv,
  payrollCycleLabelConfig,
  screamingSnakeCaseToText,
} from '@zorro/shared/utils';
import { employerFamilyUnitLabelConfig, recordsPerPage } from '@zorro/types';
import { Badge, Box, Button, Group, Text } from '@zorro/zorro-ui-design';
import { DataTableSortStatus } from 'mantine-datatable';
import { useState } from 'react';

import { stringComparator } from '../../EmployeesDatatable';
import { DateFilter, Filter, SelectFilter } from '../../Filters';
import { SearchableDataTable } from '../../SearchableDataTable';

export type PayrollRow = {
  firstName: string;
  lastName: string;
  idFromEmployer?: string | null;
  email: string;
  dateOfBirth: string;
  address?: string | null;
  employeeClass: string;
  medicalPlanType?: string;
  medicalPlanCarrier?: string | null;
  medicalPlanName?: string | null;
  medicalPlanPremium?: number | null;
  medicalPlanEmployerAllowance?: number | null;
  medicalPlanEmployeeContribution?: number | null;
  medicalPlanEmployeeSelfPayment?: number | null;
  planMarket?: string | null;
  isHsaEligible?: boolean | null;
  employeePayment?: number | null;
  employerPayment?: number | null;
  reimbursement: number;
  deduction: number;
  coverageUpdateDate: string;
};

const mapEmployeeReportRowDtoToRows = (
  employees: PayrollReportRowDto[]
): PayrollRow[] => {
  return employees.map(
    ({
      firstName,
      lastName,
      idFromEmployer,
      email,
      dateOfBirth,
      address,
      class: employeeClass,
      payrollSummary: { reimbursement, deduction, coverageUpdateDate },
      enrollment: { majorMedicalPlan },
    }) => {
      return {
        firstName,
        lastName,
        idFromEmployer,
        email,
        dateOfBirth,
        address,
        employeeClass,
        medicalPlanType: majorMedicalPlan?.familyUnit
          ? employerFamilyUnitLabelConfig[majorMedicalPlan?.familyUnit]
          : '',
        medicalPlanCarrier: majorMedicalPlan?.carrierName,
        medicalPlanName: majorMedicalPlan?.name,
        medicalPlanPremium: formatToFixedFloat(majorMedicalPlan?.premium),
        medicalPlanEmployerAllowance: formatToFixedFloat(
          majorMedicalPlan?.employerMonthlyContribution
        ),
        medicalPlanEmployeeContribution: formatToFixedFloat(
          majorMedicalPlan?.employeeMonthlyContribution
        ),
        medicalPlanEmployeeSelfPayment: formatToFixedFloat(
          majorMedicalPlan?.selfPayAmount
        ),
        planMarket: majorMedicalPlan?.planMarket
          ? screamingSnakeCaseToText(majorMedicalPlan.planMarket)
          : null,
        isHsaEligible: majorMedicalPlan?.isHsaEligible,
        reimbursement: formatToFixedFloat(reimbursement)!,
        deduction: formatToFixedFloat(deduction)!,
        coverageUpdateDate: parseDateISOThenFormatEnLocale(coverageUpdateDate),
      };
    }
  );
};

const badgeStyle = (theme: MantineTheme) => ({
  backgroundColor: theme.colors.zorroIris50,
  borderColor: theme.colors.zorroIris400,
  color: theme.colors.zorroCoal900,
});

type Props = {
  employer: GetEmployerDto;
  employees: PayrollReportRowDto[];
  effectiveOn: Date;
  payrollCycle: PayrollCycle;
  onNewPayrollClick: () => void;
  isLoading?: boolean;
};

export function PayrollDataTable({
  employer,
  employees,
  effectiveOn,
  payrollCycle,
  onNewPayrollClick,
  isLoading,
}: Props) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [filters, setFilters] = useState<Filter<PayrollRow>[]>([]);
  const monthLabel = parseDateUnknown(effectiveOn).format(
    MONTH_YEAR_EN_LOCALE_DATE_FORMAT
  );

  const employeeRows = mapEmployeeReportRowDtoToRows(employees);

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'fullName',
    direction: 'asc',
  });
  const [page, setPage] = useState(1);

  const filtered = employeeRows
    .filter((employee) => {
      let keep = true;
      filters.forEach((filter) => {
        if (!filter.filterCallback(employee)) {
          keep = false;
        }
      });
      return keep;
    })
    .filter((employee) => {
      const lowerCaseValue = searchValue.toLowerCase();
      return (
        employee.firstName.toLowerCase().includes(lowerCaseValue) ||
        employee.lastName.toLowerCase().includes(lowerCaseValue) ||
        employee.idFromEmployer?.toLowerCase().includes(lowerCaseValue) ||
        employee.email.toLowerCase().includes(lowerCaseValue) ||
        employee.dateOfBirth.toLowerCase().includes(lowerCaseValue) ||
        employee.address?.toLowerCase().includes(lowerCaseValue) ||
        employee.employeeClass.toLowerCase().includes(lowerCaseValue) ||
        employee.medicalPlanType?.toLowerCase().includes(lowerCaseValue) ||
        employee.medicalPlanName?.toLowerCase().includes(lowerCaseValue) ||
        employee.medicalPlanCarrier?.toLowerCase().includes(lowerCaseValue) ||
        employee.medicalPlanType?.toLowerCase().includes(lowerCaseValue)
      );
    });

  const dataToSort = filtered.sort((firstRow, secondRow) => {
    const first = firstRow[sortStatus.columnAccessor as keyof PayrollRow];
    const second = secondRow[sortStatus.columnAccessor as keyof PayrollRow];

    if (typeof first === 'string' && typeof second === 'string') {
      const firstDate = parseDateUnknown(first);
      const secondDate = parseDateUnknown(second);
      if (firstDate.isValid() && secondDate.isValid()) {
        return firstDate.diff(secondDate);
      }
    }

    return typeof first === 'number' && typeof second === 'number'
      ? first - second
      : stringComparator(first?.toString(), second?.toString());
  });

  const records =
    sortStatus.direction === 'asc' ? dataToSort : dataToSort.reverse();

  const pageRecords = records.slice(
    (page - 1) * recordsPerPage,
    page * recordsPerPage
  );

  const length = searchValue ? records.length : employeeRows.length;

  const downloadEmployees = () => {
    const fileName = `Payroll Report - ${employer.name} - ${monthLabel}.csv`;
    exportToCsv(records, fileName, [
      { label: 'First name', value: 'firstName' },
      { label: 'Last name', value: 'lastName' },
      { label: 'ID', value: 'idFromEmployer' },
      { label: 'Email', value: 'email' },
      { label: 'Date of birth', value: 'dateOfBirth' },
      { label: 'Address', value: 'address' },
      { label: 'Class', value: 'employeeClass' },
      { label: 'Family unit', value: 'medicalPlanType' },
      { label: 'Carrier', value: 'medicalPlanCarrier' },
      { label: 'Plan name', value: 'medicalPlanName' },
      { label: 'Monthly premium', value: 'medicalPlanPremium' },
      {
        label: 'Monthly employer contribution',
        value: 'medicalPlanEmployerAllowance',
      },
      {
        label: 'Monthly employee contribution',
        value: 'medicalPlanEmployeeContribution',
      },
      {
        label: 'Monthly employee self payment',
        value: 'medicalPlanEmployeeSelfPayment',
      },
      { label: 'Plan market', value: 'planMarket' },
      { label: 'HSA eligible', value: 'isHsaEligible' },
      {
        label: `${payrollCycleLabelConfig[payrollCycle]} reimbursement`,
        value: 'reimbursement',
      },
      {
        label: `${payrollCycleLabelConfig[payrollCycle]} deduction`,
        value: 'deduction',
      },
      { label: 'Coverage update date', value: 'coverageUpdateDate' },
    ]);
  };

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };

  const handleFilterChange = (value: Filter<PayrollRow>[]) => {
    setFilters(value);
    setPage(1);
  };

  return (
    <SearchableDataTable
      heading="Payroll Report"
      componentsAfterSearch={
        <Group>
          <Badge fz="sm" fw={400} variant="outline" style={badgeStyle}>
            {payrollCycleLabelConfig[payrollCycle]}
          </Badge>
          <Badge fz="sm" fw={400} variant="outline" style={badgeStyle}>
            {monthLabel}
          </Badge>
        </Group>
      }
      componentsRightDownloadButton={
        <Button
          size="sm"
          style={{ fontSize: '14px' }}
          onClick={onNewPayrollClick}
          disabled={isLoading}
        >
          New Payroll
        </Button>
      }
      onDownload={downloadEmployees}
      searchValue={searchValue}
      onSearchChange={handleSearchValueChange}
      pinFirstColumn
      grow
      records={pageRecords}
      highlightOnHover
      groups={[
        {
          id: 'employeeDetails',
          title: (
            <Box ta="center" w="100%">
              Employee details
            </Box>
          ),
          columns: [
            {
              accessor: 'lastName',
              sortable: true,
              render: ({ lastName }) => <Text masked>{lastName}</Text>,
            },
            {
              accessor: 'firstName',
              sortable: true,
              render: ({ firstName }) => <Text masked>{firstName}</Text>,
            },
            {
              accessor: 'idFromEmployer',
              title: 'ID',
              sortable: true,
            },
            { accessor: 'email' },
            {
              accessor: 'dateOfBirth',
              title: 'Date of birth',
              filtering: filters.some(
                (filter) => filter.type === 'dateOfBirth'
              ),
              filter: (
                <DateFilter
                  setFilters={handleFilterChange}
                  filterBy="dateOfBirth"
                  filters={filters}
                />
              ),
              render: ({ dateOfBirth }) => <Text masked>{dateOfBirth}</Text>,
            },
            {
              accessor: 'address',
              width: 232,
              render: ({ address }) => <Text masked>{address}</Text>,
            },
            {
              accessor: 'employeeClass',
              filtering: filters.some(
                (filter) => filter.type === 'employeeClass'
              ),
              filter: (
                <Group p="xs">
                  <SelectFilter
                    title="Class:"
                    selectOptions={[
                      ...new Set(employeeRows.map((row) => row.employeeClass)),
                    ].map((cls) => ({ value: cls, label: cls }))}
                    filterBy="employeeClass"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
            },
          ],
          style: (theme: MantineTheme) => ({
            backgroundColor: theme.colors.zorroGray50,
          }),
        },
        {
          id: 'medicalPlan',
          title: (
            <Box ta="center" w="100%">
              Medical plan details
            </Box>
          ),
          columns: [
            {
              accessor: 'medicalPlanType',
              title: 'Family unit',
              filtering: filters.some(
                (filter) => filter.type === 'medicalPlanType'
              ),
              filter: (
                <Group p="xs">
                  <SelectFilter
                    title="Family unit:"
                    selectOptions={[
                      ...new Set(
                        employeeRows.map((row) => row.medicalPlanType || '')
                      ),
                    ].map((cls) => ({ value: cls, label: cls }))}
                    filterBy="medicalPlanType"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
            },
            {
              accessor: 'medicalPlanCarrier',
              title: 'Carrier',
              filtering: filters.some(
                (filter) => filter.type === 'medicalPlanCarrier'
              ),
              filter: (
                <Group p="xs">
                  <SelectFilter
                    title="Carrier:"
                    selectOptions={[
                      ...new Set(
                        employeeRows.map((row) => row.medicalPlanCarrier || '')
                      ),
                    ].map((cls) => ({ value: cls, label: cls }))}
                    filterBy="medicalPlanCarrier"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
            },
            {
              accessor: 'medicalPlanName',
              title: 'Plan name',
              width: '240px',
            },
            {
              accessor: 'medicalPlanPremium',
              title: 'Monthly premium',
              render: ({ medicalPlanPremium }) =>
                formatCurrencyEnUs(medicalPlanPremium),
            },
            {
              accessor: 'medicalPlanEmployerAllowance',
              title: 'Monthly employer contribution',
              render: ({ medicalPlanEmployerAllowance }) =>
                formatCurrencyEnUs(medicalPlanEmployerAllowance),
            },
            {
              accessor: 'medicalPlanEmployeeContribution',
              title: 'Monthly employee contribution',
              render: ({ medicalPlanEmployeeContribution }) =>
                formatCurrencyEnUs(medicalPlanEmployeeContribution),
            },
            {
              accessor: 'medicalPlanEmployeeSelfPayment',
              title: 'Monthly employee self payment',
              render: ({ medicalPlanEmployeeSelfPayment }) =>
                formatCurrencyEnUs(medicalPlanEmployeeSelfPayment),
            },
            {
              accessor: 'planMarket',
              title: 'Plan market',
              width: '200px',
            },
            {
              accessor: 'isHsaEligible',
              title: 'HSA eligible',
              width: '120px',
              render: ({ isHsaEligible }) => (isHsaEligible ? 'Yes' : 'No'),
            },
          ],
        },
        {
          id: 'payrollSummary',
          title: (
            <Box ta="center" w="100%">
              Payroll summary
            </Box>
          ),
          columns: [
            {
              accessor: 'reimbursement',
              title: `${payrollCycleLabelConfig[payrollCycle]} reimbursement`,
              render: ({ reimbursement }) => formatCurrencyEnUs(reimbursement),
            },
            {
              accessor: 'deduction',
              title: `${payrollCycleLabelConfig[payrollCycle]} deduction`,
              render: ({ deduction }) => formatCurrencyEnUs(deduction),
            },
            {
              accessor: 'coverageUpdateDate',
              title: 'Coverage update date',
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === 'coverageUpdateDate'
              ),
              filter: (
                <DateFilter
                  filterBy="coverageUpdateDate"
                  setFilters={handleFilterChange}
                  filters={filters}
                />
              ),
            },
          ],
        },
      ]}
      minHeight={200}
      sortStatus={sortStatus}
      onSortStatusChange={setSortStatus}
      totalRecords={length}
      recordsPerPage={recordsPerPage}
      page={page}
      onPageChange={setPage}
      isLoading={isLoading}
    />
  );
}
